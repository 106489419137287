import React from "react";
import { ListGroup } from '@jsluna/list';
import { Card } from '@jsluna/card';
import { FilledButton, ButtonGroupWrapper, ButtonGroupPrimary, ButtonGroupSecondary } from '@jsluna/button';

import { IEvent } from "../../models/events/IEvent";
import { useNavigate } from "react-router";
import { AuthorisationRoutes, EventRoutes } from "../../Routes";
import { Fieldset, Label, Legend } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Notification, NotificationTypes } from "../Common/Notification";
import { PledgeSummaryReport } from "../reporting/PledgeSummaryReport";
import { IBasePageProps } from "../IBasePageProps";
import { useServices } from "../../providers/hooks/useServices";
import { GetStoreTypesLabel } from "./Common";

interface IEventProps extends IBasePageProps {
}

interface IEventsState {
    loading: boolean,
    events?: IEvent[],
    displayError: boolean;
}

class Events extends React.Component<IEventProps, IEventsState> {
    constructor(props: IEventProps) {
        super(props);

        this.state = {
            loading: true,
            displayError: false
        };
    }

    public async componentDidMount() {
        try {
            const eventManagementService = this.props.services.eventManagementService;
            
            // check if user have permission
            const isAdmin = await eventManagementService.isAdmin();
            if(!isAdmin) {
                this.props.navigate(AuthorisationRoutes.UnauthorisedPage);
            }

            const events = await eventManagementService.getEvents();
            this.setState({
                loading: false,
                events: events
            });
        } catch (error) {
            console.log(error);
            this.setState({
              loading: false,
              displayError : true
            });
        }        
    }

    public render(): React.ReactNode {
        if(this.state.loading) {
            return <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
        }

        if(this.state.displayError) {
            return <Notification type={NotificationTypes.Error} 
            display={true} 
            displayMessage= "Unable to process your request"
            onClose={()=>{
                this.setState({
                  displayError: false
                });
            }}/>
        }

        if(this.state.events) {
            return <ListGroup actionable type="full">
                <Legend fontStyle="h2" className='topHeaderPadding'>Events</Legend>
                <ButtonGroupWrapper className="ln-u-hard-top ln-u-push-bottom">
                    <ButtonGroupPrimary>
                        <FilledButton type="submit" onClick={()=>this.props.navigate(EventRoutes.CreateEvent)}>
                            Create new event
                        </FilledButton>
                    </ButtonGroupPrimary>
                </ButtonGroupWrapper>
                <Fieldset>                    
                    {
                        (this.state.events.length === 0) &&
                        <Label htmlFor="norecords" className="emptyMessagebox">There are no events available to display</Label>
                    }                    
                    {
                        this.state.events.map((ev: IEvent) => {
                            return <Card>       
                                    <GridWrapper>
                                        <GridItem size="1/1" element="li">
                                            <GridItem size="7/12" element="li">
                                                <p>
                                                    <h4>Live on {ev.liveDate?.toLocaleDateString()}</h4>
                                                    <h6>{ev.name}</h6>                                  
                                                    {ev.startDate?.toLocaleDateString()} to {ev.endDate.toLocaleDateString()} <br/>
                                                    {GetStoreTypesLabel(ev.storeTypes)}                                
                                                </p>
                                            </GridItem>
                                            <GridItem size="5/12" element="li" style={{marginTop:40}}>
                                                <ButtonGroupWrapper>
                                                    <ButtonGroupSecondary>
                                                        <button type="button"
                                                        className="ln-c-button ln-c-button--text ln-u-margin-right" 
                                                        onClick={()=>this.props.navigate(`${EventRoutes.StoreSettings}/${ev.id}`)}
                                                        style={{textDecoration:'underline'}}>
                                                        Store settings
                                                        </button>
                                                    </ButtonGroupSecondary>&nbsp;&nbsp;
                                                    <ButtonGroupPrimary>
                                                        <button type="button" 
                                                            className="ln-c-button ln-c-button--outlined" 
                                                            onClick={()=>this.props.navigate(`${EventRoutes.EditEvent}/${ev.id}`)}>
                                                            Edit
                                                        </button>
                                                    </ButtonGroupPrimary>
                                                </ButtonGroupWrapper>
                                            </GridItem>
                                            <GridItem size="1/2" element="li">
                                                <></>
                                            </GridItem>
                                            <GridItem size="1/2" element="li">
                                                <PledgeSummaryReport 
                                                    event={ev}/>
                                            </GridItem>
                                        </GridItem>
                                    </GridWrapper>
                            </Card>
                        })                    
                    }   
                </Fieldset>
            </ListGroup>
        }
        else {
            return<></>;
        }
    }
}

export default function EventsPage() {
    const useNavigation = useNavigate();    
    const services = useServices();
    return <Events navigate={useNavigation} services={services}></Events>;
}