import { appenv } from "../../env";
import { GeocodingResult, ICoordinates, ILocalityResult } from "./ILocalities";

export class LocalitiesService {
    public async getLocationAutoComplete(searchKey: string): Promise<ILocalityResult | undefined> {
        if(searchKey !== undefined && searchKey.trimEnd().length >= 4) {
            const requestUrl = `${appenv.EXPERIAN_API_BASE_URL}/address/search/v1`;

            const body:string = JSON.stringify({
                "country_iso": "GBR",
                "datasets": [
                  "gb-address"
                ],
                "max_suggestions": 5,
                "components": {
                  "unspecified": [
                    searchKey
                  ]
                }
            });
    
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Auth-Token": appenv.EXPERIAN_TOKEN
                },
                method: 'POST',
                body: body
            });

            if(response.ok) {
                const result = await response.json();
                return result as ILocalityResult;
            }

            const err = await response.text();
            console.log(err);
            throw new Error("Cannot add event.");
        }
    }

    public async getGeocoding(addressKey: string): Promise<ICoordinates | undefined> {
        const requestUrl = `${appenv.EXPERIAN_API_BASE_URL}/enrichment/v2`;
        const body = JSON.stringify(this.getGeocodingInput(addressKey));
        const response = await fetch(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Auth-Token": appenv.EXPERIAN_TOKEN
            },
            method: 'POST',
            body: body
        });

        if(response.ok) {
            const result = await response.json();
            const geocodingResult = result as GeocodingResult;
            if(geocodingResult.result !== undefined 
                && geocodingResult.result.geocodes !== undefined) {
                    return {
                        lat: geocodingResult.result.geocodes.latitude,
                        long: geocodingResult.result.geocodes.longitude
                    };
            }

            return undefined;
        }

        const err = await response.text();
        console.log(err);
        throw new Error("Cannot add event.");
    }

    private getGeocodingInput(addressKey: string) {
        return {
            "country_iso": "GBR",
            "keys": {
              "global_address_key": addressKey
            },
            "attributes": {
              "geocodes": [
                "latitude",
                "longitude"
              ]
            }
        };
    }
}