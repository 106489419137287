import * as React from "react";
import { Fieldset, Legend, RadioButtonField, FormOptionFieldList, SelectField, Label, TextAreaField } from '@jsluna/form';
import { FilledButton, ButtonGroupWrapper, ButtonGroupPrimary, ButtonGroupSecondary } from '@jsluna/button';
import { PageModes } from "../PageModes";
import { Heading3, Text } from '@jsluna/typography';
import { IBasePageProps } from "../IBasePageProps";
import { useNavigate, useParams } from "react-router";
import { Container } from "@jsluna/grid";
import { Modal, ModalHeading } from "@jsluna/modal";
import { appenv } from "../../env";
import { IStoreDateAvailability, IStoreEvent } from "../../models/storeevents/IStoreEvent";
import { IEventPledgeInput } from "../../models/pledging/IEventPledgeInput";
import { IUpdatePledgeInput } from "../../models/pledging/IUpdatePledgeInput";
import { ICancelPledgeInput } from "../../models/pledging/ICancelPledgeInput";
import { AuthorisationRoutes, PledgingRoutes } from "../../Routes";
import { Notification, NotificationTypes } from "../Common/Notification";
import { Exceptions } from "../../services/pledgingservice/EventPledgingService";
import { IShiftTime } from "../../models/events/IEvent";
import { IMyPledgeDatesModel } from "../../models/pledging/IMyPledgeDatesModel";
import { useServices } from "../../providers/hooks/useServices";

interface IPledgeProps extends IBasePageProps {
    mode: PageModes;
    eventName?: string;    
    eventId: string,
    pledgeID?: string,
    storeName?: string,
    storeCode?: string,
    storeAddress?: string,
}

interface IError {
    [key:string]: string;
}

interface IPledge {
    eventId?: string,
    storeCode?: string,
    storeAddress?: string,    
    pledgeDate?: Date,
    pledgeShift?: string,
    additionalInfo?: string,
    actualPledgeDate?: Date
}

interface IPledgeState {
    storeEvent?: IStoreEvent;
    pledgeEvent: IPledge;
    errors: IError;
    isModalboxOpen: boolean;
    pledgeCancelReason?: string;
    loading: boolean;
    displayError: boolean;
    currentUserPledges?: IMyPledgeDatesModel[];
    shiftTimings?: IShiftTime[];
}

const cancelOptions = [
    { label: "Emergency leave including sickness", value: 'Emergency leave including sickness' },
    { label: "Pledging at a different location", value: 'Pledging at a different location' },
    { label: "Unable to fulfil pledge due to unexpected work requirements", value: 'Unable to fulfil pledge due to unexpected work requirements' },
    { label: "Store no longer requires support", value: 'Store no longer requires support' }
];

class Pledge extends React.Component<IPledgeProps, IPledgeState> {
    constructor(props: IPledgeProps) {
        super(props);        
        this.state= {
            pledgeEvent:{},
            errors:{},
            isModalboxOpen: false,
            loading: true,
            displayError: false
        };

        this.setPledgeInfoState = this.setPledgeInfoState.bind(this);
    }

    public async componentDidMount() {
        if(this.props.eventId !== undefined
            && this.props.storeCode !== undefined) {
            try {
                const storeEvent = await this.props.services.storeEventService
                    .getStoreEvent(this.props.eventId, this.props.storeCode);

                let pledgeInfo: IPledge = {};
                let shiftTimings = await this.props.services.eventPledgeService
                    .getShiftTimingsForEvent(this.props.eventId);
                let currentUserPledges = await this.props.services.eventPledgeService
                        .getUsersPledgeForEvent(this.props.eventId);
                if(this.props.mode === PageModes.Edit && this.props.pledgeID !== undefined) {
                    try {
                        const userPledge = await this.props.services.eventPledgeService
                            .getPledgeForEvent(parseInt(this.props.pledgeID));
                    
                        pledgeInfo.pledgeDate = userPledge.date;                        
                        pledgeInfo.pledgeShift = userPledge.shiftTime.toString();
                        pledgeInfo.additionalInfo = userPledge.pledgingComments;
                        pledgeInfo.actualPledgeDate = userPledge.date;
                    }
                    catch(err) {
                        if(err === Exceptions.Unauthorised_Exception) {
                            this.props.navigate(AuthorisationRoutes.UnauthorisedPage);
                        }
                    }
                }

                this.setState({
                    loading: false,
                    storeEvent: storeEvent,
                    pledgeEvent: pledgeInfo,
                    currentUserPledges: currentUserPledges,
                    shiftTimings: shiftTimings
                });
            } catch(err) {
                console.log(err);
                  this.setState({
                    loading: false,
                    displayError: true
                });
            }
        }
    }

    public render(): React.ReactNode {
        if(this.state.loading) {
            return <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
        }

        if(this.state.displayError) {
            return <Notification type={NotificationTypes.Error} 
            display={true} 
            displayMessage= "Unable to process your request"
            onClose={()=>{
                this.setState({
                  displayError: false
                });
            }}/>
        }

        if(this.state.storeEvent === undefined) {
            return <></>;
        }

        const dateOptions = this.getEventdateOptions(this.state.storeEvent.storeDates, this.state.pledgeEvent.pledgeDate);

        if(dateOptions.length === 0) {
            return <Container free size="xs">
                <Heading3 element="div" className='bottompaddingSuccessmsg'>
                    There are no dates available to pledge
                </Heading3>
                <ButtonGroupWrapper>
                    <ButtonGroupSecondary className='btnLeftOnPopup'>
                        <FilledButton
                            onClick={()=>window.location.href=`${appenv.OURSAINSBURYS_URL}`}>
                            Return to Our Sainsbury's
                        </FilledButton>
                    </ButtonGroupSecondary>
                    <ButtonGroupPrimary className='btnRightOnPopup'>
                        <button type="button" 
                            className="ln-c-button ln-c-button--outlined"
                            onClick={()=>this.props.navigate(`${PledgingRoutes.SearchPledge}`)}>
                            Make another pledge
                        </button>
                    </ButtonGroupPrimary>
                </ButtonGroupWrapper>
            </Container>;
        }

        return <>
            <Container free size="xs">  
                <Legend fontStyle="h2" className='topHeaderPadding'>Pledge details</Legend>
                <Fieldset>                    
                    <Label htmlFor="intro">Event</Label>
                    <Text  element="p" typeStyle="body-2">
                        {this.state.storeEvent.eventName}
                    </Text>
                    <Label htmlFor="intro">Store</Label>
                    <Text  element="p" typeStyle="body-2">
                        {this.state.storeEvent.storeName}
                    </Text>
                    <Label htmlFor="intro">Store address</Label>
                    <Text  element="p" typeStyle="body-2">
                        { this.formatStoreAddress(this.state.storeEvent) }
                    </Text>
                        
                    <RadioButtonField 
                        onClick={this.setPledgeInfoState.bind(this)}
                        label="Select a date"
                        name="pledgeDate"
                        fullWidth
                        options={dateOptions}
                        error={this.state.errors["pledgeDate"]}
                        required />
                    {
                        (this.state.shiftTimings !== undefined && this.state.shiftTimings?.length > 0) &&
                        <RadioButtonField 
                            onClick={this.setPledgeInfoState.bind(this)}
                            label="Select a shift"
                            name="pledgeShift"
                            fullWidth
                            options={this.getEventshiftOptions()} 
                            error={this.state.errors["pledgeShift"]}
                            required />
                    }
                      
                    <TextAreaField name="additionalInfo" 
                        label="Use this comments box to send any messages to the store, do not email them. Please do not include any personal, sensitive or medical information. " 
                        value={this.state.pledgeEvent.additionalInfo} 
                        onChange={this.setPledgeInfoState.bind(this)}/>
                    {
                        this.props.mode === PageModes.New &&
                        <ButtonGroupPrimary>
                            <FilledButton 
                                onClick={this.submitPledge.bind(this)}
                                disabled={this.state.shiftTimings === undefined || this.state.shiftTimings?.length === 0}>
                                Submit pledge
                            </FilledButton>
                        </ButtonGroupPrimary>
                    }
                    {
                        this.props.mode === PageModes.Edit &&
                        <ButtonGroupWrapper className="ln-u-hard-top ln-u-push-bottom">
                            <ButtonGroupPrimary className='btnLeftOnPopup'>
                                <FilledButton onClick={this.updatePledge.bind(this)}
                                    disabled={this.state.shiftTimings === undefined || this.state.shiftTimings?.length === 0}>
                                    Update pledge
                                </FilledButton>
                            </ButtonGroupPrimary> 
                            <ButtonGroupSecondary className='btnRightOnPopup'>
                                <button type="button" onClick={this.openModalbox.bind(this)} 
                                    className="ln-c-button ln-c-button--outlined"
                                    disabled={this.state.shiftTimings === undefined || this.state.shiftTimings?.length === 0}>
                                    Remove pledge
                                </button>
                            </ButtonGroupSecondary>
                        </ButtonGroupWrapper>
                    }
                </Fieldset>

                <Modal
                    fullScreen
                    restrictClose
                    alert
                    handleClose={this.closeModalbox.bind(this)}
                    open={this.state.isModalboxOpen}
                    headingId="dialog-modal">
                    <ModalHeading element="h3">Remove pledge</ModalHeading>
                    <p>
                        You are about to cancel your store pledge. Please let us know why you're cancelling it.
                    </p>
                    <SelectField 
                        name="pledgeCancelReason" 
                        label="Reason for cancellation" 
                        options={cancelOptions} 
                        required
                        error={this.state.errors["pledgeCancelReason"]}
                        onChange={this.setPledgeCancel.bind(this)}
                    />
                    <ButtonGroupWrapper actionbar>   
                        <ButtonGroupPrimary>
                            <button type="button" 
                                className="ln-c-button ln-c-button--outlined"
                                onClick={this.closeModalbox.bind(this)}>
                                Cancel
                            </button>     
                       </ButtonGroupPrimary>                      
                       <ButtonGroupSecondary>
                            <FilledButton onClick={this.removePledge.bind(this)}>
                                Remove pledge
                            </FilledButton>
                        </ButtonGroupSecondary>                      
                    </ButtonGroupWrapper>
                </Modal>
            </Container>
        </>;
    }

    private formatStoreAddress(st: IStoreEvent): string {
        return `${st.storeAddress1}${st.storeAddress2?`, ${st.storeAddress2}`: ''}, ${st.postTown}, ${st.postCode}`;
    }

    private setPledgeCancel(e: any){
        let errors = this.state.errors;
        if(e.target.value === undefined || e.target.value === "" || e.target.value === null) {
            errors[e.target.name] = "This information is required.";
        } else if(errors[e.target.name] !== undefined) {
            delete errors[e.target.name];
        }

        this.setState({
            errors: errors,
            pledgeCancelReason: e.target.value
        });
    }

    private setPledgeInfoState(e: any) {
        if(this.state.storeEvent !== undefined) {
            switch(e.target.name) {
                case 'pledgeDate':
                    const eventDates = this.state.storeEvent.storeDates
                        .filter(ed=>ed.eventDate.toLocaleDateString() === e.target.value);
                    if(eventDates.length > 0) {
                        let errors = this.state.errors;
                        if(errors[e.target.name] !== undefined) {
                            delete errors[e.target.name];
                        }

                        this.setState({
                            pledgeEvent: {
                                ...this.state.pledgeEvent,
                                pledgeDate: eventDates[0].eventDate
                            },
                            errors: errors
                        });
                    }
                    break;
                default:
                    let errors = this.state.errors;
                    if(e.target.value === undefined || e.target.value === "" || e.target.value === null) {
                        errors[e.target.name] = "This information is required.";
                    } else if(errors[e.target.name] !== undefined) {
                        delete errors[e.target.name];
                    }
                    this.setState({
                        pledgeEvent: {
                         ...this.state.pledgeEvent,
                         [e.target.name]: e.target.value
                        },
                        errors: errors
                     });
            }
            
        }
    }

    private getEventdateOptions(storeDates: IStoreDateAvailability[], selectedDate?: Date): FormOptionFieldList {
        let options: FormOptionFieldList = [];

        let currentPledges: IMyPledgeDatesModel[] = [];
        if(this.state.currentUserPledges !== undefined) {
            currentPledges = this.state.currentUserPledges;
        }

        storeDates = storeDates.sort((a: IStoreDateAvailability, b: IStoreDateAvailability)=>{
            return a.eventDate.getTime() - b.eventDate.getTime();
        });
    
        let availableDates: IStoreDateAvailability[] = [];
        if(this.props.mode === PageModes.New) {
            availableDates = storeDates.filter(sd=>sd.available);
        } else {
            try {
                availableDates = storeDates.filter(sd=>sd.available
                    || (!sd.available && sd.eventDate.toLocaleDateString() === this.state.pledgeEvent.actualPledgeDate?.toLocaleDateString()));
            } catch(err) {
                console.log(err);
            }
        }

        availableDates.forEach((d)=> {
            let addDate = true;

            if(this.props.mode === PageModes.New) {
                addDate = currentPledges.filter(x=>x.eventDate.toLocaleDateString() === d.eventDate.toLocaleDateString()).length === 0;
            } else {
                if(currentPledges.filter(x => x.eventDate.toLocaleDateString() === d.eventDate.toLocaleDateString() 
                    && x.eventDate.toLocaleDateString() === this.state.pledgeEvent.actualPledgeDate?.toLocaleDateString()).length > 0) {
                    addDate = true;
                } else {
                    addDate = currentPledges.filter(x=>x.eventDate.toLocaleDateString() === d.eventDate.toLocaleDateString()).length === 0;
                }
            }
            
            if(addDate) {
                options.push({
                    value: d.eventDate.toLocaleDateString(),
                    label: d.eventDate.toLocaleDateString(),
                    checked: d.eventDate === selectedDate
                });
            }
        });

        if(this.state.pledgeEvent !== undefined
            && this.state.pledgeEvent.pledgeDate !== undefined) {
            const selectedOption = this.state.pledgeEvent.pledgeDate.toLocaleDateString();
            options.forEach((option) => {
                if(option.value === selectedOption){
                    option.checked = true;
                }
            });
        }

        return options;
    }

    private getEventshiftOptions(): FormOptionFieldList {
        let options:any[] = [];
        if(this.state.shiftTimings !== undefined) {
            this.state.shiftTimings.forEach((t)=>{
                options.push({
                    label: t.shiftTiming,
                    value: t.id.toString()
                });
            });
        }
        
        if(this.state.pledgeEvent !== undefined
            && this.state.pledgeEvent.pledgeShift !== undefined) {
            const selectedOption = this.state.pledgeEvent.pledgeShift;
            
            options.forEach((option) => {                
                if(option.value === selectedOption.toString()) option.defaultChecked = true;
            });
        }

        return options;
    }
    

    private isValidForm() {
        let valid = true;
        const valMessage = "This information is requied";
        let errors: IError = {};        

        if(this.state.pledgeEvent.pledgeDate === undefined){
            valid = false;
            errors["pledgeDate"] = valMessage;
        }

        if(this.state.pledgeEvent.pledgeShift === undefined){
            valid = false;
            errors["pledgeShift"] = valMessage;
        }

        if(!valid){
            this.setState({
                errors: errors
            });
        }

        return valid;
    }

    private async submitPledge() {
        try {
            if(this.isValidForm()
                && this.state.pledgeEvent !== undefined
                && this.state.pledgeEvent.pledgeDate !== undefined
                && this.state.pledgeEvent.pledgeShift !== undefined
                && this.state.storeEvent !== undefined) {
                
                const pledgeInput: IEventPledgeInput = {
                    date: new Date(this.state.pledgeEvent.pledgeDate),                    
                    pledgedDate: new Date(),
                    pledgingComments: this.state.pledgeEvent.additionalInfo !== undefined ? this.state.pledgeEvent.additionalInfo: "",
                    shiftTime: parseInt(this.state.pledgeEvent.pledgeShift),
                    store: this.state.storeEvent.storeCode,
                    storeName: this.state.storeEvent.storeName,
                    storeAddress: this.formatStoreAddress(this.state.storeEvent),
                    status: false
                };
              
                this.setState({
                    loading: true,
                });

                await this.props.services.eventPledgeService
                    .pledgeForEvent(this.props.eventId, pledgeInput);

                this.props.navigate(PledgingRoutes.PledgeComplete);
            }
        } catch (error) {
            console.log(error);
            this.setState({
                  loading: false,
                  displayError: true
              });
        } 
    }

    private async updatePledge() {
        try {
            if(this.isValidForm() 
            && this.state.storeEvent !== undefined
            && this.state.pledgeEvent.pledgeDate !== undefined
            && this.state.pledgeEvent.pledgeShift !== undefined
            && this.props.pledgeID !== undefined) {
                const updateInput: IUpdatePledgeInput = {
                    storeName: this.state.storeEvent.storeName,
                    storeCode: this.state.storeEvent.storeCode,
                    storeAddress: this.formatStoreAddress(this.state.storeEvent),
                    eventDate: new Date(this.state.pledgeEvent.pledgeDate),
                    pledgeDate: new Date(),
                    pledgingComment: this.state.pledgeEvent.additionalInfo !== undefined ? this.state.pledgeEvent.additionalInfo: "",
                    pledgeStatus: true,
                    shift: parseInt(this.state.pledgeEvent.pledgeShift)
                };

                try {
                    this.setState({
                        loading: true,
                    });

                    await this.props.services.eventPledgeService
                        .updatePledgeForEvent(parseInt(this.props.pledgeID), updateInput);
                    this.props.navigate(PledgingRoutes.PledgeUpdated);
                    
                } catch(err) {
                    if(err === Exceptions.Unauthorised_Exception) {
                        this.props.navigate(AuthorisationRoutes.UnauthorisedPage);
                    }
                    
                    console.log(err);
                    this.setState({
                        loading: false,
                        displayError: true
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    private async removePledge() {
        try {
            const valMessage = "This information is requied";
            let errors: IError = {};

            if(this.state.pledgeCancelReason === undefined || this.state.pledgeCancelReason === "") {
                errors["pledgeCancelReason"] = valMessage;
                this.setState({
                    errors:errors
                });
            }
            else if(this.props.pledgeID !== undefined && this.state.storeEvent !== undefined) {
                try {
                    const cancelInput: ICancelPledgeInput = {
                        storeName: this.state.storeEvent.storeName,
                        withdrawn: true,
                        withdrawnDate: new Date(),
                        withdrawnReason: this.state.pledgeCancelReason
                    };
                  
                    this.setState({
                        loading: true,
                    });

                    await this.props.services.eventPledgeService
                        .cancelPledgeForEvent(parseInt(this.props.pledgeID), cancelInput);
                    this.props.navigate(PledgingRoutes.PledgeCancelled);
                } catch(err) {
                    if(err === Exceptions.Unauthorised_Exception) {
                        this.props.navigate(AuthorisationRoutes.UnauthorisedPage);
                    }

                    console.log(err);
                    this.setState({
                      loading: false,
                      displayError: true
                  });
                }
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false,
                displayError: true
            }); 
        }
    }

    private async openModalbox() {
        this.setState(
            {
                isModalboxOpen: true
            }
        );       
    }
    private closeModalbox() {
        this.setState({
            isModalboxOpen: false
        });
    }
}

export default function PledgePage() {
    let navigate = useNavigate();    
    const services = useServices();
    const {eventId, storeCode} = useParams();
    if(eventId && storeCode) {
        return <Pledge navigate={navigate} mode={PageModes.New} eventId={eventId} storeCode={storeCode} services={services} />
    } else {
        return <></>;
    }
}

export function PledgeUpdatedPage() {
    let navigate = useNavigate();
    const services = useServices();
    const {eventId, storeCode, pledgeId} = useParams();    
    if(eventId && storeCode) {
     return <Pledge navigate={navigate} mode={PageModes.Edit} eventId={eventId} storeCode={storeCode} pledgeID={pledgeId} services={services}/>
    } else {
     return <></>;
    }
}