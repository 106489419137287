import { Fieldset, Label, Legend, SelectField, SelectOptionList } from '@jsluna/form';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { Table } from '@jsluna/table';
import { Tabs, TabLink, TabPanel } from '@jsluna/tabs';
import * as React from 'react';
import { Notification, NotificationTypes } from "../Common/Notification";
import { useNavigate } from 'react-router';
import { IBasePageProps } from '../IBasePageProps';
import '../pledgemanagement/Pledgestyles.scss';
import { ILineManagerReport } from '../../models/reporting/ILineManagerReport';
import { IPledgeEvent } from '../../models/pledging/IPledgeEvent';
import { useServices } from '../../providers/hooks/useServices';

interface ITeamPledgesProps extends IBasePageProps {
}

interface ITeamPledgesState {
    events?: IPledgeEvent[];
    selectedEvent?: IPledgeEvent;
    report?: ILineManagerReport;
    loading: boolean;
    displayError: boolean;
    requirementMessage?: string;
    selectedTab: string;
}

const columns = [
    {
        name: "Name",
        accessor: (rowData: any) => ({ value: rowData.userDisplayName }),
        hideLabel: true,
    },
    {
        name: "Store",
        accessor: (rowData: any) => ({ value: rowData.storeName }),
        hideLabel: true
    },
    {
        name: "Date",
        accessor: (rowData: any) => ({ value:  formatDate(rowData.eventDate) }),
        hideLabel: true
    },
    {
        name: "Shift",
        accessor: (rowData: any) => ({ value: rowData.shiftTime }),
        hideLabel: true
    },
    {
        name: "Criteria met",
        accessor: (rowData: any) => ({ value: getRequirementLabel(rowData.metRequirement) }),
        hideLabel: true
    },
    {
        name: "Comments",
        accessor: (rowData: any) => ({ value: rowData.pledgeComments }),
        hideLabel: true
    }
];

const noPledgeColumns = [
    {
        name: "Name",
        accessor: (rowData: any) => ({ value: rowData.displayName }),
        hideLabel: true,
    },
    {
        name: "Mail",
        accessor: (rowData: any) => ({ value: rowData.mail }),
        hideLabel: true,
    }
];

function getRequirementLabel(value: boolean) {
    return value ? "Yes" : "No";
}

function formatDate(value: string) {
    let date = new Date(value);
    return date.toLocaleDateString();
}

export class TeamPledges extends React.Component<ITeamPledgesProps, ITeamPledgesState> {
    private readonly tabs = [
        { name: 'Colleague pledges', key: 'pledges' },
        { name: 'Colleagues with no pledges', key: 'nopledges' },
    ];

    constructor(props: ITeamPledgesProps){
        super(props);

        this.state = {
            loading: true,
            displayError: false,
            selectedTab: "pledges"
        }

        this.getUserPledgeDetails = this.getUserPledgeDetails.bind(this);
    }

    public async componentDidMount() {
        try {
            const events = await this.props.services.reportingService
                .getAllEvents();
            this.setState({
                events: events,
                loading: false
            });
        } catch(err) {
            this.setState({
                displayError: true,
                loading: false
            });
        }
    }

    public render(): React.ReactNode {
        if(this.state.loading) {
            return <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
        }

        if(this.state.displayError) {
            return <Notification type={NotificationTypes.Error} 
            display={true} 
            displayMessage= "Unable to process your request"
            onClose={()=>{
                this.setState({
                  displayError: false
                });
            }}/>
        }

        return<>         
            <Container free size="xs">
                <Legend fontStyle="h2" className='topHeaderPadding'>My team pledges</Legend>
                <Fieldset>
                    <SelectField 
                        name="Event" 
                        label="Select an event" 
                        options={this.getEventOptions()}
                        onChange={this.getUserPledgeDetails.bind(this)}
                    />
                    {
                        (this.state.report === undefined) &&
                            <Label htmlFor="norecords" className="emptyMessagebox">There are no pledges available to display</Label>
                    }
                    {
                        (this.state.report !== undefined) &&
                        <div>
                            {
                                this.state.selectedEvent?.minPledgeDays && this.state.selectedEvent?.minPledgeDays > 0 ?
                                    <>
                                    <Label htmlFor="msgReq">The minimum requirement for "{this.state.selectedEvent?.name}" is {this.state.selectedEvent?.minPledgeDays} days.</Label>
                                    <br/>
                                    Number of colleagues  who meet the requirement: <Label htmlFor="metreq">{this.state.report.usersMetRequirement}</Label>
                                    <br/>
                                    Number of colleagues who do not meet the requirement: <Label htmlFor="metreq">{this.state.report.usersNotMetRequirement}</Label>
                                    </>:
                                    <Label htmlFor="msgReq">There is no minimum requirement for "{this.state.selectedEvent?.name}".</Label>
                            }
                            
                            <>
                                <Tabs type="deep">
                                    {this.tabs.map(tab => (
                                    <TabLink
                                        key={tab.key}
                                        onClick={() => this.setState({ selectedTab: tab.key})}
                                        active={this.state.selectedTab === tab.key}>
                                        {tab.name}
                                    </TabLink>
                                    ))}
                                </Tabs>
                                <TabPanel>
                                    <GridWrapper>
                                        <GridItem size="1/1" element="div">
                                            {
                                                this.state.selectedTab === "pledges" ?                                                
                                                <Table
                                                    rowKey="username"
                                                    data={this.state.report.pledges}
                                                    caption="User pledge informations"
                                                    visuallyHiddenCaption
                                                    columns={columns}>
                                                </Table>:
                                                <>
                                                    {
                                                        this.state.report.nonPledgeUsers.length > 0 ?
                                                        <>
                                                            <Table
                                                                rowKey="username"
                                                                data={this.state.report.nonPledgeUsers}
                                                                caption="User pledge informations"
                                                                visuallyHiddenCaption
                                                                columns={noPledgeColumns}>
                                                            </Table>
                                                        </>:
                                                        <Label htmlFor="norecords" className="emptyMessagebox">
                                                            There are no colleagues to display
                                                        </Label>
                                                    }
                                                </>
                                            }
                                        </GridItem>
                                    </GridWrapper>
                                </TabPanel>
                            </>
                        </div>
                    }
                </Fieldset>
            </Container>
        </>;
    }

    private getEventOptions() {
        let eventOptions: SelectOptionList = [];
        if(this.state.events !== undefined) {
            this.state.events.forEach((e)=> {
                let selected = e.eventId.toLowerCase() === this.state.selectedEvent?.eventId;
                eventOptions.push({
                    label: e.name,
                    value: e.eventId,
                    selected: selected
                });
            });
        }

        return eventOptions;
    }

    private async getUserPledgeDetails(e: any) {
        try {
            this.setState({
                loading: true
            });
            
            const report = await this.props.services.reportingService
                .getLineManagerReport(e.target.value);
            const selectedEvent = this.state.events?.find(x=>x.eventId === e.target.value);

            const storeCodes: string[] = [];
            report.pledges.forEach((s:any)=> {
                if(storeCodes.indexOf(s.storeCode) === -1) {
                    storeCodes.push(s.storeCode);
                }
            });

            const stores = await this.props.services.storeEventService
                .getStoresByCodes(storeCodes);
            stores.forEach((s)=>{
                const items = report.pledges.filter((x:any)=>x.storeCode === s.storeCode);
                items.forEach((p)=>{
                    p.storeName = s.storeName
                });
            });

            this.setState({
                selectedEvent: selectedEvent,
                report: report,
                loading: false
            });
        } catch(err) {
            console.log(err);
            this.setState({
                displayError: true,
                loading: false
            });
        }
    }
}

export default function TeamPledgesPage() {
    const navigate = useNavigate();    
    const services = useServices();
    return <TeamPledges navigate={navigate} services={services}/>;
}