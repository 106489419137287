export const EventRoutes = {
    Events: "/events",
    CreateEvent: "/createevent",
    EditEvent: "/editevent",
    EventSubmitted: "/eventsubmitted",
    EventUpdated: "/eventupdated",
    EventRemoved: "/eventremoved",
    StoreSettings: "/storesettings",
    HomePageText: "/settings/homepagetext"
};

export const PledgingRoutes = {
    SearchPledge: "/",
    EventPledge: "/pledge",
    EditPledge: "/editpledge",
    MyPledges: "/mypledges",
    MyStorePledges: "/mystorepledges",
    PledgeComplete: "/pldegecomplete",
    PledgeUpdated: "/pldegeupdated",
    PledgeCancelled: "/pldegecancelled",
    TeamPledges: "/teampledges",
    SwitchName: "/namechange",
    LeadersReport: "/leadersreport",
    DivisionSummaryReport: "/divisionsummaryreport",
    Exemptions: "/exemptions"
}

export const AuthorisationRoutes = {
    UnauthorisedPage: "/unauthorised"
}
