import { SiteLayout } from "@jsluna/site-layout";
import { SiteHeader } from './SiteHeader';
import { Container } from '@jsluna/grid';
import { AuthenticationManager } from "../AuthenticationManager";

export /**
 * The layout component for store working
 * @param props 
 * @returns  
 */
const Layout = (props: any) => {
    return <SiteLayout stickyHeader>
        <AuthenticationManager />
        <SiteHeader />
        <Container soft size="m">
            <div id="main-content">
                {props.children}
            </div>
        </Container>      
    </SiteLayout>;
}