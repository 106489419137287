import { MsalHelper } from "../../auth/MsalHelper";
import { appenv } from "../../env";
import { IUserProfile } from "../../models/reporting/ILineManagerReport";
import { IChangeNameService } from "./IChangeNameService";

export class ChangeNameService implements IChangeNameService {
    constructor(private readonly msalHelper: MsalHelper) {
    }
    
    public async searchMyPledgeDirectReport(queryText: string): Promise<IUserProfile[]> {
        const errorMessage = "Error querying my direct reports";
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/DirectReports/my/search/${queryText}`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                return result as IUserProfile[];
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }

    public async getAllDirectReports(): Promise<IUserProfile[]> {
        const errorMessage = "Error get all my direct reports";
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/DirectReports/my`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                return result as IUserProfile[];
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }

    public async switchDirectReportName(upn: string, targetUpn: string): Promise<boolean> {
        const errorMessage = "Error switching direct report name";
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/DirectReports/switch/name`;
        const body = {
            upn: upn,
            targetUpn: targetUpn
        };

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {                    
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: JSON.stringify(body)
            });

            if(response.ok) {
                return true;
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }
}
