import { Fieldset } from '@jsluna/form';
import { Body1, Heading6 } from '@jsluna/typography'
import { FilledButton, ButtonGroupWrapper, ButtonGroupPrimary, ButtonGroupSecondary } from '@jsluna/button';
import { NavigateFunction, useNavigate } from 'react-router';
import React from 'react';
import { EventRoutes } from '../../Routes';
import { PageModes } from '../PageModes';

interface IEventSubmittedProps {
    navigate: NavigateFunction;
    mode: PageModes;
}

class EventSubmitted extends React.Component<IEventSubmittedProps> {

    public render(): React.ReactNode {

        let message: string = "";
        switch(this.props.mode) {
            case PageModes.New:
                message = "Your event has been created successfully.";
                break;
            case PageModes.Edit:
                message = "Your event has been updated successfully.";
                break;
            case PageModes.Cancel:
                message = "Your event has been removed successfully.";
                break;
        }

        return<>
        <Fieldset>
        <Heading6 element="div">
            {
                message
            }
        </Heading6>
        <Body1 element="p">The event will go live.</Body1>
        <ButtonGroupWrapper>
            <ButtonGroupSecondary className='btnLeftOnPopup'>                
                <FilledButton onClick={this.navigatetoEvents.bind(this)}>
                    View my events
                </FilledButton>
            </ButtonGroupSecondary>
            <ButtonGroupPrimary className='btnRightOnPopup'>
                <button type="button" 
                    className="ln-c-button ln-c-button--outlined"
                    onClick={this.navigatetoCreateEvents.bind(this)}>
                    Create another event
                </button>                
            </ButtonGroupPrimary>
        </ButtonGroupWrapper>
        </Fieldset>
        </>;
    }

    private navigatetoEvents() {
        this.props.navigate(EventRoutes.Events);
    }

    private navigatetoCreateEvents() {
        this.props.navigate(EventRoutes.CreateEvent);
    }
}

export default function EventSubmittedPage() {
    const navigate = useNavigate();
    return <EventSubmitted navigate={navigate} mode={PageModes.New} />;
}

export function EventUpdatedPage() {
    const navigate = useNavigate();
    return <EventSubmitted navigate={navigate} mode={PageModes.Edit} />;
}

export function EventRemovedPage() {
    const navigate = useNavigate();
    return <EventSubmitted navigate={navigate} mode={PageModes.Cancel} />;
}