import { Configuration } from "@azure/msal-browser";
import { appenv } from "../env";

/**
 * Msal configuration
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: appenv.MSAL_AUTH_CLIENTID,
        authority: appenv.MSAL_AUTH_AUTHORITY,
        redirectUri: appenv.MSAL_AUTH_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    }
};

/**
 * Login request containing scope for connecting to backend api
 */
export const loginRequest = {
    scopes: [appenv.API_SCOPE]
};