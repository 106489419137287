import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from "react";
import { MsalHelper } from "../auth/MsalHelper";

/**
 * The authentication manager component
 * @returns  
 */
export const AuthenticationManager = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const msalHelper = new MsalHelper(instance);

    useEffect(() => {
        async function login() {
            await msalHelper.handleLogin();
        }

        if(!isAuthenticated) {
            login();
        }
    });

    return <></>;
}