import * as React from 'react';
import { Modal, ModalHeading } from '@jsluna/modal';
import {
    FilledButton,
    OutlinedButton,
    ButtonGroupWrapper,
    ButtonGroupPrimary,
    ButtonGroupSecondary,
  } from '@jsluna/button';

import { TextAreaField, SelectField } from '@jsluna/form';

interface IExemptionModelProps {
    showExemptModel: boolean;
    onClose(): void;
    onExempt(team: boolean, reasonForExempt?: string, otherReason?: string): void;
}

interface IExemptionModelState {
    reasonForExempt?: string;
    otherReason?: string;
}

const exemptOptions = [
    { label: "Requirements of role", value: 'Requirements of role' },
    { label: "Holiday", value: 'Holiday' },    
    { label: "3rd Party Contractor", value: '3rd Party Contractor' },
    { label: "Other / Medical", value: 'Other / Medical' }
];

export class ExemptionModel extends React.Component<IExemptionModelProps, IExemptionModelState> {
    constructor(props: IExemptionModelProps) {
        super(props);

        this.state = {
        };
    }

    public render(): React.ReactNode {
        return <Modal
                fullScreen
                restrictClose
                alert
                open={this.props.showExemptModel}
                headingId="dialog-modal">
                    <ModalHeading element="h3">
                        Exempt Colleagues/Teams
                    </ModalHeading>
                    <SelectField name="exemptReason" label="Select reason for exemption" 
                        options={exemptOptions} 
                        onChange={this.onExemptReasonSelected.bind(this)}
                        defaultValue={this.state.reasonForExempt}
                        error={this.state.reasonForExempt === '' ? "Select reason for exemption": ""}/>
                    
                    <TextAreaField name="exempt-reason" label="Reason for exemption (please do not include any personal, sensitive or medical information)"
                        value={this.state.otherReason}
                        onChange={(e: any)=> this.setState({
                            otherReason: e?.target.value
                        })}
                        error={this.state.reasonForExempt === 'Other' && this.state.otherReason === '' ? "Enter a reason": ""}/>
                    <ButtonGroupWrapper actionbar>
                        <ButtonGroupSecondary>
                            <OutlinedButton
                                className="ln-u-margin-right"
                                onClick={() => this.props.onClose()}>
                                Cancel
                            </OutlinedButton>
                        </ButtonGroupSecondary>
                        <ButtonGroupPrimary>
                            <FilledButton onClick={this.onExempt.bind(this)}>Exempt</FilledButton>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <FilledButton onClick={this.onExemptTeam.bind(this)}>Exempt Team</FilledButton>
                        </ButtonGroupPrimary>
                    </ButtonGroupWrapper>
            </Modal>;
    }

    private onExemptReasonSelected(value: any) {
        this.setState({
            reasonForExempt: value.target.value
        });
    }

    private onExempt() {
        this.props.onExempt(false, this.state.reasonForExempt, this.state.otherReason);
    }

    private onExemptTeam() {
        this.props.onExempt(true, this.state.reasonForExempt, this.state.otherReason);
    }
}