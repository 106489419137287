import { MsalHelper } from "../../auth/MsalHelper";
import { appenv } from "../../env";

import { ILiveEvent } from "../../models/storeevents/ILiveEvent";
import { IStore } from "../../models/storeevents/IStore";
import { IStoreEvent } from "../../models/storeevents/IStoreEvent";
import { IStoreSearchInput } from "../../models/storeevents/IStoreSearchInput";
import { IStoreSearchResult } from "../../models/storeevents/IStoreSearchResult";
import { IStoreEventService } from "./IStoreEventService";

export class StoreEventService implements IStoreEventService {
    constructor(private readonly msalHelper: MsalHelper) {
    }

    public async searchStores(eventId: string, query: string, storeTypes: number[]): Promise<IStore[]> {
        const requestUrl: string = `${appenv.API_STOREEVENTS_BASEURL}/stores/search/${eventId}`;
        const body = {
            query : query,
            storeTypes: storeTypes
        };

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: JSON.stringify(body)
            });

            if(response.ok) {
                const stores: IStore[] = await response.json();
                return stores;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting stores by store codes');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting stores by store codes');
        }
    }
    
    public async getStoresByCodes(storeCodes: string[]): Promise<IStore[]> {
        const requestUrl: string = `${appenv.API_STOREEVENTS_BASEURL}/stores/getstoresbycodes`;
        const body = JSON.stringify({
            storeCodes: storeCodes
        });

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: body
            });

            if(response.ok) {
                const stores: IStore[] = await response.json();
                return stores;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting stores by store codes');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting stores by store codes');
        }
    }

    public async getStoreEvent(eventId: string, storeCode: string): Promise<IStoreEvent> {
        const requestUrl: string = `${appenv.API_STOREEVENTS_BASEURL}/events/${eventId}/stores/${storeCode}`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {                
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                const storeEvent: IStoreEvent = {
                    eventId: result.eventId,
                    eventName: result.eventName,
                    storeName: result.storeName,
                    storeCode: result.storeCode,
                    storeAddress1: result.storeAddress1,
                    storeAddress2: result.storeAddress2,
                    postTown: result.postTown,
                    postCode: result.postCode,
                    lat: result.lat,
                    long: result.long,
                    storeDates: []
                };

                result.storeDates.forEach((sd: any)=>{
                    storeEvent.storeDates.push({
                        eventDate: new Date(sd.eventDate),
                        available: sd.available
                    });
                });
                
                return storeEvent;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting store event');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting store event');
        }
    }

    public async getLiveEvents(): Promise<ILiveEvent[]> {
        const requestUrl: string = `${appenv.API_STOREEVENTS_BASEURL}/events`;

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {                
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const events: ILiveEvent[] = await response.json();
                return events;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting live events');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting live events');
        }
    }

    public async getAllEvents(): Promise<ILiveEvent[]> {
        const requestUrl: string = `${appenv.API_STOREEVENTS_BASEURL}/events/all`;

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const events: ILiveEvent[] = await response.json();
                return events;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting live events');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting live events');
        }
    }

    public async searchNearestStores(eventId: string, input: IStoreSearchInput): Promise<IStoreSearchResult[]> {
        const requestUrl: string = `${appenv.API_STOREEVENTS_BASEURL}/events/${eventId}/stores`;
        const body: string = JSON.stringify(input);

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: body
            });
            
            if(response.ok) {
                const stores: IStoreSearchResult[] = await response.json();
                return stores;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting live events');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting live events');
        }
    }
}