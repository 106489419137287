import { EventManagementService } from "./eventmanagement/EventManagementService";
import { IEventManagementService } from "./eventmanagement/IEventManagementService";
import { DirectReportService } from "./exemptions/DirectReportService";
import { ExemptionsService } from "./exemptions/ExemptionsService";
import { IDirectReportService } from "./exemptions/IDirectReportService";
import { IExemptionsService } from "./exemptions/IExemptionsService";
import { EventPledgeService } from "./pledgingservice/EventPledgingService";
import { IEventPledgeService } from "./pledgingservice/IEventPledgeService";
import { IReportingService } from "./reportingservice/IReportingService";
import { ReportingService } from "./reportingservice/ReportingService";
import { ConfigurationService } from "./storeevents/ConfigurationService";
import { IConfigurationService } from "./storeevents/IConfigurationService";
import { IStoreEventService } from "./storeevents/IStoreEventService";
import { StoreEventService } from "./storeevents/StoreEventService";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalHelper } from "../auth/MsalHelper";
import { IChangeNameService } from "./pledgingservice/IChangeNameService";
import { ChangeNameService } from "./pledgingservice/ChangeNameService";

export interface IServiceCollection {
    eventManagementService: IEventManagementService;
    storeEventService: IStoreEventService;
    eventPledgeService: IEventPledgeService;
    reportingService: IReportingService;
    configurationService: IConfigurationService;
    exemptionsService: IExemptionsService;
    directReportService: IDirectReportService;
    changeNameService: IChangeNameService;
}

export function getServices(instance: IPublicClientApplication): IServiceCollection {
    const msalHelper = new MsalHelper(instance);
    return {
        eventManagementService: new EventManagementService(msalHelper),
        storeEventService: new StoreEventService(msalHelper),
        eventPledgeService: new EventPledgeService(msalHelper),
        reportingService: new ReportingService(msalHelper),
        configurationService: new ConfigurationService(msalHelper),
        exemptionsService: new ExemptionsService(msalHelper),
        directReportService: new DirectReportService(msalHelper),
        changeNameService: new ChangeNameService(msalHelper)
    }
}