import { MsalHelper } from "../../auth/MsalHelper";
import { appenv } from "../../env";
import { IConfiguration } from "../../models/storeevents/IConfiguration";
import { IConfigurationService } from "./IConfigurationService";

export class ConfigurationService implements IConfigurationService {
    constructor(private readonly msalHelper: MsalHelper) {
    }

    public async setConfiguration(name: string, value: string): Promise<void> {
        const requestUrl: string = `${appenv.API_STOREEVENTS_BASEURL}/configurations`;
        const body = {
            name: name,
            value: value
        };

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                method: 'PATCH',
                body: JSON.stringify(body)
            });

            if(response.ok) {
                return;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting live events');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting configuration');
        }
    }
    
    public async getConfiguration(name: string): Promise<IConfiguration> {
        const requestUrl: string = `${appenv.API_STOREEVENTS_BASEURL}/configurations/${name}`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const config: IConfiguration = await response.json();
                return config;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting live events');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting configuration');
        }
    }
}