import * as React from 'react';
import { useNavigate } from 'react-router';
import { useMsal } from '@azure/msal-react';
import { ColleagueSearch } from './ColleagueSearch';
import { IExemptionState } from './IExemptionState';
import { Fieldset, Label, Legend, SelectField, SelectOptionList } from '@jsluna/form';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { Modal, ModalHeading } from '@jsluna/modal';
import { IExemptionProps } from './IExemptionProps';
import { MsalHelper } from '../../auth/MsalHelper';
import { Notification, NotificationTypes } from '../Common/Notification';
import { AccountInfo } from '@azure/msal-browser';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, OutlinedButton } from '@jsluna/button';
import { ExemptionModel } from './ExemptionModel';
import { ColleagueList } from './ColleagueList';
import { IExemption } from '../../models/exemptions/IExemption';
import { Impersonate } from '../Common/Impersonate';
import { useServices } from '../../providers/hooks/useServices';

export class Exemption extends React.Component<IExemptionProps, IExemptionState> {
    private readonly account?: AccountInfo;    
    public constructor(props: any) {
        super(props);
        
        this.account = this.props.msalHelper.getAccount();
        this.state = {
            loading: false,
            displayError: false,
            showExemptionModel: false,
            showRemoveConfirmation: false,
            currentUserName: this.account?.username,
            isImpersonating: false
        }
    }

    public async componentDidMount() {
        try {
            const directReportExists = await this.ensureDirectReports();
            if(directReportExists) {
                await this.loadEvents();
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false,
                displayError : true
            });
        }
    }

    public render(): React.ReactNode {
        if(this.state.loading) {
            return <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
        }

        if(this.state.displayError) {
            return <Notification type={NotificationTypes.Error} 
            display={true} 
            displayMessage="Unable to process your request"
            onClose={()=>{
                this.setState({
                  displayError: false
                });
            }}/>
        }
        
        return <Container free>
                <Legend fontStyle="h2" className='topHeaderPadding'>Exemptions</Legend>
                <Fieldset>
                    <Impersonate msalHelper={this.props.msalHelper} onImpersonate={this.onImpersonate.bind(this)}/>
                    {
                        this.state.isImpersonating &&
                        <Label htmlFor=''>
                            Testing as colleague '{this.state.currentUserName}'
                        </Label>
                    }
                    <SelectField
                            className="exemption-field"
                            name="Event"
                            label="Select an event" 
                            options={this.getEventOptions()}
                            onChange={this.getExemptions.bind(this)} />
                    {
                        this.state.selectedEvent !== undefined &&
                        <ColleagueSearch
                            services={this.props.services}
                            username={this.state.currentUserName}
                            onColleaguesSelected={(value)=>this.setState({selectedColleagues: value})}/>
                    }
                    {
                        this.state.showExemptionModel &&
                        <ExemptionModel showExemptModel={this.state.showExemptionModel} 
                            onExempt={this.onExempt.bind(this)}
                            onClose={()=>this.setState({
                                showExemptionModel: false
                            })}/>
                    }
                    {
                        this.renderRemoveConfirmationModel()
                    }
                    {
                        (this.state.exemptions !== undefined && this.state.exemptions.length > 0) &&
                        <GridWrapper>
                            <GridItem key="1" size="1/1" element="div">
                                <ButtonGroupSecondary>
                                    <FilledButton type="submit"
                                        disabled={this.state.selectedColleagues && this.state.selectedColleagues.length > 0 ? false: true}
                                        onClick={()=>this.setState({
                                            showExemptionModel: true
                                        })}>
                                            Exempt
                                    </FilledButton>
                                </ButtonGroupSecondary>
                                <ButtonGroupPrimary>
                                    <FilledButton type="submit"
                                        disabled={this.state.selectedColleagues && this.state.selectedColleagues.length > 0 ? false: true}
                                        onClick={()=>this.setState({
                                            showRemoveConfirmation: true
                                        })}>
                                            Remove Exemption
                                    </FilledButton>
                                </ButtonGroupPrimary>
                            </GridItem>
                            <GridItem key="1" size="1/1" element="li">
                                <ColleagueList exemptions={this.state.exemptions} onColleaguesSelected={this.onColleaguesSelected.bind(this)}/>
                            </GridItem>
                        </GridWrapper>
                    }
                </Fieldset>
            </Container>;
    }

    private renderRemoveConfirmationModel() {
        return <Modal
                fullScreen
                restrictClose
                alert
                open={this.state.showRemoveConfirmation}
                headingId="dialog-modal">
                    <ModalHeading element="h3">
                        Remove Colleagues/Teams Exemption
                    </ModalHeading>
                    <p>
                        Are you sure want to remove the exemption?
                    </p>
                    <ButtonGroupWrapper actionbar>
                        <ButtonGroupSecondary>
                            <OutlinedButton
                                className="ln-u-margin-right"
                                onClick={() => this.setState({
                                    showRemoveConfirmation: false
                                })}>
                                Cancel
                            </OutlinedButton>
                        </ButtonGroupSecondary>
                        <ButtonGroupPrimary>
                            <FilledButton onClick={()=>this.onRemoveExemption(false)}>Remove</FilledButton>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <FilledButton onClick={()=>this.onRemoveExemption(true)}>Remove Team</FilledButton>
                        </ButtonGroupPrimary>
                    </ButtonGroupWrapper>
            </Modal>;
    }

    private onImpersonate(username: string) {
        this.setState({
            currentUserName: username,
            isImpersonating: true
        }, async ()=>{
            try {
                const directReportExists = await this.ensureDirectReports();
                if(directReportExists) {
                    await this.loadEvents();
                }
        } catch (error) {
                console.log(error);
                this.setState({
                    loading: false,
                    displayError : true
                });
            }
        }); 
    }

    private getEventOptions(): SelectOptionList {
        let eventOptions: any[] = [];
        if(this.state.events) {
            const selectedEvent = this.state.selectedEvent;
            this.state.events.forEach((e)=>{
                let selected = false;
                if(selectedEvent !== undefined) {
                    selected = selectedEvent.eventId === e.eventId;
                }

                eventOptions.push({
                    label: e.name,
                    value: e.eventId,
                    selected: selected
                });
            });
        }

        return eventOptions;
    }

    private onColleaguesSelected(selected: IExemption[]): void {
        const selectedColleagues: string[] = [];
        selected.forEach((x: IExemption) => {
            selectedColleagues.push(x.userPrincipalName);
        });

        this.setState({
            selectedColleagues: selectedColleagues
        });
    }

    private async getExemptions(ev: React.ChangeEvent<HTMLSelectElement>) {
        await this.loadExemptions(ev.target.value);
    }

    private async onExempt(exemptTeam: boolean, reasonForExempt?: string, otherReason?: string) {
        if(this.state.currentUserName !== undefined
            && this.state.selectedEvent !== undefined
            && this.state.selectedColleagues !== undefined
            && this.state.selectedColleagues.length > 0) {
                this.setState({
                    showExemptionModel: false,
                    loading: true,
                    loadingMessage: "Operation in progress..."
                });

                let exemptReason: string | undefined;
                if(reasonForExempt === 'Other'
                    && otherReason === '') {
                        return;
                } else if(reasonForExempt === 'Other') {
                    exemptReason = otherReason;
                } else {
                    exemptReason = reasonForExempt;
                }

                if(exemptTeam) {
                    await this.props.services.exemptionsService
                        .exemptTeam(this.state.selectedEvent?.eventId, this.state.currentUserName, this.state.selectedColleagues, exemptReason);
                } else {
                    await this.props.services.exemptionsService
                        .exemptColleagues(this.state.selectedEvent?.eventId, this.state.selectedColleagues, exemptReason);
                }

                await this.loadExemptions(this.state.selectedEvent?.eventId);
        }
    }

    private async onRemoveExemption(exemptTeam: boolean) {
        if(this.state.currentUserName !== undefined
            && this.state.selectedEvent !== undefined
            && this.state.selectedColleagues !== undefined
            && this.state.selectedColleagues.length > 0) {
                this.setState({
                    showRemoveConfirmation: false,
                    loading: true,
                    loadingMessage: "Operation in progress..."
                });

                if(exemptTeam) {
                    await this.props.services.exemptionsService
                        .removeTeamsExemptions(this.state.selectedEvent?.eventId, this.state.currentUserName, this.state.selectedColleagues);
                } else {
                    await this.props.services.exemptionsService
                        .removeColleaguesExemptions(this.state.selectedEvent?.eventId, this.state.selectedColleagues);
                }

                await this.loadExemptions(this.state.selectedEvent?.eventId);
        }
    }

    private async loadExemptions(eventId: string) {
        if(eventId && this.state.currentUserName !== undefined) {

            this.setState({
                loading: true,
                loadingMessage: "Loading exemptions...",
                selectedEvent: this.state.events?.find(x=> x.eventId === eventId)
            });

            try {
                const username = this.state.currentUserName;
                const exemptions = await this.props.services.exemptionsService
                    .getExemptionsForEvent(eventId, username);
                this.setState({
                    exemptions: exemptions,
                    loading: false,
                    loadingMessage: undefined
                });
            } catch(e) {
                this.setState({
                    loading: false,
                    loadingMessage: undefined,
                    displayError : true
                });
            }
        }
    }

    private async loadEvents(): Promise<void> {
        this.setState({
            loading: true
        });

        const events = await this.props.services.eventManagementService.getEvents();
        this.setState({
            events: events,
            loading: false
        });
    }

    private async ensureDirectReports(): Promise<boolean> {
        if(this.state.currentUserName !== undefined) {
            const directReportService = this.props.services.directReportService;
            const username = this.state.currentUserName;
            const exists = await directReportService.exists(username);
            if(exists) {
                return true;
            } else {
                this.setState({
                    loading: true,
                    loadingMessage: "Generating direct report hierarchy"
                });

                const request = await directReportService.generate(username);
                if(request) {
                    const intervalId = window.setInterval(async() => {
                        try {
                            const status = await directReportService.getGenerationStatus(request.id);
                            if(status.completed) {
                                clearInterval(intervalId);

                                this.setState({
                                    loading: false,
                                    loadingMessage: undefined
                                });
                                
                                await this.loadEvents();
                            }
                        } catch(e) {
                            this.setState({
                                loading: false,
                                loadingMessage: undefined,
                                displayError: true
                            });
                        }
                    }, 1000);
                }

                return false;
            }
        }

        return false;
    }
}

export default function ExemptionsPage() {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const msalHelper = new MsalHelper(instance);
    const services = useServices();
    return <Exemption navigate={navigate} msalHelper={msalHelper} services={services}/>;
}