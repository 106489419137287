import * as React from 'react';
import { IBasePageProps } from '../../IBasePageProps';
import { MsalHelper } from '../../../auth/MsalHelper';
import { useNavigate } from 'react-router';
import { useMsal } from '@azure/msal-react';
import { useServices } from '../../../providers/hooks/useServices';
import { IDivisionSummaryReport } from '../../../models/reporting/IDivisionSummaryReport';
import { AuthorisationRoutes } from '../../../Routes';
import { IPledgeEvent } from '../../../models/pledging/IPledgeEvent';
import { useCSVDownloader } from '../../Common/useCSVDownloader';
import { Notification, NotificationTypes } from '../../Common/Notification';
import { Container } from '@jsluna/grid';
import { Fieldset, Legend, SelectField, SelectOptionList } from '@jsluna/form';
import { DivisionSummary } from './DivisionSummary';

interface IDivisionSummaryReportProps extends IBasePageProps {
    msalHelper: MsalHelper;
    CSVDownloader: any;
}

interface IDivisionSummaryReportState {
    events?: IPledgeEvent[];
    selectedEvent?: IPledgeEvent;
    report?: IDivisionSummaryReport;
    loading: boolean;
    displayError: boolean;
}

class DivisionSummaryReport extends React.Component<IDivisionSummaryReportProps, IDivisionSummaryReportState> {
    constructor(props:IDivisionSummaryReportProps) {
        super(props);

        this.state = {
            loading: false,
            displayError: false
        };
    }

    public async componentDidMount(): Promise<void> {
        try {
            const isAdmin = await this.props.services.eventManagementService.isAdmin();
            if(!isAdmin) {
                this.props.navigate(AuthorisationRoutes.UnauthorisedPage);
            }

            const events = await this.props.services.reportingService.getAllEvents();
            this.setState({
                events: events,
                loading: false
            });
        } catch(err) {
            console.log(err);
            this.setState({
                loading: false,
                displayError: true
            });
        }
    }

    public render(): React.ReactNode {
        if(this.state.displayError) {
            return <Notification type={NotificationTypes.Error} 
                display={true} 
                displayMessage= "Unable to process your request"
                onClose={()=>{
                    this.setState({
                    displayError: false
                    });
                }}/>
        }
        
        return <Container free>
            <Legend fontStyle="h2" className='topHeaderPadding'>Division Summary Report</Legend>
            <Fieldset>
                <SelectField
                        name="Event" 
                        label="Select an event" 
                        options={this.getEventOptions()}
                        onChange={this.generateReport.bind(this)} />
                {
                    this.state.loading &&
                    <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
                }
                {
                    this.state.report &&
                    <DivisionSummary report={this.state.report} CSVDownloader={this.props.CSVDownloader}/>
                }
            </Fieldset>
        </Container>;
    }

    private getEventOptions() {
        let eventOptions: SelectOptionList = [];
        if(this.state.events !== undefined) {
            this.state.events.forEach((e)=> {
                let selected = e.eventId.toLowerCase() === this.state.selectedEvent?.eventId;
                eventOptions.push({
                    label: e.name,
                    value: e.eventId,
                    selected: selected
                });
            });
        }

        return eventOptions;
    }

    private async generateReport(ev: any) {
        try {
            if(ev.target.value) {
                const eventId = ev.target.value;
                this.setState({
                    loading: true
                }, async()=>{
                    const reportId = await this.props.services.reportingService.generateDivisionSummaryReport(eventId);
                    const interval = window.setInterval(async ()=> {
                        const report = await this.props.services.reportingService
                            .getDivisionSummaryReport(reportId);
    
                        if(report !== undefined && report.status === 1) {
                            window.clearInterval(interval);
                            this.setState({
                                report: report,
                                loading: false,
                                displayError: false
                            });
                        } else if(report !== undefined && report.status === 2) {
                            window.clearInterval(interval);
                            console.log(report.errorMessage);
                            this.setState({
                                loading: false,
                                displayError: true
                            });
                        }
                    }, 5000);
                });
            }
        } catch(err) {
            console.log(err);
            this.setState({
                loading: false,
                displayError: true
            });
        }
    }
}

export default function DivisionSummaryReportPage() {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const msalHelper = new MsalHelper(instance);
    const services = useServices();
    const CSVDownloader = useCSVDownloader();
    return <DivisionSummaryReport navigate={navigate} msalHelper={msalHelper} services={services} CSVDownloader={CSVDownloader}/>
}
