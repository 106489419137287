import { ICreateEvent } from "../../models/events/ICreateEvent";
import { IEvent } from "../../models/events/IEvent";
import { IEventManagementService } from "./IEventManagementService";
import { appenv } from "../../env";
import { IUpdateEvent } from "../../models/IUpdateEvent";
import { IUpdateStoreEvent } from "../../models/events/IUpdateStoreEvent";
import { IStoreEvent } from "../../models/events/IStoreEvent";
import moment from "moment";
import { MsalHelper } from "../../auth/MsalHelper";

export class EventManagementService implements IEventManagementService {
    constructor(private readonly msalHelper: MsalHelper) {
    }

    public async removeStoresFromEvent(eventId: number, stores: string[]): Promise<void> {
        const requestUrl = `${appenv.API_EVENTMANAGEMENT_BASEURL}/sevents/${eventId}/stores`;
        const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
        const body:string = JSON.stringify({
            stores: stores
        });

        const response = await fetch(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            method: 'DELETE',
            body: body
        });

        if(!response.ok) {
            const err = await response.text();
            console.log(err);
            throw new Error("Cannot remove stores from event.");
        }
    }

    public async removeEvent(eventId: number): Promise<void> {
        const requestUrl = `${appenv.API_EVENTMANAGEMENT_BASEURL}/events/${eventId}`;        
        const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
        const response = await fetch(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            method: 'DELETE'
        });

        if(!response.ok) {
            const err = await response.text();
            console.log(err);
            throw new Error("Cannot add event.");
        }
    }

    public async isAdmin(): Promise<boolean> {
        const requestUrl = `${appenv.API_EVENTMANAGEMENT_BASEURL}/events/currentuser/isadmin`;
        const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
        const response = await fetch(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            method: 'GET'
        });

        if(response.ok) {
            const result = await response.json();
            return result.isAdmin;
        }
        
        const err = await response.text();
        console.log(err);
        throw new Error("Cannot add event.");
    }
    
    public async getEvents(): Promise<IEvent[]> {
        const requestUrl = `${appenv.API_EVENTMANAGEMENT_BASEURL}/events`;
        const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
        try{
            const response = await fetch(requestUrl, {
                headers: {                
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                let events: IEvent[] = [];
                result.forEach((item: any)=>{
                  events.push({
                    id: item.id,
                    eventId: item.eventId,
                    name: item.name,
                    startDate: new Date(item.startDate),
                    endDate: new Date(item.endDate),
                    liveDate: new Date(item.liveDate),
                    maxPledges: item.maxPledges,
                    storeTypes: item.storeTypes,
                    stores: item.stores,
                    shiftTimings: item.shiftTimings,
                    minPledgeDays: item.minPledgeDays
                  });
                });

                return events;
           }
        }
        catch(err) {
            console.log(err);
            throw new Error('Error getting events.');
        }      

        return [];
    }

    public async getEventById(eventId: number): Promise<IEvent> {
        const requestUrl = `${appenv.API_EVENTMANAGEMENT_BASEURL}/events/${eventId}`;
        const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
        try {
            const response = await fetch(requestUrl, {
                headers: {                
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const item = await response.json();
                const event: IEvent = {
                    id: item.id,
                    eventId: item.eventId,
                    name: item.name,
                    startDate: new Date(item.startDate),
                    endDate: new Date(item.endDate),
                    liveDate: new Date(item.liveDate),
                    maxPledges: item.maxPledges,
                    storeTypes: item.storeTypes,
                    stores: item.stores,
                    shiftTimings: item.shiftTimings,
                    minPledgeDays: item.minPledgeDays
                };
                
                return event;
            }

            const err = await response.text();
            console.log(err);
            throw new Error("Cannot get the event."); 
        }
        catch(err) {
            console.log(err);
            throw new Error("return event by id has failed");
        }     

    }

    public async addEvent(eventInfo: ICreateEvent): Promise<IEvent> {
        const requestUrl = `${appenv.API_EVENTMANAGEMENT_BASEURL}/events`;        
        this.attachDateFormat(eventInfo.startDate);
        this.attachDateFormat(eventInfo.endDate);
        this.attachDateFormat(eventInfo.liveDate);

        const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
        const body:string = JSON.stringify(eventInfo);
        console.log(body);
        const response = await fetch(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            method: 'POST',
            body: body
        });

        if(response.ok) {
            const ev: IEvent = await response.json();
            return ev;
        }
        
        const err = await response.text();
        console.log(err);
        throw new Error("Cannot add event.");
    }

    public async updateEvent(eventId: number, eventInfo: IUpdateEvent): Promise<IEvent> {
        const requestUrl = `${appenv.API_EVENTMANAGEMENT_BASEURL}/events/${eventId}`;
        const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
        this.attachDateFormat(eventInfo.startDate);
        this.attachDateFormat(eventInfo.endDate);
        this.attachDateFormat(eventInfo.liveDate);

        const body:string = JSON.stringify(eventInfo);
        console.log(body);
        const response = await fetch(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            method: 'PATCH',
            body: body
        });

        if(response.ok) {
            const ev: IEvent = await response.json();
            return ev;
        }
        
        const err = await response.text();
        console.log(err);
        throw new Error("Cannot update the event.");
    }

    public async getEventStoreSettings(eventId: number): Promise<IStoreEvent[]> {
        const requestUrl = `${appenv.API_EVENTMANAGEMENT_BASEURL}/sevents/${eventId}/stores`;
        const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
        try{
            const response = await fetch(requestUrl, {
                headers: {                
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const events = await response.json();
                return events;
           }
        }
        catch(err) {
            console.log(err);
        }      

        return [];
    }

    public async updateEventStoreSettings(eventId: number, storeSetInfo: IUpdateStoreEvent[]): Promise<void> {
        const requestUrl = `${appenv.API_EVENTMANAGEMENT_BASEURL}/sevents/${eventId}/stores`;
        storeSetInfo.forEach((s)=>{
            this.attachDateFormat(s.eventDate);
        });

        const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
        const body:string = JSON.stringify(storeSetInfo);        
        const response = await fetch(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            method: 'POST',
            body: body
        });

        if(!response.ok) {
            const err = await response.text();
            console.log(err);
            throw new Error("Cannot update the store settings details.");
        }
    }    
    
    private attachDateFormat(d: Date) {
        d.toJSON = function(){
            var date = Date.UTC(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours(), this.getMinutes())
            return moment(date).format(); 
        }
    }
}