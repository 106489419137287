import * as React from 'react';
import { Fieldset, Label, Legend, SelectField, SelectOptionList } from '@jsluna/form';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { BreadcrumbsWrapper, BreadcrumbsItem } from '@jsluna/breadcrumbs';
import { Notification, NotificationTypes } from "../../Common/Notification";
import { useNavigate } from 'react-router';
import { useMsal } from '@azure/msal-react';
import { MsalHelper } from '../../../auth/MsalHelper';
import { IExemptedProfile, ILeaderReport, ReportStatus } from '../../../models/reporting/ILeaderReport';
import { AccountInfo } from '@azure/msal-browser';
import { LineManagerReport } from './LineManagerReport';
import { Button } from '@jsluna/button';
import { AuthorisationRoutes } from '../../../Routes';
import { IPledgeEvent } from '../../../models/pledging/IPledgeEvent';
import { useServices } from '../../../providers/hooks/useServices';
import { IBasePageProps } from '../../IBasePageProps';
import { Impersonate } from '../../Common/Impersonate';

interface IReportNav {
    text: string;
    key: string;
    prevNav?: IReportNav;
}

interface ILeadersReportProps extends IBasePageProps {
    msalHelper: MsalHelper;
}

interface ITeamPledgesState {
    events?: IPledgeEvent[];
    selectedEvent?: IPledgeEvent;
    allDirectReports?: ILeaderReport[];
    currentDirectReports?: ILeaderReport[];
    currentLineManagerReport?: ILeaderReport;
    leaderReport?: ILeaderReport;
    currentExemptions: IExemptedProfile[];
    loading: boolean;
    loadingMessage?: string;
    reportLoading: boolean;
    displayError: boolean;
    requirementMessage?: string;
    selectedTab: string;
    reportNav: IReportNav[];
    isImpersonating: boolean;
    currentUserName?: string;
}

class LeadersReport extends React.Component<ILeadersReportProps, ITeamPledgesState> {
    private readonly account?: AccountInfo;
    constructor(props: ILeadersReportProps){
        super(props);

        this.account = this.props.msalHelper.getAccount();
        this.state = {
            loading: true,
            reportLoading: false,
            displayError: false,
            selectedTab: "pledges",
            reportNav: [],
            currentExemptions: [],
            isImpersonating: false,
            currentUserName: this.account?.username
        }

        this.onReportNav = this.onReportNav.bind(this);
    }

    public async componentDidMount() {
        try {
            // check for ssc leader
            const isSSCLeader = await this.props.services.eventPledgeService.isSSCLeader();
            const isAdmin = await this.props.services.eventManagementService.isAdmin();
            if(!isSSCLeader && !isAdmin) {
                this.props.navigate(AuthorisationRoutes.UnauthorisedPage);
            }

            const directReportExists = await this.ensureDirectReports();
            if(directReportExists) {
                await this.loadEvents();
            }
        } catch(err) {
            this.setState({
                loading: false,
                displayError: true
            })
        }
    }

    public render(): React.ReactNode {
        if(this.state.loading) {
            return <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
        }

        if(this.state.displayError) {
            return <Notification type={NotificationTypes.Error} 
                display={true} 
                displayMessage= "Unable to process your request"
                onClose={()=>{
                    this.setState({
                    displayError: false
                    });
                }}/>
        }

        return<>
            <Container free size="xs">
                <Legend fontStyle="h2" className='topHeaderPadding'>Team Reporting</Legend>
                <Fieldset>
                    <Impersonate msalHelper={this.props.msalHelper} onImpersonate={this.onImpersonate.bind(this)}/>
                    {
                        this.state.isImpersonating &&
                        <Label htmlFor=''>
                            Testing as colleague '{this.state.currentUserName}'
                        </Label>
                    }
                    <SelectField 
                        name="Event" 
                        label="Select an event" 
                        options={this.getEventOptions()}
                        onChange={this.getUserPledgeDetails.bind(this)} />
                    {
                        this.state.reportLoading && 
                         <Notification type={NotificationTypes.Progress} display={true} 
                            displayMessage="Generating Report. Please wait..." onClose={()=>{}}/>
                    }
                    {
                        (!this.state.reportLoading && this.state.allDirectReports === undefined) &&
                            <Label htmlFor="norecords" className="emptyMessagebox">Select an event to view the report.</Label>
                    }
                    {
                        (!this.state.reportLoading && this.state.currentLineManagerReport !== undefined && this.state.currentDirectReports !== undefined
                            && this.state.allDirectReports !== undefined) &&
                        <GridWrapper>
                            <GridItem size="1/1" element="div">
                                {this.renderReportNav()}
                            </GridItem>
                            <GridItem size="1/1" element="div">
                                <LineManagerReport 
                                    allDirectReports={this.state.allDirectReports}
                                    directReports={this.state.currentDirectReports}
                                    lineManagerReport={this.state.currentLineManagerReport}
                                    onLineManagerSelected = {this.onLineManagerSelected.bind(this)}
                                    exemptions={this.state.currentExemptions} 
                                    onExemptColleague={this.onColleagueExempt.bind(this)}
                                    onExemptTeam={this.onColleagueTeam.bind(this)}/>
                            </GridItem>
                        </GridWrapper>
                    }
                </Fieldset>
            </Container>
        </>;
    }

    private getEventOptions() {
        let eventOptions: SelectOptionList = [];
        if(this.state.events !== undefined) {
            this.state.events.forEach((e)=> {
                let selected = e.eventId.toLowerCase() === this.state.selectedEvent?.eventId;
                eventOptions.push({
                    label: e.name,
                    value: e.eventId,
                    selected: selected
                });
            });
        }

        return eventOptions;
    }

    private async getUserPledgeDetails(ev: any) {
        try {
            if(this.account !== undefined
                && ev.target.value
                && this.state.currentUserName) {
                const eventId = ev.target.value;
                const selectedEvent = this.state.events?.find(e=>e.eventId === eventId);
                if(selectedEvent !== undefined) {
                    this.setState({
                        reportLoading: true,
                        loading: false
                    });
                    
                    // start generating the report
                    const reportId = await this.props.services.reportingService
                        .generateSSCLeaderReport(eventId, this.state.currentUserName);

                    const interval = window.setInterval(async ()=> {
                        const report = await this.props.services.reportingService
                            .getSSCLeaderReport(reportId);
                        if(report !== undefined
                            && this.account !== undefined
                            && report.status === ReportStatus.Completed
                            && this.state.currentUserName) {
                            window.clearInterval(interval);

                            // filter for current ssc leader
                            let currentUser = this.state.currentUserName;

                            const exemptions = await this.props.services.eventPledgeService
                                .getExemptions(eventId, currentUser);
                            const currentDirectReports =
                                report.directReports?.filter(r=>r.lineManager.toLocaleLowerCase() === currentUser?.toLocaleLowerCase());
                            if(currentDirectReports !== undefined) {
                                let currentDirectReportsCopy = JSON.parse(JSON.stringify(currentDirectReports));
                                currentDirectReportsCopy.forEach((lreport: ILeaderReport) => {
                                    if(report.directReports !== undefined) {
                                        this.rollupPledgeInfo(report.directReports, lreport);
                                    }
                                });

                                const storeCodes: string[] = [];
                                report.report.pledges.forEach((s:any)=> {
                                    if(storeCodes.indexOf(s.storeCode) === -1) {
                                        storeCodes.push(s.storeCode);
                                    }
                                });
                
                                const stores = await this.props.services.storeEventService
                                    .getStoresByCodes(storeCodes);
                                stores.forEach((s)=>{
                                    const items =report.report.pledges.filter((x:any)=>x.storeCode === s.storeCode);
                                    items.forEach((p)=>{
                                        p.storeName = s.storeName
                                    });
                                });

                                this.setState({
                                    reportLoading: false,
                                    selectedEvent: selectedEvent,
                                    allDirectReports: report.directReports,
                                    currentDirectReports: currentDirectReportsCopy,
                                    currentLineManagerReport: report.report,
                                    currentExemptions: exemptions,
                                    leaderReport: report.report
                                });
                            }
                        } else if(report !== undefined && report.status === ReportStatus.Error) {
                            window.clearInterval(interval);
                            console.log(report.errorMessage);
                            this.setState({
                                loading: false,
                                displayError: true
                            });
                        }
                    }, 2000);
                }
            }
        } catch(err) {
            console.log(err);
            this.setState({
                loading: false,
                displayError: true
            });
        }
    }

    private async onLineManagerSelected(value: ILeaderReport): Promise<void> {
        if(this.state.allDirectReports !== undefined
            && this.state.selectedEvent !== undefined
            && value.userPrincipalName !== undefined) {
            const currentDirectReports = this.state.allDirectReports
                .filter(r=>r.lineManager.toLocaleLowerCase() === value.userPrincipalName?.toLocaleLowerCase());

            let currentDirectReportsCopy = JSON.parse(JSON.stringify(currentDirectReports));
            currentDirectReportsCopy.forEach((report: ILeaderReport) => {
                if(this.state.allDirectReports !== undefined) {
                    this.rollupPledgeInfo(this.state.allDirectReports, report);
                }
            });

            // load exemptions
            const exemptions = await this.props.services.eventPledgeService
                .getExemptions(this.state.selectedEvent.eventId, value.userPrincipalName);

            let currentNav: IReportNav[] = this.state.reportNav;
            if(currentNav.length > 0) {
                const lastNav = currentNav[currentNav.length - 1];
                currentNav.push({
                    text: value.userDisplayName,
                    key: value.userPrincipalName,
                    prevNav: lastNav
                });
            } else {
                currentNav.push({
                    text: value.userDisplayName,
                    key: value.userPrincipalName
                });
            }

            const storeCodes: string[] = [];
            value.pledges.forEach((s:any)=> {
                if(storeCodes.indexOf(s.storeCode) === -1) {
                    storeCodes.push(s.storeCode);
                }
            });

            const stores = await this.props.services.storeEventService
                .getStoresByCodes(storeCodes);
            stores.forEach((s)=>{
                const items = value.pledges.filter((x:any)=>x.storeCode === s.storeCode);
                items.forEach((p)=>{
                    p.storeName = s.storeName
                });
            });

            this.setState({
                currentDirectReports: currentDirectReportsCopy,
                currentLineManagerReport: value,
                currentExemptions: exemptions,
                reportNav: currentNav
            });
        }
    }

    private rollupPledgeInfo(allReports: ILeaderReport[], creport: ILeaderReport, lineManager?: ILeaderReport) {
        if(allReports !== undefined) {
            let dReports: ILeaderReport[] =[];
            if(lineManager === undefined) {
                dReports = allReports.filter(x=>x.lineManager === creport.userPrincipalName);
            } else {
                dReports = allReports.filter(x=>x.lineManager === lineManager.userPrincipalName);
            }
            
            dReports.forEach(dReport => {
                creport.usersMetRequirement += dReport.usersMetRequirement;
                creport.usersNotMetRequirement += dReport.usersNotMetRequirement;

                this.rollupPledgeInfo(allReports, creport, dReport);
            });
        }
    }

    private renderReportNav() {
        return <BreadcrumbsWrapper className="ln-u-flush-bottom">
            <BreadcrumbsItem>
                <Button circle={false} disabled={false} hard={false} element="button" variant="text"
                    active={this.state.reportNav.length === 0 ? true: false}
                    onClick={()=>this.onReportNav()}>
                    {this.state.currentUserName}
                </Button>
            </BreadcrumbsItem>
            {
                this.state.reportNav.map((nav, index)=>{
                    return   <BreadcrumbsItem active={index === this.state.reportNav.length - 1 ? true: false}>
                                <Button circle={false} disabled={false} hard={false} element="button" variant="text"                                    
                                    onClick={()=>this.onReportNav(nav)}>
                                    {nav.text}
                                </Button>
                            </BreadcrumbsItem>
                })
            }
        </BreadcrumbsWrapper>;
    }

    private async onReportNav(nav?: IReportNav) {
        if(this.state.selectedEvent
            && this.account !== undefined) {
            if(nav === undefined && this.state.currentUserName) {
                let currentUser = this.state.currentUserName;

                // load exemptions
                const exemptions = await this.props.services.eventPledgeService
                    .getExemptions(this.state.selectedEvent.eventId, currentUser?.toLocaleLowerCase());
                const currentDirectReports = this.state.allDirectReports?.filter(r=>r.lineManager.toLocaleLowerCase() === currentUser.toLocaleLowerCase());
    
                let currentDirectReportsCopy = JSON.parse(JSON.stringify(currentDirectReports));
                currentDirectReportsCopy.forEach((report: ILeaderReport) => {
                    if(this.state.allDirectReports !== undefined) {
                        this.rollupPledgeInfo(this.state.allDirectReports, report);
                    }
                });

                this.setState({
                    currentDirectReports: currentDirectReportsCopy,
                    reportNav: [],
                    currentExemptions: exemptions,
                    currentLineManagerReport: this.state.leaderReport
                });
            } else {
                if(nav) {
                    let newNav: IReportNav[] = [];
                    this.state.reportNav.every(rnav => {
                        if(rnav.key === nav.key) {
                            newNav.push({
                                key: rnav.key,
                                text: rnav.text,
                                prevNav: rnav.prevNav
                            });

                            return false;
                        }

                        newNav.push({
                            key: rnav.key,
                            text: rnav.text,
                            prevNav: rnav.prevNav
                        });

                        return true;
                    });

                    const exemptions = await this.props.services.eventPledgeService
                        .getExemptions(this.state.selectedEvent.eventId, nav?.key.toLocaleLowerCase());
                    const lineManagerReport = this.state.allDirectReports?.find(r=>r.userPrincipalName.toLocaleLowerCase() === nav?.key.toLocaleLowerCase());
                    const currentDirectReports = this.state.allDirectReports?.filter(r=>r.lineManager.toLocaleLowerCase() === nav?.key.toLocaleLowerCase());
        
                    let currentDirectReportsCopy = JSON.parse(JSON.stringify(currentDirectReports));
                    currentDirectReportsCopy.forEach((report: ILeaderReport) => {
                        if(this.state.allDirectReports !== undefined) {
                            this.rollupPledgeInfo(this.state.allDirectReports, report);
                        }
                    });

                    this.setState({
                        currentDirectReports: currentDirectReportsCopy,
                        reportNav: newNav,
                        currentExemptions: exemptions,
                        currentLineManagerReport: lineManagerReport
                    });
                }
            }
        }
    }

    private onImpersonate(username: string) {
        this.setState({
            currentUserName: username,
            isImpersonating: true
        }, async ()=>{
            try {
                const directReportExists = await this.ensureDirectReports();
                if(directReportExists) {
                    await this.loadEvents();
                }
        } catch (error) {
                console.log(error);
                this.setState({
                    loading: false,
                    displayError : true
                });
            }
        }); 
    }

    private async loadEvents(): Promise<void> {
        this.setState({
            loading: true
        });

        const events = await this.props.services.reportingService.getAllEvents();
        this.setState({
            events: events,
            loading: false
        });
    }

    private async ensureDirectReports(): Promise<boolean> {
        if(this.state.currentUserName !== undefined) {
            const directReportService = this.props.services.directReportService;
            const username = this.state.currentUserName;
            const exists = await directReportService.exists(username);
            if(exists) {
                return true;
            } else {
                this.setState({
                    loading: true,
                    loadingMessage: "Generating direct report hierarchy"
                });

                const request = await directReportService.generate(username);
                if(request) {
                    const intervalId = window.setInterval(async() => {
                        try {
                            const status = await directReportService.getGenerationStatus(request.id);
                            if(status.completed) {
                                clearInterval(intervalId);

                                this.setState({
                                    loading: false,
                                    loadingMessage: undefined
                                });
                                
                                await this.loadEvents();
                            }
                        } catch(e) {
                            this.setState({
                                loading: false,
                                loadingMessage: undefined,
                                displayError: true
                            });
                        }
                    }, 1000);
                }

                return false;
            }
        }

        return false;
    }

    private async onColleagueExempt(exempt: boolean, upn: string, reason?: string) {
        if(this.state.selectedEvent !== undefined
            && this.state.currentLineManagerReport !== undefined) {
            try {
                if(exempt && reason !== undefined) {
                    await this.props.services.eventPledgeService.addExemption(
                        this.state.selectedEvent.eventId,
                        upn,
                        reason);
                } else if(!exempt) {
                    await this.props.services.eventPledgeService.removeExemption(
                        this.state.selectedEvent.eventId,
                        upn);
                }
                
                const exemptions = await this.props.services.eventPledgeService
                    .getExemptions(this.state.selectedEvent.eventId, 
                        this.state.currentLineManagerReport.userPrincipalName);
                
                this.setState({
                    currentExemptions: exemptions
                });
            } catch(err) {
                console.log(err);
                this.setState({
                    loading: false,
                    displayError: true
                });
            }
        }
    }

    private async onColleagueTeam(upn: string, reason: string) {
        if(this.state.selectedEvent !== undefined
            && this.state.currentLineManagerReport !== undefined) {
            try {
                await this.props.services.eventPledgeService.addTeamExemption(
                    this.state.selectedEvent.eventId,
                    upn,
                    reason);
                
                const exemptions = await this.props.services.eventPledgeService
                    .getExemptions(this.state.selectedEvent.eventId, 
                        this.state.currentLineManagerReport.userPrincipalName);
                
                this.setState({
                    currentExemptions: exemptions
                });
            } catch(err) {
                console.log(err);
                this.setState({
                    loading: false,
                    displayError: true
                });
            }
        }
    }
}

export default function LeadersReportPage() {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const msalHelper = new MsalHelper(instance);
    const services = useServices();
    return <LeadersReport navigate={navigate} msalHelper={msalHelper} services={services}/>;
}