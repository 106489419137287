import * as React from 'react';
import {
    TableContainer,
    TableHeader,
    TableHeaderRow,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell,
} from '@jsluna/table';
import { Checkbox } from '@jsluna/form';
import { IExemption } from '../../models/exemptions/IExemption';

interface IColleagueListProps {
    exemptions: IExemption[];
    onColleaguesSelected(selected: IExemption[]): void;
}

interface ICollagueListState {
    allSelected: boolean;
    selectedRows: IExemption[];
}

const columns = ["Colleague", "Email", "Division", "Line Manager", "Exempted"];

export class ColleagueList extends React.Component<IColleagueListProps, ICollagueListState> {
    constructor(props: IColleagueListProps) {
        super(props);

        this.state = {
            allSelected: false,
            selectedRows: []
        };

        this.handleSelectAllRows = this.handleSelectAllRows.bind(this);
        this.handleSelectRow = this.handleSelectRow.bind(this);
    }

    public render(): React.ReactNode {
        return <TableContainer sortable responsive>
            <TableHeader>
                <TableHeaderRow>
                    <TableHeaderCell key="all-colleagues">
                        <Checkbox
                            name="all-colleagues"
                            inline
                            label=""
                            hideLabel
                            checked={this.state.allSelected}
                            onChange={(e: any) => this.handleSelectAllRows(e.target.checked)} />
                    </TableHeaderCell>
                    {
                        columns.map(column => (
                        <TableHeaderCell key={column}>{column}</TableHeaderCell>
                    ))}
                </TableHeaderRow>
            </TableHeader>
            <TableBody>
                {
                    this.props.exemptions?.map((ex: IExemption) => {
                        return <TableRow key={ex.userPrincipalName}>
                                    <TableCell lastWhenStacked className="ln-u-hard-ends">
                                        <Checkbox
                                            name={`entry-${ex.userPrincipalName}`}
                                            inline
                                            label={`Select ${ex.displayName}`}
                                            hideLabel
                                            checked= {
                                                this.state.selectedRows.find(x=>x.userPrincipalName === ex.userPrincipalName)
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e: any) =>
                                                this.handleSelectRow(ex.userPrincipalName, e.target.checked)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell label={ex.displayName}>{ex.displayName}</TableCell>
                                    <TableCell label={ex.mail}>{ex.mail}</TableCell>
                                    <TableCell label={ex.division}>{ex.division}</TableCell>
                                    <TableCell label={ex.lineManager?.userPrincipalName}>{ex.lineManager?.displayName}</TableCell>
                                    <TableCell label={ex.exempted}>{ex.exempted ? "Yes": "No"}</TableCell>
                                </TableRow>;
                    })
                }
            </TableBody>
        </TableContainer>;
    }

    private handleSelectAllRows(checked: boolean) {
        if(checked) {
            this.setState({
                selectedRows: this.props.exemptions,
                allSelected: true
            });

            this.props.onColleaguesSelected(this.props.exemptions);
        } else {
            this.setState({
                selectedRows: [],
                allSelected: false
            });

            this.props.onColleaguesSelected([]);
        }
    }

    private handleSelectRow(id: string, selected: any) {
        const data = this.props.exemptions;
        let selectedRows = this.state.selectedRows;
        const selectedRow = data?.find(x=>x.userPrincipalName === id);
        if(selected && selectedRow) {
            selectedRows.push(selectedRow);
        } else if(!selected && selectedRow) {
            selectedRows = selectedRows.filter(x=>x.userPrincipalName !== id);
        }

        this.setState({
            selectedRows: selectedRows,
            allSelected: data.length === selectedRows.length
        });

        this.props.onColleaguesSelected(selectedRows);
    }
}