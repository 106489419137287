import * as React from 'react';
import { Fieldset, Label, Legend, SelectField } from '@jsluna/form';
import { GridWrapper, GridItem, Container } from '@jsluna/grid';
import { Table } from '@jsluna/table';
import { useNavigate } from 'react-router';
import { IBasePageProps } from '../../IBasePageProps';
import { IEvent } from '../../../models/events/IEvent';
import { Notification, NotificationTypes } from "../../Common/Notification";
import { IMyStorePledge } from '../../../models/pledging/IMyStorePledge';
import { ButtonGroupSecondary, FilledButton } from '@jsluna/button';
import { RecordAttendance } from './RecordAttendance';
import { IRecordAttendance } from '../../../models/pledging/IRecordAttendance';
import { IStorePledge } from './IStorePledge';
import { useServices } from '../../../providers/hooks/useServices';
import { useCSVDownloader } from '../../Common/useCSVDownloader';

interface IStorePledgeProps extends IBasePageProps {
    CSVDownloader: any;
}

interface IStorePledgeState {
    events?: IEvent[];
    selectedEvent?:IEvent;
    storepledges?: IStorePledge[];
    loading: boolean,
    displayError: boolean;
    selectedPledge?: IStorePledge;
    showRecordAttendance: boolean;
    attendance?: IRecordAttendance;    
}

export class MyStorePledges extends React.Component<IStorePledgeProps, IStorePledgeState> {
    constructor(props: IStorePledgeProps) {
        super(props);    
        this.state={
            storepledges:[],
            loading: true,
            displayError: false,
            showRecordAttendance: false
        }

        this.getStorepledges = this.getStorepledges.bind(this);
    }

    public async componentDidMount() {
        try {
            this.setState({
                loading: true
            });
            
            const events = await this.props.services.eventManagementService.getEvents();
            this.setState({
                events: events,
                loading: false
            });
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false,
                displayError : true
            });
        }
    }

    public render(): React.ReactNode {
        if(this.state.loading) {
            return <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
        }

        if(this.state.displayError) {
            return <Notification type={NotificationTypes.Error} 
            display={true} 
            displayMessage="Unable to process your request"
            onClose={()=>{
                this.setState({
                  displayError: false
                });
            }}/>
        }

        const CSVDownloader = this.props.CSVDownloader;

        return <>
            {
                (this.state.selectedPledge !== undefined && this.state.attendance !== undefined && this.state.showRecordAttendance) &&
                <RecordAttendance 
                    pledge={this.state.selectedPledge}
                    attendance={this.state.attendance}
                    showExemptModel={this.state.showRecordAttendance}
                    onRecord={this.onRecordAttendance.bind(this)}
                    onCancel={()=>{
                        this.setState({
                            showRecordAttendance: false,
                            selectedPledge: undefined,
                            attendance: undefined
                        });
                    }}/>
            }
            
            <Container free size="xs">
                <Legend fontStyle="h2" className='topHeaderPadding'>My store pledges</Legend>
                <Fieldset>
                    <SelectField 
                        name="Event" 
                        label="Select an event" 
                        options={this.getEventOptions()}
                        onChange={this.getStorepledges} 
                        />
                    <GridWrapper>
                        {
                            this.state.selectedEvent &&
                            <GridItem key="actionbar" size="1/1" element="div">
                                <ButtonGroupSecondary>
                                    <CSVDownloader
                                        filename={"StorePledges"}
                                        bom={true}
                                        data={this.state.storepledges} />
                                </ButtonGroupSecondary>
                            </GridItem>
                        }
                        
                        {(this.state.storepledges === undefined || this.state.storepledges.length === 0) &&
                            <Label htmlFor="norecords" className="emptyMessagebox">There are no pledges available to display</Label>
                        }
                        {(this.state.selectedEvent && this.state.storepledges !== undefined && this.state.storepledges.length > 0) &&
                                <GridItem key="1" size="1/1" element="div">
                                    <Table
                                        rowKey="username"                                    
                                        data={this.state.storepledges}
                                        caption="Captions that have no significance to sighted users, can be toggled off and still be readable by screen readers."
                                        visuallyHiddenCaption
                                        columns={[
                                            {
                                                name: "Colleague",
                                                accessor: (rowData: any) => ({ value: rowData.username }),
                                                hideLabel: true
                                            },                                        
                                            {
                                                name: "Date",
                                                accessor: (rowData: any) => ({ value: rowData.eventDate }),
                                                hideLabel: true
                                            },
                                            {
                                                name: "Shift",
                                                accessor: (rowData: any) => ({ value: rowData.timing }),
                                                hideLabel: true
                                            },
                                            {
                                                name: "Comments",
                                                accessor: (rowData: any) => ({ value: this.trimComments(rowData.comments) }),
                                                hideLabel: true
                                            },
                                            {
                                                name: "Non - Attendance",
                                                accessor: (rowData: any) => ({ value: rowData }),
                                                hideLabel: true,
                                                render: this.renderAttendanceActions.bind(this)
                                            }
                                        ]}>
                                    </Table>
                                </GridItem>
                        }
                    </GridWrapper>
                </Fieldset>
            </Container>
        </>;
    }

    private renderAttendanceActions(value: any) {
        return <FilledButton element="button" variant="filled"
                    onClick={
                        () => {
                            const pledge = value.value;
                            this.setState({
                                selectedPledge: pledge,
                                showRecordAttendance: true,
                                attendance: {
                                    hasAttended: false,
                                    nonAttendanceReason: pledge.nonAttendanceReason,
                                    additionalAttendanceComments: pledge.additionalAttendanceComments
                                }
                            })
                        }
                    }>
            Record
        </FilledButton>;
    }

    private trimComments(str: string) {
        if(str.length > 50) {
            str = str.substring(0, 50);
        }

        return str;
    }
    
    private getEventOptions() {
        let eventOptions: any[] = [];
        if(this.state.events) {
            this.state.events.forEach((e)=>{
                eventOptions.push({
                    label: e.name,
                    value: e.eventId,
                    checked: false
                });
            });
        }

        return eventOptions;
    }
    
    private async getStorepledges(e: any) {
        if(e.target.value !== undefined && e.target.value !== '') {
            const myStorePledges = await this.props.services.eventPledgeService
                .getMyStorePledges(e.target.value);
            
            const storePledges: IStorePledge[] = [];
            myStorePledges.forEach((p: IMyStorePledge)=>{
                storePledges.push({
                    id: p.id,
                    username: p.userDisplayName,
                    eventDate: p.eventDate.toLocaleDateString(),
                    timing: p.shift,
                    comments: p.userPledgeComments,
                    hasAttended: p.hasAttended,
                    nonAttendanceReason: p.nonAttendanceReason,
                    additionalAttendanceComments: p.additionalAttendanceComments
                });
            });

            this.setState({
                storepledges: storePledges,
                selectedEvent: this.state.events?.find(ev=>ev.eventId === e.target.value)
            });
        } else {
            this.setState({
                storepledges: []
            });
        }
    }

    private async onRecordAttendance(pledgeId: number, attendance: IRecordAttendance): Promise<void> {
        if(this.state.selectedEvent !== undefined) {
            this.setState({
                loading: true
            });

            try {
                await this.props.services.eventPledgeService
                    .recordPledgeAttendance(pledgeId, attendance);

                const myStorePledges = await this.props.services.eventPledgeService
                    .getMyStorePledges(this.state.selectedEvent?.eventId);
            
                const storePledges: IStorePledge[] = [];
                myStorePledges.forEach((p: IMyStorePledge)=>{
                    storePledges.push({
                        id: p.id,
                        username: p.userDisplayName,
                        eventDate: p.eventDate.toLocaleDateString(),
                        timing: p.shift,
                        comments: p.userPledgeComments,
                        hasAttended: p.hasAttended,
                        nonAttendanceReason: p.nonAttendanceReason,
                        additionalAttendanceComments: p.additionalAttendanceComments
                    });
                });

                this.setState({
                    loading: false,
                    displayError : false,
                    showRecordAttendance: false,
                    storepledges: storePledges
                });
            } catch(err) {
                console.log(err);
                this.setState({
                    loading: false,
                    displayError : true,
                    showRecordAttendance: false
                });
            }
        }
    }
}

export default function MyStorePledgesPage() {
    const navigate = useNavigate();
    const services = useServices();
    const CSVDownloader = useCSVDownloader();
    return <MyStorePledges navigate={navigate} services={services} CSVDownloader={CSVDownloader}/>;
}