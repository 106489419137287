
export const GetStoreTypesLabel = (storeTypes: number[]) => {
    let storeTypesStr: string = "";
        for(let i=0; i < storeTypes.length; i++) {
            switch(storeTypes[i]) {
                case 1:
                    storeTypesStr += "All Supermarkets";
                    break;
                case 2:
                    storeTypesStr +=  "All SiS";
                    break;
                case 3:
                    storeTypesStr +=  "All SaS";
                    break;
                case 4:
                    storeTypesStr +=  "Specific Stores";
                    break;
                case 6:
                    storeTypesStr +=  "LFC";
                    break;
                case 7:
                    storeTypesStr +=  "Supermarket Collection Points";
                    break;
                case 8:
                    storeTypesStr +=  "Convenience Collection Points";
                    break;
                default:
                    storeTypesStr += "";
            }

            if(i !== storeTypes.length - 1) {
                storeTypesStr += ", ";
            }
        }

        return storeTypesStr;
}
