import * as React from 'react';
import { 
    AsyncAutocompleteField,
    MultiAutocompleteField 
} from '@jsluna/autocomplete';
import { IServiceCollection } from '../../services/IServiceCollection';
import { IDirectReport } from '../../models/exemptions/IDirectReport';

interface IColleagueSearchProps {
    services: IServiceCollection;
    username?: string;
    onColleaguesSelected(info: any): void;
}

export class ColleagueSearch extends React.Component<IColleagueSearchProps, {}> {
    public render(): React.ReactNode {
        return <AsyncAutocompleteField
                className="exemption-field"
                name="aasearchcolleague"
                placeholder="Type keyword to search for a colleague"
                label="Select colleagues to exempt"
                loadOptions={this.searchColleague.bind(this)}
                onSelect={this.onColleagueSelected.bind(this)}
                role="search">
                <MultiAutocompleteField></MultiAutocompleteField>
            </AsyncAutocompleteField>;
    }

    private async searchColleague(value: string) {
        if(this.props.username !== undefined) {
            const username = this.props.username;
            const results = await this.props.services.directReportService.query(username, value);
            if(results) {
                let autoCompleteItems:any[] = [];
                results.forEach((item: IDirectReport)=>{
                    autoCompleteItems.push({
                        label: item.userProfile.displayName,
                        value: item.userProfile.userPrincipalName
                    });
                });

                return autoCompleteItems;
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    private onColleagueSelected(value: any): void {
        if(value) {
            const selColleagues: string[] = [];
            value.forEach((c: any)=>{
                selColleagues.push(c.value);
            });

            this.props.onColleaguesSelected(selColleagues);
        }
    }
}