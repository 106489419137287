export interface ISSCLeaderReport {
    id: string;
    status: ReportStatus;
    report: ILeaderReport;
    directReports?: ILeaderReport[];
    errorMessage?: string;
}

export interface ILeaderReport {
    userPrincipalName: string;
    userDisplayName: string;
    hasMetRequirement: boolean;
    usersMetRequirement: number;
    usersNotMetRequirement: number;
    pledges: IPledge[];
    nonPledgeUsers: IUserProfile[];
    lineManager: string;
}

export interface IPledge {
    userPrincipalName: string;
    userDisplayName: string;
    storeCode: string;
    storeName?: string;
    eventDate: Date;
    shiftTime: string;
    pledgeComments: string;
    metRequirement: boolean;
}

interface IUserProfile {
    userPrincipalName: string;
    displayName: string;
    mail?: string;
    exempt: boolean;
    exemptComments: string;
}

export interface IExemptedProfile {
    userPrincipalName: string;
    displayName: string;
    mail?: string;
    exempted: boolean;
    exemptionReason?: string;
}

export enum ReportStatus {
    Queued,    
    Completed,
    Error
}