import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalConfig } from "./auth/AuthConfig";
import reportWebVitals from './reportWebVitals';
import { ServiceProvider } from './providers/ServiceProvider';
import { Layout } from './components/layouts/Layout';
import { Login } from './pages/Login';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <ServiceProvider>
          <App />
        </ServiceProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Layout>
          <Login />
        </Layout>
      </UnauthenticatedTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
