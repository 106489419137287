import moment from "moment";
import { appenv } from "../../env";
import { IShiftTime } from "../../models/events/IEvent";
import { ICancelPledgeInput } from "../../models/pledging/ICancelPledgeInput";
import { IEventPledgeInput } from "../../models/pledging/IEventPledgeInput";
import { IMyPledge } from "../../models/pledging/IMyPledge";
import { IMyPledgeDatesModel } from "../../models/pledging/IMyPledgeDatesModel";
import { IMyStorePledge } from "../../models/pledging/IMyStorePledge";
import { IRecordAttendance } from "../../models/pledging/IRecordAttendance";
import { IUpdatePledgeInput } from "../../models/pledging/IUpdatePledgeInput";
import { IUserPledge } from "../../models/pledging/IUserPledge";
import { IExemptedProfile } from "../../models/reporting/ILeaderReport";
import { IEventPledgeService } from "./IEventPledgeService";
import { MsalHelper } from "../../auth/MsalHelper";

export const Exceptions = {
    Unauthorised_Exception: "Unauthorised",
    PledgeFull_Exception: "The pledges for event is full"
}

export class EventPledgeService implements IEventPledgeService {
    constructor(private readonly msalHelper: MsalHelper) {
    }
    
    public async recordPledgeAttendance(pledgeId: number, attendance: IRecordAttendance): Promise<void> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/${pledgeId}/attendance`;

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: JSON.stringify(attendance)
            });

            if(response.ok) {
                return;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting pledge for event.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting pledge for event.');
        }
    }

    public async isSSCLeader(): Promise<boolean> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/reports/issscleader`;
        const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
        const response = await fetch(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            method: 'GET'
        });

        if(response.ok) {
            const result = await response.json();
            return result.hasPermission;
        }
        
        const err = await response.text();
        console.log(err);
        throw new Error("Cannot add event.");
    }

    public async removeExemption(eventId: string, upn: string): Promise<void> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/events/${eventId}/exempt/colleague`;
        const body = {
            userPrincipalName: upn
        };

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'DELETE',
                body: JSON.stringify(body)
            });

            if(response.ok) {
                return;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting pledge for event.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting pledge for event.');
        }
    }

    public async addExemption(eventId: string, upn: string, reason: string): Promise<void> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/events/${eventId}/exempt/colleague`;
        const body = {
            userPrincipalName: upn,
            description: reason
        };

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: JSON.stringify(body)
            });

            if(response.ok) {
                return;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting pledge for event.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting pledge for event.');
        }
    }

    public async addTeamExemption(eventId: string, upn: string, reason: string): Promise<void> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/events/${eventId}/exempt/team/colleague`;
        const body = {
            leaderUserPrincipalName: upn,
            description: reason
        };

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: JSON.stringify(body)
            });

            if(response.ok) {
                return;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error exempting team.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error exempting team.');
        }
    }

    public async getExemptions(eventId: string, upn: string): Promise<IExemptedProfile[]> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/exemption/events/${eventId}/${upn}`;

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result: IExemptedProfile[] = await response.json();
                return result;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting pledge for event.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting pledge for event.');
        }
    }

    public async getShiftTimingsForEvent(eventId: string): Promise<IShiftTime[]> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/events/${eventId}/shifts`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {                
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result: IShiftTime[] = await response.json();
                return result;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting pledge for event.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting pledge for event.');
        }
    }

    public async canUserPledge(): Promise<boolean> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/allowed`;

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });
            
            if(response.ok) {
                var result = await response.json();
                return result.allowed;
            }

            if(response.status === 401) {
                throw new Error(Exceptions.Unauthorised_Exception);
            }

            const text: string = await response.text();
            console.log(text);
            throw new Error('Error getting can user pledge.');
        } catch(err) {
            console.log(err);
            throw new Error('Error getting can user pledge.');
        }
    }

    public async getUsersPledgeForEvent(eventId: string): Promise<IMyPledgeDatesModel[]> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/events/${eventId}/my`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {                
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                const userPledges: IMyPledgeDatesModel[] = [];
                result.forEach((res: any)=>{
                    userPledges.push({
                            id:res.id,
                            eventDate:new Date(res.eventDate)
                        });
                });

                return userPledges;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting my pledges');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting my pledges');
        }
    }

    public async getMyStorePledges(eventId: string): Promise<IMyStorePledge[]> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/events/${eventId}/stores/my`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {                
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                const storePledges: IMyStorePledge[] = [];
                result.forEach((p: any)=>{
                    storePledges.push({
                            id: p.id,
                            userDisplayName: p.userDisplayName,
                            eventDate: new Date(p.eventDate),
                            shift: p.shift,
                            userPledgeComments: p.userPledgeComments,
                            hasAttended: p.hasAttended,
                            nonAttendanceReason: p.nonAttendanceReason,
                            additionalAttendanceComments: p.additionalAttendanceComments
                        }
                    );
                });

                return storePledges;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting my store pledges');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting my store pledges');
        }
    }

    public async getMyPledges(): Promise<IMyPledge[]> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/my`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                const userPledges: IMyPledge[] = [];
                result.forEach((p: any)=>{
                    userPledges.push({
                            id: p.id,
                            eventId: p.eventId,
                            eventDate: new Date(p.eventDate),
                            storeCode: p.storeCode,
                            shiftTiming: p.shiftTime
                        }
                    );
                });

                return userPledges;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting my pledges');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting my pledges');
        }
    }

    public async pledgeForEvent(eventId: string, input: IEventPledgeInput): Promise<void> {
        this.attachDateFormat(input.pledgedDate);
        this.attachDateFormat(input.date);
        
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/${eventId}`;
        const body: string = JSON.stringify(input);

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: body
            });
            
            if(!response.ok) {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error pledging for event.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error pledging for event.');
        }
    }

    public async getPledgeForEvent(pledgeId: number): Promise<IUserPledge> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/${pledgeId}`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {                
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                const userPledge: IUserPledge = {
                    id:result.id,
                    store: result.store,
                    date:new Date(result.date),
                    pledgedDate: new Date(result.pledgedDate),
                    division: result.division,
                    status: result.status,
                    pledgingComments:result.pledgingComments,
                    shiftTime: result.shiftTime
                };

                return userPledge;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting pledge for event.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting pledge for event.');
        }
    }

    public async updatePledgeForEvent(pledgeId: number, input: IUpdatePledgeInput): Promise<IUserPledge> {
        this.attachDateFormat(input.eventDate);
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/${pledgeId}`;
        const body: string = JSON.stringify(input);

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'PATCH',
                body: body
            });
            
            if(response.ok) {
                const result = await response.json();
                const userPledge: IUserPledge = {
                    id:result.id,
                    store: result.store,
                    date:new Date(result.date),
                    pledgedDate: new Date(result.pledgedDate),
                    division: result.division,
                    status: result.status,
                    pledgingComments:result.pledgingComments,
                    shiftTime: result.shiftTime
                };

                return userPledge;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                if(response.status === 409) {
                    throw new Error(Exceptions.PledgeFull_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error pledging for event.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error pledging for event.');
        }
    }

    public async cancelPledgeForEvent(pledgeId:number, input:ICancelPledgeInput): Promise<void> {
        this.attachDateFormat(input.withdrawnDate);
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/pledging/${pledgeId}`;
        const body: string = JSON.stringify(input);

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'DELETE',
                body: body
            });
            
            if(!response.ok) {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error pledging for event.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error pledging for event.');
        }
    }

    private attachDateFormat(d: Date) {
        d.toJSON = function(){
            var date = Date.UTC(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours(), this.getMinutes())
            return moment(date).format(); 
        }
    }
}