import * as React from 'react';
import { Modal, ModalHeading } from '@jsluna/modal';
import { SelectField, TextAreaField, Label  } from '@jsluna/form';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, OutlinedButton } from '@jsluna/button';
import { IRecordAttendance } from '../../../models/pledging/IRecordAttendance';
import { IStorePledge } from './IStorePledge';

interface IRecordAttendanceProps {
    pledge: IStorePledge;
    attendance: IRecordAttendance;
    showExemptModel: boolean;
    onRecord(pledgeId:number, data: IRecordAttendance): void;
    onCancel(): void;
}

interface IRecordAttendanceState {    
    nonAttendanceReason?: string;
    additionalComments?: string;
    attendance: IRecordAttendance;
}

export class RecordAttendance extends React.Component<IRecordAttendanceProps, IRecordAttendanceState> {

    constructor(props: IRecordAttendanceProps) {
        super(props);

        this.state = {            
            attendance: this.props.attendance
        };
    }

    public render(): React.ReactNode {
        const label = `${this.props.pledge.username} did not attend a pledged shift on ${this.props.pledge.eventDate}.`;
        return <Modal
                fullScreen
                restrictClose
                alert
                open={ this.props.showExemptModel }
                headingId="dialog-modal">
                    <ModalHeading element="h3">
                        Record Non-Attendance
                    </ModalHeading>
                    <Label htmlFor='attendanceoptions'>{label}</Label>
                    <SelectField name="nonAttendanceReason" label="Select a reason"
                        options={this.getNonAttendanceReasons()}
                        onChange={this.onNonAttendanceReasonSelected.bind(this)}
                        defaultValue={this.state.attendance.nonAttendanceReason}
                        error={this.state.nonAttendanceReason === '' ? "Select a reason": ""}/>
                    <TextAreaField name="add-comments" label="Additional Comments. Please do not enter any personal sensitive medical information."
                        value={this.state.attendance.additionalAttendanceComments}
                        onChange={(e: any)=> this.setState({
                            attendance: {
                                ...this.state.attendance,
                                additionalAttendanceComments: e?.target.value
                            }
                        })}/>
                    <ButtonGroupWrapper actionbar>
                        <ButtonGroupSecondary>
                            <></>
                        </ButtonGroupSecondary>
                        <ButtonGroupPrimary>
                            <OutlinedButton
                                className="ln-u-margin-right"
                                onClick={() => this.props.onCancel()}>
                                Cancel
                            </OutlinedButton>
                            <FilledButton onClick={this.onRecordAttendance.bind(this)}>Record</FilledButton>
                        </ButtonGroupPrimary>
                    </ButtonGroupWrapper>
            </Modal>;
    }

    private getNonAttendanceReasons() {
        let options = [
            { label: "Colleague did not turn up", value: 'Colleague did not turn up' },
            { label: "Colleague cancelled at the last minute", value: 'Colleague cancelled at the last minute' },
            { label: "Colleague left early", value: 'Colleague left early' },
            { label: "Other", value: 'Other' }
        ];

        return options;
    }

    private onNonAttendanceReasonSelected(e: any) {
        this.setState({
            attendance: {
                ...this.state.attendance,
                nonAttendanceReason: e.target.value
            }
        });
    }

    private onRecordAttendance() {
        this.props.onRecord(this.props.pledge.id, this.state.attendance);
    }
}