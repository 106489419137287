import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { GridWrapper, GridItem, Container } from '@jsluna/grid'
import {Fieldset, Label, Legend} from '@jsluna/form';
import { Body1 } from '@jsluna/typography'
import { FilledButton } from '@jsluna/button';
import { IBasePageProps } from '../IBasePageProps';
import { PledgingRoutes } from '../../Routes';
import { IStore } from '../../models/storeevents/IStore';
import { Notification, NotificationTypes } from "../Common/Notification";
import { useServices } from '../../providers/hooks/useServices';

interface IMyPledgeProps extends IBasePageProps {
}

interface IPledge {
    id: number;
    eventId: string;
    eventDate: Date;
    shiftTiming: string;
    store: IStore;
}

interface IMyPledgeState {
    pledges?: IPledge[];
    loading: boolean;
    displayError: boolean;
}

class MyPledges extends React.Component<IMyPledgeProps, IMyPledgeState> {
    constructor(props: IMyPledgeProps) {
        super(props);    

        this.state = {
            loading: true,
            displayError: false
        };
    }

    public render(): React.ReactNode {
        const date = new Date();
        if(this.state.loading) {
            return <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
        }

        if(this.state.displayError) {
            return <Notification type={NotificationTypes.Error} 
            display={true} 
            displayMessage= "Unable to process your request"
            onClose={()=>{
                this.setState({
                  displayError: false
                });
            }}/>
        }
      
        return <>
            <Container free size="xs">  
                <Legend fontStyle="h2" className='topHeaderPadding'>My pledges</Legend>
                <Fieldset>
                    {
                        (this.state.pledges === undefined || this.state.pledges.length === 0) &&
                        <Label htmlFor="norecords" className="emptyMessagebox">There are no pledges available to display</Label>
                    }
                {
                    (this.state.pledges !== undefined && this.state.pledges !== null) &&
                    this.state.pledges.map((p: IPledge) => {
                        return <GridWrapper>
                            <GridItem size="1/1" element="li">
                                <GridItem size="10/12" element="li">
                                    <div>
                                        <Label htmlFor="storeTitle">{ this.getFormattedStore(p.store) }</Label>
                                        <Body1 element="p">{ p.eventDate.toDateString() }</Body1>
                                        <Body1 element="p">{ p.shiftTiming }</Body1>
                                    </div>
                                </GridItem>
                                <GridItem size="2/12" element="li">
                                    {
                                        date.getTime() < p.eventDate.getTime() && 
                                        <FilledButton 
                                            className='btnPadding5'
                                            onClick={()=>this.props.navigate(`${PledgingRoutes.EditPledge}/${p.eventId}/${p.store.storeCode}/${p.id}`)}>
                                            Edit
                                        </FilledButton>
                                    }
                                </GridItem>
                            </GridItem>
                        </GridWrapper>
                    })
                }
                </Fieldset>
            </Container>
        </>;
    }

    public async componentDidMount() {
        try {
            this.setState({
                loading: true,
                displayError : false
            });
            
            const pledges = await this.props.services.eventPledgeService
                .getMyPledges();
            const storeCodes: string[] = [];
            pledges.forEach((s:any)=> {
                if(storeCodes.indexOf(s.storeCode) === -1) {
                    storeCodes.push(s.storeCode);
                }
            });

            const stores = await this.props.services.storeEventService
                .getStoresByCodes(storeCodes);
            const formatedPledges: IPledge[] = [];

            stores.forEach((s)=>{
                const items = pledges.filter((x:any)=>x.storeCode === s.storeCode);
                items.forEach((p: any)=>{
                    formatedPledges.push({
                        id: p.id,
                        eventId: p.eventId,
                        eventDate: p.eventDate,
                        shiftTiming: p.shiftTiming,
                        store: s
                    });
                });
            });

            formatedPledges.sort((a: IPledge, b: IPledge) => Number(b.eventDate) - Number(a.eventDate));

            this.setState({
                pledges: formatedPledges,
                loading: false,
                displayError : false
            });
        } catch(err) {
            console.log(err);
            this.setState({
                loading: false,
                displayError : true
            });
        }
    }

    private getFormattedStore(store: IStore) {
        return `${store.storeName}, ${store.storeAddress1}${store.storeAddress2?`, ${store.storeAddress2}`: ''}, ${store.postTown}, ${store.postCode}`;
    }
}

export default function MyPledgesPage() {
    const navigate = useNavigate();    
    const services = useServices();
    return <MyPledges navigate={navigate} services={services}/>;
}