import { StatusCard } from '@jsluna/card';
import { NotificationList, NotificationItem } from '@jsluna/notification';
import { useNavigate } from 'react-router';
import { PledgingRoutes } from '../../Routes';

export default function UnauthorisedPage () {
    const navigate = useNavigate();
    return <NotificationList open={true} style={{ position: 'absolute', top:'30%', left:'40%' }}>
        <NotificationItem
                    notification
                    open={true}
                    handleClose={() => navigate(PledgingRoutes.SearchPledge)}>
                    <StatusCard status="danger">       
                        You do not have permission to access this page
                    </StatusCard>
                </NotificationItem>
    </NotificationList>
}