import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from "react";
import { MsalHelper } from "../auth/MsalHelper";

export function Login() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const msalHelper = new MsalHelper(instance);

    useEffect(() => {
        async function login() {
            await msalHelper.handleLogin();
        }

        if(!isAuthenticated) {
            login();
        }
    });

    return <></>;
}