import * as React from 'react';
import { Table } from '@jsluna/table';
import { Modal, ModalHeading } from '@jsluna/modal';
import {
    FilledButton,
    OutlinedButton,
    ButtonGroupWrapper,
    ButtonGroupPrimary
  } from '@jsluna/button';
import { TextAreaField, SelectField } from '@jsluna/form';

import { IExemptedProfile } from '../../../models/reporting/ILeaderReport';

interface IPledgeExemptionsProps {
    profiles: IExemptedProfile[];
    onExempt(exempt: boolean, upn: string, reason?: string): void;
}

interface IPledgeExemptionsState {
    showExemptModel: boolean;
    showRemoveConfirmation: boolean;
    showRemoveConfirmationModel: boolean;
    selectedColleague?: string;
    reasonForExempt: string;
    otherReason: string;
}

const exemptOptions = [
    { label: "Requirements of role", value: 'Requirements of role' },
    { label: "Holiday", value: 'Holiday' },    
    { label: "3rd Party Contractor", value: '3rd Party Contractor' },
    { label: "Other / Medical", value: 'Other / Medical' }
];

export class PledgeExemptions extends React.Component<IPledgeExemptionsProps, IPledgeExemptionsState> {
    private readonly columns = [
        {
            name: "Name",
            accessor: (rowData: IExemptedProfile) => ({ value: rowData.displayName }),
            hideLabel: true,
        },
        {
            name: "Email",
            accessor: (rowData: IExemptedProfile) => ({ value: rowData.mail }),
            hideLabel: true,
        },
        {
            name: "Exempted",
            accessor: (rowData: IExemptedProfile) => ({ value: this.getExemptionLabel(rowData.exempted) }),
            hideLabel: true,
        },
        {
            name: "Actions",
            accessor: (rowData: IExemptedProfile) => ({ 
                value: rowData.exempted,
                upn: rowData.userPrincipalName
            }),
            hideLabel: true,
            render: this.renderActions.bind(this)
        }
    ];

    constructor(props: IPledgeExemptionsProps) {
        super(props);

        this.state = {
            showExemptModel: false,
            showRemoveConfirmation: false,
            showRemoveConfirmationModel: false,
            reasonForExempt: '',
            otherReason: ''
        };
    }

    public render(): React.ReactNode {
        return <>
            {
                this.state.showExemptModel && 
                    this.renderExemptModel()
            }
            {
                this.state.showRemoveConfirmation && 
                    this.renderRemoveConfirmationModel()
            }
            <Table
                rowKey="userPrincipalName"
                data={this.props.profiles}
                caption="Colleague Exemptions"
                visuallyHiddenCaption
                columns={this.columns}>
            </Table>
        </>;
    }

    private renderExemptModel() {
        return <Modal
                fullScreen
                restrictClose
                alert
                open={this.state.showExemptModel}
                headingId="dialog-modal">
                    <ModalHeading element="h3">
                        Exempt Colleague
                    </ModalHeading>
                    <SelectField name="exemptReason" label="Select reason for exempt" 
                        options={exemptOptions} 
                        onChange={this.onExemptReasonSelected.bind(this)}
                        defaultValue={this.state.reasonForExempt}
                        error={this.state.reasonForExempt === '' ? "Select a reason for exempt": ""}/>
                    
                    <TextAreaField name="exempt-reason" label="Reason for exempt. Please do not enter any personal sensitive medical information."
                        value={this.state.otherReason}
                        onChange={(e: any)=> this.setState({
                            otherReason: e?.target.value
                        })}
                        error={this.state.reasonForExempt === 'Other' && this.state.otherReason === '' ? "Enter a reason": ""}/>
                    <ButtonGroupWrapper actionbar>
                        <ButtonGroupPrimary>
                            <OutlinedButton
                                className="ln-u-margin-right"
                                onClick={() => this.setState({
                                    showExemptModel: false,
                                    selectedColleague: undefined
                                })}>
                                Cancel
                            </OutlinedButton>
                            <FilledButton onClick={this.onExempt.bind(this)}>Exempt</FilledButton>
                        </ButtonGroupPrimary>
                    </ButtonGroupWrapper>
            </Modal>;
    }

    private renderRemoveConfirmationModel() {
        return <Modal
                fullScreen
                restrictClose
                alert
                open={this.state.showRemoveConfirmation}
                headingId="dialog-modal">
                    <ModalHeading element="h3">
                        Remove Exemption
                    </ModalHeading>
                    <p>
                        Are you sure want to remove the exemption?
                    </p>
                    <ButtonGroupWrapper actionbar>
                        <ButtonGroupPrimary>
                            <OutlinedButton
                                className="ln-u-margin-right"
                                onClick={() => this.setState({
                                    showExemptModel: false,
                                    selectedColleague: undefined
                                })}>
                                Cancel
                            </OutlinedButton>
                            <FilledButton onClick={this.onRemoveExempt.bind(this)}>Remove</FilledButton>
                        </ButtonGroupPrimary>
                    </ButtonGroupWrapper>
            </Modal>;
    }

    private getExemptionLabel(value: boolean) {
        return value ? "Yes" : "No";
    }

    private renderActions(value: any) {
        return <FilledButton element="button" variant="filled"
                    onClick={
                        () =>{
                            this.onExemptClick(!value.value, value.upn);
                        }
                    }>
            {value.value ? "Remove": "Add"}
        </FilledButton>;
    }

    private onExempt() {
        this.setState({
            showExemptModel: false
        });

        if(this.state.selectedColleague !== undefined
            && this.state.reasonForExempt !== undefined)  {
            let exemptReason = '';
            if(this.state.reasonForExempt === 'Other'
                && this.state.otherReason === '') {
                    return;
            } else if(this.state.reasonForExempt === 'Other') {
                exemptReason = this.state.otherReason;
            } else {
                exemptReason = this.state.reasonForExempt;
            }

            this.props.onExempt(
                true,
                this.state.selectedColleague,
                exemptReason);
        }
    }

    private onRemoveExempt() {
        this.setState({
            showRemoveConfirmation: false
        });

        if(this.state.selectedColleague !== undefined) {
            this.props.onExempt(
                false,
                this.state.selectedColleague);
        }
    }

    private onExemptClick(exempt: boolean, upn: string) {
        if(exempt) {
            this.setState({
                showExemptModel: true,
                selectedColleague: upn
            });
        } else {
            this.setState({
                showRemoveConfirmation: true,
                selectedColleague: upn
            });
        }
    }

    private onExemptReasonSelected(value: any) {
        this.setState({
            reasonForExempt: value.target.value
        });
    }
}