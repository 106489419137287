import { Checkbox, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import React from "react";

export interface IShiftTimeItem {
    id: number;
    value? : string;
    isFullDay: boolean;
}

export interface IShiftTimingsProps {
    shiftTimings: IShiftTimeItem[];
    onShiftChange(id:number, value: string): void;
    onFullShiftChange(id: number, value: boolean): void;
    error?: string;
}

export class ShiftTimings extends React.Component<IShiftTimingsProps, {}> {
    public render(): React.ReactNode {
        return <div className={`ln-c-form-group${this.props.error ? " has-error":""}`}>
            {
                this.props.shiftTimings.map((s)=> {
                    return <GridWrapper>
                            <GridItem size="2/3">
                                <TextInputField
                                    name={`shiftTime${s.id}`}
                                    label={`Shift Time ${s.id}`}
                                    onChange={(e)=>{this.props.onShiftChange(s.id, e.target.value)}}
                                    required />
                            </GridItem>
                            <GridItem size="1/3">
                                <Checkbox
                                    name={`checkbox-${s.id}`}
                                    label="Full day shift"
                                    onChange={(e: any)=> {
                                        this.props.onFullShiftChange(s.id, e.target.checked);
                                    }}
                                    defaultChecked={s.isFullDay} />
                            </GridItem>
                    </GridWrapper>
                })
            }
            {
                this.props.error &&
                <div className="ln-c-field-info ln-c-field-info--error" role="alert" aria-live="assertive">
                    {this.props.error}
                </div>
            }
        </div>;
    }
}
