import { NotificationList, NotificationItem } from '@jsluna/notification';
import {
  ProgressIndicator,
  ProgressSpinner,
  ProgressMessage,
} from '@jsluna/progress'
import { StatusCard } from '@jsluna/card'
import React from 'react';

export enum NotificationTypes {
    Error,
    Warning,
    Progress,
    Success
}

interface INotificationProps{
    displayMessage?: string;
    type: NotificationTypes;
    display: boolean;
    onClose(): void;
}

export class Notification extends React.Component<INotificationProps, {}> {

public render(): React.ReactNode {
    return <>    
        <NotificationList open={true} style={{ margin: 'auto', width:'50%', position:'relative', padding:'10px', marginTop:'60px' }}>
            {(this.props.type === NotificationTypes.Error || this.props.type === NotificationTypes.Warning) &&
                <NotificationItem
                    notification
                    className='notificationItem'
                    open={this.props.display}
                    handleClose={() => this.props.onClose()}>
                    <StatusCard status={`${this.props.type === NotificationTypes.Error ? "danger": "warning"}`}>
                        {this.props.displayMessage}
                    </StatusCard>
                </NotificationItem>
            }
            {(this.props.type === NotificationTypes.Progress && this.props.display) &&
                <NotificationItem>
                    <ProgressIndicator loading preventFocus>
                        <ProgressSpinner />
                        <ProgressMessage className="ln-u-margin-left">
                            {this.props.displayMessage !== undefined? this.props.displayMessage: "Loading..."}
                        </ProgressMessage>
                    </ProgressIndicator>
                </NotificationItem>
            }
            {
                this.props.type === NotificationTypes.Success &&
                <NotificationItem
                    notification
                    open={this.props.display}
                    handleClose={() => this.props.onClose()}>
                    <StatusCard status="success">
                        {this.props.displayMessage}
                    </StatusCard>
                </NotificationItem>
            }
        </NotificationList>
    </>
    }
}