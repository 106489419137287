
import React from 'react';
import { Body1, Heading3} from '@jsluna/typography'
import { FilledButton, ButtonGroupWrapper, ButtonGroupPrimary, ButtonGroupSecondary } from '@jsluna/button';
import { Container } from "@jsluna/grid";
import './Pledgestyles.scss'
import { NavigateFunction, useNavigate } from 'react-router';
import { MsalHelper } from '../../auth/MsalHelper';
import { PledgingRoutes } from '../../Routes';
import { useMsal } from '@azure/msal-react';
import { appenv } from '../../env';
import { PageModes } from '../PageModes';

interface IPledgeSubmittedProps {
    navigate: NavigateFunction;
    msalHelper: MsalHelper;
    mode: PageModes;   
}

export class PledgeSubmitted extends React.Component<IPledgeSubmittedProps, {}> {

    public render(): React.ReactNode {

        let message: JSX.Element = <></>;
        switch(this.props.mode) {
            case PageModes.New:
                message = <>Your pledge request has been received but <span className='textUnderscore'>not</span> confirmed.</>;
                break;
            case PageModes.Edit:
                message = <>Your pledge request has been updated successfully.</>;
                break;
            case PageModes.Cancel:
                message = <>Your pledge request has been cancelled successfully.</>;
                break;
        }

        return <>
            <Container free size="xs" className="dvPledgeSuccessWarpper">
                <Heading3 element="div" className='bottompaddingSuccessmsg'>
                    {message}                    
                </Heading3>
                {
                    this.props.mode === PageModes.New &&
                    <>
                        <Body1 element="p">
                            If successful, you'll receive a confirmation email within 30 minutes and your pledge will be visible in the ‘My Pledges’ tab.
                        </Body1>
                        <Body1 element="p">
                            If you <b>do not</b> receive a confirmation email or your pledge isn't visible in the ‘My Pledges’ tab, this means that the limit for the location has been reached and you'll need to pledge for a different location or date.
                        </Body1>
                    </>
                }
                <ButtonGroupWrapper>
                    <ButtonGroupSecondary className='btnLeftOnPopup'>
                        <FilledButton
                            onClick={()=>window.location.href=`${appenv.OURSAINSBURYS_URL}`}>
                            Return to Our Sainsbury's
                        </FilledButton>
                    </ButtonGroupSecondary>
                    <ButtonGroupPrimary className='btnRightOnPopup'>
                        <button type="button" 
                            className="ln-c-button ln-c-button--outlined"
                            onClick={()=>this.props.navigate(`${PledgingRoutes.SearchPledge}`)}>
                            Make another pledge
                        </button>
                    </ButtonGroupPrimary>
                </ButtonGroupWrapper>
            </Container>
        </>;
    }
}

export default function PledgeSubmittedPage() {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const msalHelper = new MsalHelper(instance);
    return <PledgeSubmitted navigate={navigate} msalHelper={msalHelper} mode={PageModes.New} />;
}

export function PledgeUpdatedMessagePage() {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const msalHelper = new MsalHelper(instance);
    return <PledgeSubmitted navigate={navigate} msalHelper={msalHelper} mode={PageModes.Edit} />;
}

export function PledgeCancelledMessagePage() {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const msalHelper = new MsalHelper(instance);
    return <PledgeSubmitted navigate={navigate} msalHelper={msalHelper} mode={PageModes.Cancel} />;
}