import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';
import { appenv } from "../../../env";
import { IStoreSearchResult } from "../../../models/storeevents/IStoreSearchResult";
import { PledgingRoutes } from "../../../Routes";

interface IMapViewProps {
    eventId: string;
    stores: IStoreSearchResult[];
    onPledge(store: IStoreSearchResult): void;
}

const defaultCoordinates: LatLngExpression = [51.50940214, -0.133012];

export default function MapView(props: IMapViewProps) {
    let centerCoordinates: LatLngExpression = defaultCoordinates;
    if(props.stores.length > 0) {
        const firstStore = props.stores[0];
        centerCoordinates = [firstStore.lat, firstStore.long];
    }

    let zoomLevel: number = 10;
    if(props.stores.length > 0) {
        const lastStore = props.stores[props.stores.length - 1];
        if(lastStore.distanceInMiles > 30) {
            zoomLevel = 9;
        } else if(lastStore.distanceInMiles < 10) {
            zoomLevel = 11;
        }
    }

    let storeCount = 0;
    return <div style={{position: "relative"}}>
        <MapContainer center={centerCoordinates} zoom={zoomLevel} className="mapContainer">
                    <TileLayer
                        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> | Created with <a href="http://traveltimeplatform.com" target="_blank">TravelTime API</a>'
                        url={`${appenv.TRAVELTIME_TILES_URL}/lux/{z}/{x}/{y}.png?key=${appenv.TRAVELTIME_APP_ID}`}
                        tileSize={512}
                        maxZoom={18}
                        zoomOffset={-1} />
                    {
                        props.stores.map((store) => {
                            storeCount++;
                            return <Marker position={[store.lat, store.long]} icon={getMapIcon(store.storeCode, storeCount)} title={storeCount.toString()}>
                                <Popup>
                                    { getMarkerInfoHtml(props.eventId, store) }
                                </Popup>
                            </Marker>;
                        })
                    }
                </MapContainer>
            </div>;
}

const getMapIcon = (storeCode: string, num: number) => {
    const numHtml = `<div class='marker-text'>${num}</div>`;
    const isArgosStore = storeCode.trim().toLowerCase().startsWith("a");
    const className = `${isArgosStore ? "marker-argos-div-icon": "marker-sains-div-icon"} marker-common`;

    return L.divIcon({
        className: className,
        html: numHtml
    });
}

const getMarkerInfoHtml = (eventId: string, store: IStoreSearchResult) => {
    return <div className="mapInfoContent">
        <h3>
            { store.storeName }
        </h3>
        <p>
            { <>{store.storeAddress1}<br/></> }
            {
                store.storeAddress2 !== undefined &&                
                <>{store.storeAddress2}<br/></>
            }
            { <>{store.postTown}<br/></> }
            { store.postCode }
        </p>
        <a href={`${PledgingRoutes.EventPledge}/${eventId}/${store.storeCode}`}
            className="ln-c-link ln-c-link--bare ln-c-link--cta ln-c-link--alpha">Pledge here</a>
    </div>
}