import { MsalHelper } from "../../auth/MsalHelper";
import { appenv } from "../../env";
import { IExemption } from "../../models/exemptions/IExemption";
import { IExemptionsService } from "./IExemptionsService";

export class ExemptionsService implements IExemptionsService {
    constructor(private readonly msalHelper: MsalHelper) {
    }
    
    public async getExemptionsForEvent(eventId: string, manager: string): Promise<IExemption[]> {
        const errorMessage = "Error getting exemptions users";
        const requestUrl: string = `${appenv.API_EXEMPTION_BASEURL}/exemption/events/${eventId}/${manager}`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                return result as IExemption[];
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }

    public async exemptColleagues(eventId: string, users: string[], description?: string | undefined): Promise<void> {
        const errorMessage = "Error exempting users";
        const requestUrl: string = `${appenv.API_EXEMPTION_BASEURL}/exemption/events/${eventId}/directreports`;
        const body = {
            users: users,
            description: description
        };

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: JSON.stringify(body)
            });

            if(response.ok) {
                return;
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }

    public async exemptTeam(eventId: string, username: string, managers: string[], description?: string | undefined): Promise<void> {
        const errorMessage = "Error exempting teams";
        const requestUrl: string = `${appenv.API_EXEMPTION_BASEURL}/exemption/events/${eventId}/${username}/directreports`;
        const body = {
            users: managers,
            description: description
        };

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: JSON.stringify(body)
            });

            if(response.ok) {
                return;
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }

    public async removeColleaguesExemptions(eventId: string, users: string[]): Promise<void> {
        const errorMessage = "Error exempting users";
        const requestUrl: string = `${appenv.API_EXEMPTION_BASEURL}/exemption/events/${eventId}/directreports/remove`;
        const body = {
            users: users
        };

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: JSON.stringify(body)
            });

            if(response.ok) {
                return;
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }

    public async removeTeamsExemptions(eventId: string, username: string, managers: string[]): Promise<void> {
        const errorMessage = "Error exempting teams";
        const requestUrl: string = `${appenv.API_EXEMPTION_BASEURL}/exemption/events/${eventId}/${username}/directreports/remove`;
        const body = {
            users: managers
        };

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST',
                body: JSON.stringify(body)
            });

            if(response.ok) {
                return;
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }
}