import { AuthenticationResult, IPublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { loginRequest } from "../auth/AuthConfig";

/**
 * Msal helper containing functions required for authentication and access tokens
 */
export class MsalHelper {
    private readonly instance: IPublicClientApplication;
    constructor(instance: IPublicClientApplication) {
        this.instance = instance;
    }

    public async handleLogin(): Promise<void> {
        try {
            await this.instance.loginRedirect(loginRequest);
        } catch(err) {
            console.log(err);
        }
    }

    public getAccount() {
        const accounts = this.instance.getAllAccounts();
        if(accounts.length > 0) {
            return accounts[0];
        }

        return undefined;
    }

    public async getAccessToken(scopes: string[]): Promise<string> {
        const request: SilentRequest = {
            scopes: scopes,
            account: this.instance.getAllAccounts()[0]
        };

        try {
            const response: AuthenticationResult = await this.instance.acquireTokenSilent(request);
            return response.accessToken;
        } catch(err) {
            console.log(err);
            throw new Error("Error in getting access token");
        }
    }
}