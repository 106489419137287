import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GridWrapper, GridItem, Container } from '@jsluna/grid';
import { Fieldset, Label, Legend, RadioButtonField, TextAreaField, TextInputField } from '@jsluna/form';
import { Table } from '@jsluna/table';
import { ButtonGroupPrimary, FilledButton } from '@jsluna/button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IBasePageProps } from '../IBasePageProps';
import { IUpdateStoreEvent } from '../../models/events/IUpdateStoreEvent';
import { IStoreEvent } from '../../models/events/IStoreEvent';
import { Notification, NotificationTypes } from "../Common/Notification";
import { AuthorisationRoutes } from '../../Routes';
import { useServices } from '../../providers/hooks/useServices';

interface IStoreSettingProps extends IBasePageProps {
    eventID: number;    
}

interface IError {
    [key:string]: string;
}

interface IStore {
    storeName?: string;
    storeCode?: string;
    eventDate?: Date;
    maxPledges?: number;
    priority?: string;
}

enum StoreSettingOptions {
    Add,
    Remove
}

interface IStoreSettingState {
    eventName?: string;
    eventStartDate?: Date;
    eventEndDate?: Date;
    storeSettingOption: StoreSettingOptions;
    storesetting?: IStore;
    storesToRemove?: string[];
    avalableStoresettings?: IStoreEvent[];
    errors: IError;
    loading: boolean,
    displayError: boolean;
    showSuccess: boolean;
}

const columns = [
    {
        name: "Store",
        accessor: (rowData: any) => ({ value: rowData.storeCode }),
        hideLabel: true
    },
    {
        name: "Date",
        accessor: (rowData: any) => ({ value:  rowData.eventDate }),
        hideLabel: true
    },
    {
        name: "Max pledges",
        accessor: (rowData: any) => ({ value: rowData.maxPledges }),
        hideLabel: true
    },
    {
        name: "Priority",
        accessor: (rowData: any) => ({ value: rowData.priority }),
        hideLabel: true
    }
];

export class StoreSettings extends React.Component<IStoreSettingProps, IStoreSettingState> {
    constructor(props: IStoreSettingProps){
        super(props);

        this.state={
            storeSettingOption: StoreSettingOptions.Add,
            storesetting:{
            },
            errors:{
            },
            loading: true,
            displayError: false,
            showSuccess: false
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onNumberChange = this.onNumberChange.bind(this);
        this.updateStoreSettings = this.updateStoreSettings.bind(this);
    }

    public render(): React.ReactNode {
        if(this.state.loading) {
            return <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
        }

        if(this.state.displayError) {
            return <Notification type={NotificationTypes.Error} 
            display={true} 
            displayMessage= "Unable to process your request"
            onClose={()=>{
                this.setState({
                  displayError: false
                });
            }}/>
        }

        return<>        
            <Container free size="m">
                {
                    this.state.showSuccess &&
                    <Notification type={NotificationTypes.Success} 
                        display={this.state.showSuccess} 
                        displayMessage= "The update has been completed successfully."
                        onClose={()=>{
                            this.setState({
                                showSuccess: false
                            });
                    }}/>
                }
                <Legend fontStyle="h2" className='topHeaderPadding'>Store settings</Legend>
                <Fieldset>
                    <GridWrapper>
                        <GridItem size="1/1" element="li">
                            <h3>{this.state.eventName}</h3>
                        </GridItem>
                        <GridItem size="1/1" element="li">
                            <Label htmlFor="eventDaterange">Event date range</Label>
                        </GridItem>
                        <GridItem size="1/1" element="li">
                            {this.state.eventStartDate?.toLocaleDateString()} to {this.state.eventEndDate?.toLocaleDateString()}
                        </GridItem>
                    </GridWrapper>
                </Fieldset>
                <Fieldset>
                    <RadioButtonField
                        label="Please choose an option below"
                        name="addorremove"
                        listType="inline"
                        onClick={this.onOptionSelect.bind(this)}
                        options={[
                            { value: 'add', label: 'Add Stores' , defaultChecked: this.state.storeSettingOption === StoreSettingOptions.Add ? true : false},
                            { value: 'remove', label: 'Remove Stores', defaultChecked: this.state.storeSettingOption === StoreSettingOptions.Remove ? true : false },
                        ]} />
                
                {
                    this.state.storeSettingOption === StoreSettingOptions.Add &&
                    <>
                        <div className={`ln-c-form-group${this.state.errors["eventDate"] !== undefined ? " has-error":""}`}>
                            <label className="ln-c-label">Day</label>
                            <div className="ln-c-field-info ln-c-field-info--extra">The date when the event will start</div>
                            <div className='ln-c-input-group'>
                                <DatePicker 
                                    name="eventDate"
                                    onChange={(date:Date)=>this.onDateChange("eventDate", date)} 
                                    selected={this.state.storesetting?.eventDate}
                                    minDate={this.state.eventStartDate}
                                    maxDate={this.state.eventEndDate} 
                                    dateFormat='dd/MM/yyyy'/>
                            </div>
                            {
                                this.state.errors["eventDate"] !== undefined &&
                                <div id="eventDateValidation" className="ln-c-field-info ln-c-field-info--error" role="alert" aria-live="assertive">
                                    { this.state.errors["eventDate"] }
                                </div>
                            }
                        </div>
                        
                        <TextInputField
                            name="maxPledges"
                            label="Maximum pledges"
                            maxLength={3}
                            max={100}
                            min={1}                
                            error={this.state.errors["maxPledges"]}
                            info="Add number of maximum pledges per store"
                            onChange={this.onNumberChange.bind(this)}
                            type="number"
                            value={this.state.storesetting?.maxPledges !== undefined ? this.state.storesetting?.maxPledges.toString() : ""}
                            required
                        />

                        <RadioButtonField 
                            label="Priority"
                            name="priority"
                            listType="inline"
                            options={[
                                { value: "1", label: 'High priority', defaultChecked: true },
                                { value: "2", label: 'Low priority'},
                            ]}        
                            onClick={this.radiobuttonChnage.bind(this)}
                        />
                        <TextAreaField name="storeCode" 
                            info="Add individual stores codes, seperated by comma"
                            label="Stores" 
                            maxLength={1000}
                            value={this.state.storesetting?.storeCode} 
                            onChange={this.onTextChange.bind(this)}
                            required
                            error={this.state.errors["storeCode"]}
                        />
                        <ButtonGroupPrimary>
                            <FilledButton  onClick={this.updateStoreSettings.bind(this)}>
                                Add Stores
                            </FilledButton>
                        </ButtonGroupPrimary>
                    </>
                }
                {
                    this.state.storeSettingOption === StoreSettingOptions.Remove && 
                    <>
                        <TextAreaField name="storeCode" 
                            info="Add individual stores codes, seperated by comma"
                            label="Stores"
                            maxLength={1000}
                            value={this.state.storesetting?.storeCode} 
                            onChange={this.onTextChange.bind(this)}
                            required
                            error={this.state.errors["storeCode"]}
                        />
                        <ButtonGroupPrimary>
                            <FilledButton  onClick={this.removeStores.bind(this)}>
                                Remove Stores
                            </FilledButton>
                        </ButtonGroupPrimary>
                    </>
                }
                </Fieldset>
                <Fieldset>
                    <Label htmlFor="existingsettings">Existing store settings</Label>
                    {
                        this.state.avalableStoresettings &&
                            <GridWrapper>
                                <GridItem key="1" size="1/1" element="div">
                                    <Table
                                        rowKey="storeCode"
                                        data={this.state.avalableStoresettings}
                                        caption="Store settings informations"
                                        visuallyHiddenCaption
                                        columns={columns}>
                                    </Table>
                                </GridItem>
                            </GridWrapper>
                    }              
                    {
                        (this.state.avalableStoresettings?.length === 0) &&
                        <Label htmlFor="norecords" className="emptyMessagebox">There are no store settings available to display</Label>
                    }
                </Fieldset>
            </Container>
            </>
    }

    public async componentDidMount() {
        try {
            if(this.props.eventID !== undefined) {
                const selEvent = await this.props.services.eventManagementService.getEventById(this.props.eventID);
                if(selEvent !== undefined) {
                    this.setState({
                       eventName: selEvent.name,
                       eventStartDate: selEvent.startDate,
                       eventEndDate: selEvent.endDate,
                    });
                }
                
                this.getStoreSettings();
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false,
                displayError : true
            });
        }        
    }

    private onDateChange(name: string, e: any){  
        let errors = this.state.errors;
        if(e === undefined || e === null) {
            errors[name] = "This information is required.";
        } else if(errors[name] !== undefined) {
            delete errors[name];
        }

        this.setState({
            storesetting: {
                ...this.state.storesetting,
                [name]: e
            },
            errors: errors
        });
    }

    private onOptionSelect(e: any) {
        if(e.target.value === "add") {
            this.setState({
                storeSettingOption: StoreSettingOptions.Add
            });
        } else if(e.target.value === "remove") {
            this.setState({
                storeSettingOption: StoreSettingOptions.Remove
            });
        }
    }

    private onTextChange(e: any) {
        this.setEventInfoState(e.target.name, e.target.value);
    }

    private radiobuttonChnage(e: any) {
        if(e.target.value !== undefined) {
            this.setState({
                storesetting: {
                 ...this.state.storesetting,
                 [e.target.name]: e.target.value
                }
            });           
        }
    }

    private onNumberChange(e: any) {
        if(e.target.value !== undefined && e.target.value !== "") {
            if(isNaN(e.target.value)){
                this.setEventInfoState(e.target.name, "");                     
            } 
            else{
                this.setEventInfoState(e.target.name, parseInt(e.target.value));  
            } 
        } else {
            this.setEventInfoState(e.target.name, undefined);          
        }
    }

    private setEventInfoState(name: string, value: any) {

        let errors = this.state.errors;
        if(value === undefined || value === "" || value === null) {
            errors[name] = "This information is required.";
        } else if(errors[name] !== undefined) {
            delete errors[name];
        }

        this.setState({
            storesetting: {
             ...this.state.storesetting,
             [name]: value
            },
            errors: errors
         });
    }

    private validateControls() {
        let validForm = true;
        const valMessage = "This information is requied";
        let errors: IError = {};

        if(this.state.storesetting !== undefined){
            if(this.state.storesetting.eventDate === undefined){
                validForm = false;
                errors["eventDate"] = valMessage;
            }
            if(this.state.storesetting.maxPledges === undefined){
                validForm = false;
                errors["maxPledges"] = valMessage;
            }
            if(this.state.storesetting.storeCode === undefined){
                validForm = false;
                errors["storeCode"] = valMessage;
            }
        }
        else{
            validForm = false;
        }
        
        if(!validForm){
            this.setState({
                errors: errors
            });
        }

        return validForm;
    }

    private async updateStoreSettings() {
        try {
            this.setState({
                loading: true,
            });

            if(this.validateControls()){
                if(this.state.storesetting?.storeCode !== undefined){
                    const storeCodes = this.state.storesetting.storeCode.split(',');
                    let storestoUpdate: IUpdateStoreEvent[] = [];
                    storeCodes.forEach((item) => {
                        if(this.state.storesetting?.eventDate !== undefined){
                            const store: IUpdateStoreEvent = {
                                storeCode: item.trim(),
                                eventDate: this.state.storesetting?.eventDate,
                                maxPledges: this.state.storesetting?.maxPledges ? this.state.storesetting.maxPledges : 0,
                                priority: this.state.storesetting?.priority ? parseInt(this.state.storesetting.priority) : 1                    
                            };
                            storestoUpdate.push(store);
                        }
                    });
                    
                    await this.props.services.eventManagementService
                        .updateEventStoreSettings(this.props.eventID, storestoUpdate);

                    this.setState({
                        showSuccess: true
                    }, ()=>this.getStoreSettings());
                }
            } 
            this.setState({
                loading: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false,
                displayError : true,
                showSuccess: false
            });
        }
    }

    private async removeStores() {
        try {
            this.setState({
                loading: true,
            });
           
            if(this.state.storesetting?.storeCode !== undefined) {
                const storeCodes = this.state.storesetting.storeCode.split(',');
                
                await this.props.services.eventManagementService
                    .removeStoresFromEvent(this.props.eventID, storeCodes);

                this.setState({
                    showSuccess: true,
                    loading: false
                }, ()=> this.getStoreSettings());
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false,
                displayError : true,
                showSuccess: false
            });
        }
    }

    private async getStoreSettings() {
        const eventManagementService = this.props.services.eventManagementService;

        // check if user have permission
        const isAdmin = await eventManagementService.isAdmin();
        if(!isAdmin) {
            this.props.navigate(AuthorisationRoutes.UnauthorisedPage);
        }

        const results = await eventManagementService.getEventStoreSettings(this.props.eventID);
        let storeSettings: any[] = [];

        if(results !== undefined){
            results.forEach((settings) => {
                storeSettings.push({
                    "storeCode": settings.storeCode,
                    "eventDate": new Date(settings.eventDate).toLocaleDateString(),
                    "maxPledges": settings.maxPledges,
                    "priority": settings.priority
                });
            });
        }
        
        this.setState({
            loading: false,
            avalableStoresettings: storeSettings
        });
    }
}

export default function StoreSettingsPage() {
    const navigate = useNavigate();
    const { id } = useParams();
    let idValue: number = 0;
    if(id) {
        idValue = parseInt(id);
    }
    
    const services = useServices();
    return <StoreSettings navigate={navigate} eventID={idValue} services={services}/>;
}