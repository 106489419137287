import { useContext, useState } from "react";
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, OutlinedButton } from "@jsluna/button";
import { Modal, ModalHeading } from '@jsluna/modal';
import { IEvent } from "../../models/events/IEvent";
import { IServiceCollection } from "../../services/IServiceCollection";
import { Notification, NotificationTypes } from "../Common/Notification";
import { ServiceContext } from "../../providers/ServiceContext";

interface IPledgeSummaryReportProps {
    event: IEvent;
}

export const PledgeSummaryReport = (props: IPledgeSummaryReportProps) => {
    const [showConfirm, setshowConfirm] = useState(false);
    const [showGenMessage, setShowGenMessage] = useState(false);
    const [showError, setShowError] = useState(false);
    const services = useContext(ServiceContext) as IServiceCollection;

    if(showError) {
        return  <Notification type={NotificationTypes.Error} 
        display={true} 
        displayMessage= "Unable to process your request"
        onClose={()=>setShowError(false)}/>;
    }

    return <>
        <Modal
            fullScreen
            restrictClose
            open={showConfirm}
            headingId="dialog-modal">
            <ModalHeading element="h3">
                Pledge Summary Report
            </ModalHeading>
            {
                !showGenMessage ?
                <p>
                    Are you sure want to regenerate the pledge summary report for {props.event.name}?
                </p>:
                <p>
                    Report generation in progress. Please refresh the Power BI report in few minutes.
                </p>
            }
            
            <ButtonGroupWrapper actionbar>
                <ButtonGroupSecondary> </ButtonGroupSecondary>
                <ButtonGroupPrimary>
                    <OutlinedButton
                        className="ln-u-margin-right"
                        onClick={() => { setshowConfirm(false); setShowGenMessage(false);}}>
                            {
                                showGenMessage ? "Close": "Cancel"
                            }
                    </OutlinedButton>
                    {
                        !showGenMessage &&
                        <FilledButton onClick={async () => {
                            try {
                                await generateReport(services, props.event);
                                setShowGenMessage(true);
                            } catch(err) {
                                console.log(err);
                                setShowError(true);
                            }
                        }}>
                            Generate
                        </FilledButton>
                    }
                </ButtonGroupPrimary>
            </ButtonGroupWrapper>
        </Modal>
        <ButtonGroupPrimary>
            <FilledButton onClick={() => setshowConfirm(true)}>
                Generate Pledge Summary Report
            </FilledButton>
        </ButtonGroupPrimary>
    </>;
}

async function generateReport(services: IServiceCollection, event: IEvent) {
    await services.reportingService.generatePledgeSummaryReport(event.eventId);
}