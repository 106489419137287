import React from "react";
import { Fieldset, Legend, RadioButtonField, FormOptionFieldList, SelectField} from '@jsluna/form';
import { Heading6 } from '@jsluna/typography';
import { FilledButton } from '@jsluna/button';
import { GridWrapper, GridItem, Container } from '@jsluna/grid';
import { AsyncAutocompleteField } from '@jsluna/autocomplete';
import '../Pledgestyles.scss';
import { IBasePageProps } from "../../IBasePageProps";
import { useNavigate } from "react-router";
import { PledgingRoutes } from "../../../Routes";
import { ILiveEvent } from "../../../models/storeevents/ILiveEvent";
import { IStoreSearchInput } from "../../../models/storeevents/IStoreSearchInput";
import { IStoreSearchResult } from "../../../models/storeevents/IStoreSearchResult";
import { Notification, NotificationTypes } from "../../Common/Notification";
import MapView from "./MapView";
import { LocalitiesService } from "../../../services/localities/LocalitiesService";
import { useServices } from "../../../providers/hooks/useServices";
import { IStoreEventService } from "../../../services/storeevents/IStoreEventService";
import { IStore } from "../../../models/storeevents/IStore";

interface IPledgeSearchProps extends IBasePageProps {
}

interface IError {
    [key:string]: string;
}

interface IPledgeSearchState {
    liveEvents?: ILiveEvent[];
    selectedEventId?: string;
    currentSearchValue: string;
    nearestStores?: IStoreSearchResult[];  
    selectedStoreTypes: string;
    errors: IError;
    loading: boolean;
    displayError: boolean;
    storesLoading: boolean;
    eventInfoHtml: string;
}

const CONFIG_NAME_EVENTINFOTEXT: string = "EventInfoHtml";
const ALL_STORES_OPTION_VALUE: string = "1,2,3,5,6,7,8";
class PledgeSearch extends React.Component<IPledgeSearchProps, IPledgeSearchState> {
    private readonly localitiesService: LocalitiesService;
    private readonly storeEventService: IStoreEventService;
    constructor(props:IPledgeSearchProps) {
        super(props);
        
        this.state = {
            errors: {},
            eventInfoHtml: "",
            loading: true,
            displayError: false,
            storesLoading: false,
            selectedStoreTypes: ALL_STORES_OPTION_VALUE,
            currentSearchValue: ""
        };

        this.localitiesService = new LocalitiesService();
        this.storeEventService = props.services.storeEventService;

        this.pledgeStore.bind(this);
    }

    public async componentDidMount() {
        try {
            const liveEvents = await this.props.services.storeEventService.getLiveEvents();
            const config = await this.props.services.configurationService.getConfiguration(CONFIG_NAME_EVENTINFOTEXT);
            this.setState({
                liveEvents: liveEvents,
                loading: false,
                eventInfoHtml: config.value
            });
        } catch(err) {
            console.log(err);
            this.setState({
                loading: false,
                displayError : true
            });
        }
    }

    public render(): React.ReactNode {
        const loadPledges = this.state.liveEvents !== undefined && this.state.liveEvents.length > 0;
        if(this.state.loading) {
            return <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
        }

        if(this.state.displayError) {
            return <Notification type={NotificationTypes.Error} 
            display={true} 
            displayMessage= "Unable to process your request"
            onClose={()=>{
                this.setState({
                  displayError: false
                });
            }}/>
        }

        return<>
        
        <Container free size="lg">
            <Legend fontStyle="h2" className='topHeaderPadding'>Make a pledge</Legend>
            <Fieldset>
                <div dangerouslySetInnerHTML={{__html: this.state.eventInfoHtml}}></div>
            </Fieldset>
            <br/>
            {
                (loadPledges && this.state.liveEvents !== undefined && this.state.liveEvents.length > 0) &&
                <Fieldset>
                    <Heading6 htmlFor="intro">Submit your pledge below</Heading6>
                        {
                            <>
                                <RadioButtonField 
                                    label="Select an event"
                                    name="eventID"
                                    fullWidth
                                    options={this.getEventOptions()}
                                    onClick={this.setEventInfoState.bind(this)}
                                    error={this.state.errors["eventID"]}
                                    required
                                />
                                {
                                    this.state.selectedEventId &&
                                    <>
                                        {
                                            window.innerWidth > 500 ?
                                            <RadioButtonField 
                                                onClick={this.setSelectedStoreType.bind(this)}
                                                label="Show only"
                                                name="eventAppliesTo"
                                                fullWidth
                                                outlined
                                                listType="inline"
                                                options={this.getStoreTypes()} />:
                                            <SelectField 
                                                name="eventAppliesTo"
                                                label="Show Only"
                                                onChange={this.setSelectedStoreType.bind(this)}
                                                options={this.getStoreTypes()} />
                                        }
                                        
                                        <div data-testid="autocomplete-async-1">
                                            <AsyncAutocompleteField
                                                name="selectedStoreKey"
                                                placeholder="Search by address or postcode or store"
                                                label="Search for an address or store"
                                                loadOptions={this.searchStore.bind(this)}
                                                role="search"
                                                onSelect={this.getNearbyStores.bind(this)} />
                                        </div>
                                    </>
                                }
                            </>
                        }
                        {
                            this.state.storesLoading &&
                            <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
                        }
                </Fieldset>
            }
            {
                (loadPledges && !this.state.storesLoading) &&
                <>
                    <Fieldset>
                        <GridWrapper>
                            <GridItem size="1/1" element="li">
                                {
                                    (this.state.nearestStores && this.state.selectedEventId) && 
                                    <MapView stores={this.state.nearestStores} eventId={this.state.selectedEventId} onPledge={()=>{}} />
                                }
                            </GridItem>
                        </GridWrapper>
                    </Fieldset>
                    {
                        this.state.nearestStores?.map((st: IStoreSearchResult, index)=> {
                            return <>
                                <Fieldset><GridWrapper>
                                        <GridItem size="9/12" element="li">
                                            <GridWrapper>
                                                <GridItem size="1/1" element="li">
                                                    <GridWrapper>				
                                                        <GridItem size="1/1" element="li">                                            
                                                            <div className="storeTitle">
                                                                {
                                                                    (st.storeTypeId === 2  || st.storeTypeId === 3)?
                                                                    <span className="storeOrderingnumber storeIconArgos">{index + 1}</span>:
                                                                    <span className="storeOrderingnumber storeIconSainsburys">{index + 1}</span>
                                                                }
                                                                
                                                                <span className="paddingleft20">{st.storeName}</span>
                                                            </div>
                                                            <span className="storeMile">{st.distanceInMiles.toFixed(2)} miles</span>
                                                        </GridItem>
                                                    </GridWrapper>
                                                    <GridWrapper>
                                                        <GridItem size="1/1" element="li">
                                                            Address: {this.formatStoreAddress(st)}
                                                        </GridItem>
                                                    </GridWrapper>
                                                </GridItem>
                                            </GridWrapper>
                                        </GridItem>
                                        <GridItem size="3/12" element="li">
                                            <FilledButton className="btnPledgehere" onClick={() => this.pledgeStore(st.storeCode)}>Pledge here</FilledButton>
                                        </GridItem>
                                    </GridWrapper>
                                    <hr className="horizontalDivider" />
                                    </Fieldset>
                            </>
                        })
                    }
                </>
            }
        </Container>
        </>;
    }

    private formatStoreAddress(st: IStoreSearchResult): string {
        return `${st.storeAddress1}${st.storeAddress2?`, ${st.storeAddress2}`: ''}, ${st.postTown}, ${st.postCode}`;
    }

    private getEventOptions(): FormOptionFieldList {
        let options: FormOptionFieldList = [];
        if(this.state.liveEvents !== undefined) {
            this.state.liveEvents.forEach((e)=>{
                let selected = e.eventId === this.state.selectedEventId;
                
                options.push({
                    label: e.name,
                    value: e.eventId,
                    checked: selected
                });
            });
        }

        return options;
    }

    private setSelectedStoreType(e: any) {
        if(e && e.target && e.target.value) {
            this.setState({
                selectedStoreTypes: e.target.value
            }, async ()=>{
                if(this.state.currentSearchValue && this.state.currentSearchValue !== "") {
                    await this.loadNearsetStores(this.state.currentSearchValue);
                }
            });
        }
    }

    private setEventInfoState(e: any) {
        this.setState({
            selectedEventId: e.target.value,
            nearestStores: [],
        });
    }

    private getStoreTypes() {
        let options = [
            { value: ALL_STORES_OPTION_VALUE, label: "All Stores", defaultChecked: true },
            { value: "1", label: "Sainsbury's Stores" },
            { value: "2,3", label: "Argos Stores" },
            { value: "5", label: "Convenience" },
            { value: "6", label: "Local Fulfilment Centres" },
            { value: "7,8", label: "Collection Points" }
        ];

        return options;
    }

    private async searchStore(value: string) {
        if(this.state.selectedEventId) {
            const storeTypes: number[] = [];
            this.state.selectedStoreTypes.split(',')
                .forEach(st=>{
                    storeTypes.push(parseInt(st));
                });
            
            let autoCompleteItems:any[] = [];
            const stores = await this.storeEventService.searchStores(this.state.selectedEventId, value, storeTypes);
            if(stores.length > 0) {
                stores.forEach((item)=> {
                    autoCompleteItems.push({
                        label: this.formatStore(item),
                        value: `st:${item.storeCode}`
                    });
                });
            } else {
                const storeSearchOptions = await this.localitiesService.getLocationAutoComplete(value);
                if(storeSearchOptions !== undefined) {
                    let autoCompleteItems:any[] = [];
                    if(storeSearchOptions.result && storeSearchOptions.result.suggestions) {
                        storeSearchOptions.result.suggestions.forEach((item)=>{
                            autoCompleteItems.push({
                                label: item.text,
                                value: `add:${item.global_address_key}`
                            });
                        });
                    }
    
                    return autoCompleteItems;
                }
            }

            return autoCompleteItems;
        } else {
            return [];
        }
    }

    private formatStore(store: IStore) {
        let storeTypeStr = "";
        switch(store.storeTypeId) {
            case 1:            
                storeTypeStr = "Sainsbury's"
                break;
            case 2:
                storeTypeStr = "Argos SiS"
                break;
            case 3:
                storeTypeStr = "Argos SaS"
                break;
            case 5:
                storeTypeStr = "Convenience"
                break;
            case 6:
                storeTypeStr = "Local Fulfilment Centre"
                break;
            case 7:
            case 8:
                storeTypeStr = "Collection Point"
                break;
            default:
                storeTypeStr = "Sainsbury's";
                break;
        }

        if(store.storeAddress2) {
            return `${storeTypeStr} - ${store.storeName} (${store.storeCode}) : ${store.storeAddress1}, ${store.storeAddress2}, ${store.postTown}, ${store.postCode}`;
        } else {
            return `${storeTypeStr} - ${store.storeName} (${store.storeCode}) : ${store.storeAddress1}, ${store.postTown}, ${store.postCode}`;
        }
    }

    private async getNearbyStores(value: any) {
        if(value !== null
            && this.state.selectedEventId !== undefined) {
            this.setState({
                loading: false,
                storesLoading: true,
                currentSearchValue: value.value
            }, async ()=>{
                await this.loadNearsetStores(this.state.currentSearchValue)
            });
        } else {
            this.setState({
                nearestStores: [],
                storesLoading: false,
                currentSearchValue: ""
            });
        }
    }

    private async loadNearsetStores(selectedValue: string) {
        if(this.state.selectedEventId) {
            if(selectedValue.indexOf("st:") !== -1) {
                const storeCode = selectedValue.replace("st:", "");
                this.props.navigate(`${PledgingRoutes.EventPledge}/${this.state.selectedEventId}/${storeCode}`);
            } else {
                const address = selectedValue.replace("add:", "");
                const coordinates = await this.localitiesService.getGeocoding(address);
                if(coordinates !== undefined) {
                    const storeSearchInput: IStoreSearchInput = {
                        latitude: coordinates.lat,
                        longitude: coordinates.long,
                        withInMiles: 50,
                        maxResultsCount: 25,
                        storeTypes: this.state.selectedStoreTypes
                    };
    
                    try {
                        const nearestStores = await this.props.services.storeEventService
                            .searchNearestStores(this.state.selectedEventId, storeSearchInput);
    
                        this.setState({
                            nearestStores: nearestStores,
                            storesLoading: false
                        });
                    } catch(err) {
                        console.log(err);
                        this.setState({
                            loading: false,
                            displayError : true,
                            storesLoading: false
                        });
                    }
                }
            }
        }
    }

    private pledgeStore(storeCode: string) {
        let validForm = true;
        const valMessage = "This information is requied";
        let errors: IError = {};

        if(this.state.selectedEventId === undefined) {
            validForm = false;
            errors["eventID"] = valMessage;
        }

        if(!validForm) {
            this.setState({
                errors: errors
            });
        }
        else {
            this.props.navigate(`${PledgingRoutes.EventPledge}/${this.state.selectedEventId}/${storeCode}`);
        }
    }
}

export default function PledgeSearchPage() {
    const navigate = useNavigate();    
    const services = useServices();
    return <PledgeSearch navigate={navigate} services={services}/>;
}