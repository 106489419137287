/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import PapaParse, { UnparseConfig } from 'papaparse';
import { FilledButton } from '@jsluna/button';

export interface Props {
  children: React.ReactNode;
  data: any;
  filename: string;
  style?: any;
  className?: string;
  bom?: boolean;
  config?: UnparseConfig;
}

function useCSVDownloaderComponent(): any {
  const CSVDownloaderComponent = ({
    data = {},
    filename,
    bom = false,
    config = {},
    className = ''
  }: Props): JSX.Element => {
    const download = async (): Promise<void> => {
      const bomCode = bom ? '\ufeff' : '';
      let csvContent = null;
      let csvURL = null;

      if (typeof data === 'object') {
        csvContent = PapaParse.unparse(data, config);
      } else {
        csvContent = data;
      }

      const csvData = new Blob([`${bomCode}${csvContent}`], {
        type: 'text/csv;charset=utf-8;',
      });

      const navObj: any = window.navigator;
      if (navObj.msSaveBlob) {
        csvURL = navObj.msSaveBlob(csvData, `${filename}.csv`);
      } else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      const link = document.createElement('a');
      link.href = csvURL as string;
      link.setAttribute('download', `${filename}.csv`);
      link.click();
      link.remove();
    };

    return (
        <FilledButton
            onClick={download} className={className}>
                Download
        </FilledButton>
    );
  };

  const CSVDownloader = React.useMemo(() => CSVDownloaderComponent, []) as any;

  return CSVDownloader;
}

export function useCSVDownloader(): any {
  const CSVDownloader = useCSVDownloaderComponent();

  return CSVDownloader;
}