import { MsalHelper } from "../../auth/MsalHelper";
import { appenv } from "../../env";
import { IPledgeEvent } from "../../models/pledging/IPledgeEvent";
import { IDivisionPledgeByDateSummary, IDivisionSummaryReport } from "../../models/reporting/IDivisionSummaryReport";
import { ISSCLeaderReport } from "../../models/reporting/ILeaderReport";
import { ILineManagerReport } from "../../models/reporting/ILineManagerReport";
import { Exceptions } from "../pledgingservice/EventPledgingService";
import { IReportingService } from "./IReportingService";

export class ReportingService implements IReportingService {
    constructor(private readonly msalHelper: MsalHelper) {
    }
    
    public async getAllEvents(): Promise<IPledgeEvent[]> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/reports/events/all`;

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const events: IPledgeEvent[] = await response.json();
                return events;
            } else {
                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting live events');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting live events');
        }
    }

    public async generatePledgeSummaryReport(eventId: string): Promise<void> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/reports/events/${eventId}/colleaguepledging`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST'
            });

            if(!response.ok) {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error generating pledge summary report.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error generating pledge summary report.');
        }
    }

    public async generateSSCLeaderReport(eventId: string, username: string): Promise<string> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/reports/events/${eventId}/sscleader`;
        const body = {
            username: username
        };

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                method: 'POST',
                body: JSON.stringify(body)
            });

            if(response.ok) {
                const result = await response.json();
                return result.reportId;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error generating ssc leader report.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error generating ssc leader report.');
        }
    }

    public async getSSCLeaderReport(reportId: string): Promise<ISSCLeaderReport> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/reports/sscleader/${reportId}`;

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                return result;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting ssc leader report.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting ssc leader report.');
        }
    }

    public async generateDivisionSummaryReport(eventId: string): Promise<string> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/reports/events/${eventId}/divisionsummary`;        

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                method: 'POST'
            });

            if(response.ok) {
                const result = await response.json();
                return result.reportId;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error generating division summary report.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error generating division summary report.');
        }
    }

    public async getDivisionSummaryReport(reportId: string): Promise<IDivisionSummaryReport> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/reports/divisionsummary/${reportId}`;

        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();

                let pledgeByDateSummary: IDivisionPledgeByDateSummary[] = [];
                result.pledgesByDate.forEach((d: any)=>{
                    pledgeByDateSummary.push({
                        division: d.division,
                        eventDate: new Date(d.eventDate),
                        pledgingsCount: d.pledgingsCount,
                        colleaguesCount: d.colleaguesCount
                    });
                });

                result.pledgesByDate = pledgeByDateSummary;

                return result;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting division summary report.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting division summary report.');
        }
    }

    public async getLineManagerReport(eventId: string): Promise<ILineManagerReport> {
        const requestUrl: string = `${appenv.API_PLEDGING_BASEURL}/reports/events/${eventId}/linemanager`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            var response = await fetch(requestUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result: ILineManagerReport = await response.json();
                return result;
            } else {
                if(response.status === 401) {
                    throw new Error(Exceptions.Unauthorised_Exception);
                }

                const text: string = await response.text();
                console.log(text);
                throw new Error('Error getting line manager report.');
            }
        } catch(err) {
            console.log(err);
            throw new Error('Error getting line manager report.');
        }
    }
}