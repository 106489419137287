import * as React from 'react';
import { Table } from '@jsluna/table';
import { IDivisionPledgeByDateSummary, IDivisionPledgeSummary, IDivisionSummaryReport } from '../../../models/reporting/IDivisionSummaryReport';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { ButtonGroupSecondary } from '@jsluna/button';

interface IDivisionSummaryData {
    [key: string]: any;
}

interface IDivisionSummaryProps {
    report: IDivisionSummaryReport;
    CSVDownloader: any;
}

export class DivisionSummary extends React.Component<IDivisionSummaryProps, {}> {

    private reportData: IDivisionSummaryData[] = [];

    public render(): React.ReactNode {
        const CSVDownloader = this.props.CSVDownloader;
        const columns = this.getReportTableColumns(this.props.report);
        this.reportData = this.getReportTableData(this.props.report);
        return <GridWrapper>
                <GridItem key="actionbar" size="1/1" element="div">
                    <ButtonGroupSecondary>
                        <CSVDownloader
                            filename={"DivisionSummaryReport"}
                            bom={true}
                            data={this.getExportData(this.props.report)} />
                    </ButtonGroupSecondary>
                </GridItem>
                <GridItem size="1/1" element="div">
                    <Table
                        rowKey="division"
                        columns={columns}
                        data={this.reportData}
                        caption="Division pledge summary report"
                        visuallyHiddenCaption>
                    </Table>
                </GridItem>
            </GridWrapper>;
    }

    private getExportData(report: IDivisionSummaryReport): any {
        let data:any[] = [];
        
        let totalData: IDivisionSummaryData = {}
        totalData["Division"] = "Total";
        totalData["Total Mandatory Pledges"] = 0;
        totalData["Number of colleagues pledged"] = 0;
        totalData["No. of colleagues who meet minimum requirement"] = 0;
        totalData["% of eligible colleagues pledged mandatory shifts"] = 0;
        totalData["Total Colleagues"] = 0;

        report.divisionPledgeSummary.forEach((ds: IDivisionPledgeSummary)=>{
            let divisionData: IDivisionSummaryData = {};

            divisionData["Division"] = ds.division;

            const divsionDateData = report.pledgesByDate.filter(pd=> pd.division === ds.division);
            let totalPledges = 0;
            divsionDateData.forEach(dd => {
                const columnName = this.formatDateColumnHeader(dd.eventDate);
                divisionData[columnName] = dd.pledgingsCount;
                if(totalData[columnName]) {
                    totalData[columnName] += dd.pledgingsCount;
                } else {
                    totalData[columnName] = dd.pledgingsCount;
                }

                totalPledges += dd.pledgingsCount;
            });

            divisionData["Total Mandatory Pledges"] = totalPledges;
            totalData["Total Mandatory Pledges"] += totalPledges;
            divisionData["Number of colleagues pledged"] = ds.colleaguePledgesCount;
            totalData["Number of colleagues pledged"] += ds.colleaguePledgesCount;    
            divisionData["No. of colleagues who meet minimum requirement"] = ds.totalMetRequirement;
            totalData["No. of colleagues who meet minimum requirement"] += ds.totalMetRequirement;

            const percentage = (ds.totalPercentageMetRequirement).toFixed(2);
            divisionData["% of eligible colleagues pledged mandatory shifts"] = percentage;
            totalData["% of eligible colleagues pledged mandatory shifts"] += parseFloat(percentage);
            divisionData["Total Colleagues"] = ds.totalProfiles;
            totalData["Total Colleagues"] += ds.totalProfiles;

            data.push(divisionData);
        });

        data = data.sort((a, b) => a["Division"] && a["Division"].localeCompare(b["Division"]));
        totalData["% of eligible colleagues pledged mandatory shifts"] = (totalData["% of eligible colleagues pledged mandatory shifts"] / data.length).toFixed(2);
        data.push(totalData);

        return data;
    }

    private getReportTableData(report: IDivisionSummaryReport): any {
        let data: IDivisionSummaryData[] = [];
        
        let totalData: IDivisionSummaryData = {}
        totalData["division"] = "Total";
        totalData["pledgingsCount"] = 0;
        totalData["colleaguePledgesCount"] = 0;
        totalData["totalMetRequirement"] = 0;
        totalData["totalPercentageMetRequirement"] = 0;
        totalData["totalProfiles"] = 0;
        report.divisionPledgeSummary.forEach((ds: IDivisionPledgeSummary)=>{
            let divisionData: IDivisionSummaryData = {};

            const divsionDateData = report.pledgesByDate.filter(pd=> pd.division === ds.division);
            let totalPledges = 0;
            divsionDateData.forEach(dd => {
                const columnName = this.getDateColumnName(dd.eventDate);
                divisionData[columnName] = dd.pledgingsCount;
                if(totalData[columnName]) {
                    totalData[columnName] += dd.pledgingsCount;
                } else {
                    totalData[columnName] = dd.pledgingsCount;
                }
                
                totalPledges += dd.pledgingsCount;
            });

            divisionData["division"] = ds.division;
            divisionData["pledgingsCount"] = totalPledges;
            totalData["pledgingsCount"] += totalPledges;
            divisionData["colleaguePledgesCount"] = ds.colleaguePledgesCount;
            totalData["colleaguePledgesCount"] += ds.colleaguePledgesCount;
            divisionData["totalMetRequirement"] = ds.totalMetRequirement;
            totalData["totalMetRequirement"] += ds.totalMetRequirement;

            const percentage = ((ds.colleaguePledgesCount / ds.totalProfiles) * 100).toFixed(2);
            divisionData["totalPercentageMetRequirement"] = percentage;
            totalData["totalPercentageMetRequirement"] += parseFloat(percentage);
            divisionData["totalProfiles"] = ds.totalProfiles;
            totalData["totalProfiles"] += ds.totalProfiles;

            data.push(divisionData);
        });

        data = data.sort((a, b) => a["division"] && a["division"].localeCompare(b["division"]));
        totalData["totalPercentageMetRequirement"] = (totalData["totalPercentageMetRequirement"] / data.length).toFixed(2);
        data.push(totalData);

        return data;
    }

    private getReportTableColumns(report: IDivisionSummaryReport): any[] {
        const columns: any[] = [];
        columns.push({
            name: "Division",
            accessor: (rowData: any) => ({ value: rowData.division }),
            hideLabel: true,
            className: "divisionsummary-division-col"
        });

        const dateValues: Date[] = [];

        // create date columns
        report.pledgesByDate.forEach((e: IDivisionPledgeByDateSummary) => {
            if(dateValues.filter(d=>d.toISOString() === e.eventDate.toISOString()).length === 0) {
                columns.push({
                    name: this.formatDateColumnHeader(e.eventDate),
                    accessor: (rowData: any) => ({ value: rowData[this.getDateColumnName(e.eventDate)] }),
                    hideLabel: true
                });

                dateValues.push(e.eventDate);
            }
        });

        columns.push({
            name: "Total Mandatory Pledges",
            accessor: (rowData: any) => ({ value: rowData.pledgingsCount }),
            hideLabel: true
        });

        columns.push({
            name: "Number of colleagues pledged",
            accessor: (rowData: any) => ({ value: rowData.colleaguePledgesCount }),
            hideLabel: true
        });

        columns.push({
            name: "No. of colleagues who meet minimum requirement",
            accessor: (rowData: any) => ({ value: rowData.totalMetRequirement }),
            hideLabel: true
        });

        columns.push({
            name: "% of eligible colleagues pledged mandatory shifts",
            accessor: (rowData: any) => ({ value: rowData.totalPercentageMetRequirement }),
            hideLabel: true
        });

        columns.push({
            name: "Total Colleagues",
            accessor: (rowData: any) => ({ value: rowData.totalProfiles }),
            hideLabel: true
        });

        return columns;
    }

    private formatDateColumnHeader(date: Date) {
        return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    }

    private getDateColumnName(date: Date) {
        return `dt_col_${date.getDate()}${date.getMonth()}${date.getFullYear()}`;
    }
}
