import * as React from 'react';
import { 
    AsyncAutocompleteField
} from '@jsluna/autocomplete';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { FilledButton } from '@jsluna/button';
import { ServiceContext } from '../../providers/ServiceContext';
import { IServiceCollection } from '../../services/IServiceCollection';
import { MsalHelper } from '../../auth/MsalHelper';
import { IUserProfile } from '../../models/reporting/ILineManagerReport';

interface IImpersonateProps {
    msalHelper: MsalHelper;
    onImpersonate(usename: string): void;
}

interface IImpersonateState {
    isAdmin: boolean;
    selectedUser?: string;
}

export class Impersonate extends React.Component<IImpersonateProps, IImpersonateState> {
    static contextType = ServiceContext;
    constructor(props: IImpersonateProps) {
        super(props);
        
        this.state = {
            isAdmin: false
        }
    }

    public async componentDidMount(): Promise<void> {
        const services: IServiceCollection = this.context;
        try {
            const isAdmin = await services.eventManagementService.isAdmin();
            this.setState({
                isAdmin: isAdmin
            });
        } catch(error) {
            console.log(error);
        }
    }

    public render(): React.ReactNode {
        if(this.state.isAdmin) {
            return <GridWrapper>
                        <GridItem key="autocomplete" size="2/4" element="div">
                            <AsyncAutocompleteField
                                className="search-field"
                                name="aasearchcolleague"
                                placeholder="Type keyword to search for a colleague"
                                label="Select a colleague to perform the test as"
                                loadOptions={this.searchColleagues.bind(this)}
                                onSelect={this.onColleagueSelected.bind(this)}
                                role="search" />
                        </GridItem>
                        <GridItem key="impersonate" size="1/4" element="div">
                            <GridWrapper>
                                <GridItem size="1/1" element="div"><label></label></GridItem>
                                <GridItem size="1/1" element="div">
                                    <FilledButton type="submit"
                                        onClick={this.impersonate.bind(this)}>
                                            Test
                                    </FilledButton>
                                </GridItem>
                            </GridWrapper>
                        </GridItem>
                        <GridItem key="empty" size="1/4" element="div">
                            <></>
                        </GridItem>
                    </GridWrapper>;
        } else {
            return <></>;
        }
    }

    private async searchColleagues(value: string) {
        const services: IServiceCollection = this.context;
        if(value && value.length >= 3) {
            const results = await services.directReportService.searchProfiles(value);
            if(results) {
                let autoCompleteItems:any[] = [];
                results.forEach((item: IUserProfile)=>{
                    autoCompleteItems.push({
                        label: `${item.displayName} (${item.userPrincipalName})`,
                        value: item.userPrincipalName
                    });
                });

                return autoCompleteItems;
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    private onColleagueSelected(value: any): void {
        if(value) {
            this.setState({
                selectedUser: value.value
            });
        }
    }

    private impersonate() {
        if(this.state.selectedUser) {
            this.props.onImpersonate(this.state.selectedUser);
        }
    }
}