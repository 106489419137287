type EnvType = {
    MSAL_AUTH_CLIENTID: string,
    MSAL_AUTH_AUTHORITY: string,
    MSAL_AUTH_REDIRECT_URI: string,
    API_SCOPE: string,
    WOOSMAP_KEY: string,
    WOOSMAP_URL: string,
    EXPERIAN_API_BASE_URL: string,
    EXPERIAN_TOKEN: string,
    TRAVELTIME_TILES_URL: string,
    TRAVELTIME_APP_ID: string,    
    API_EVENTMANAGEMENT_BASEURL: string,
    API_STOREEVENTS_BASEURL: string,
    API_PLEDGING_BASEURL: string,
    API_EXEMPTION_BASEURL: string,
    OURSAINSBURYS_URL: string,
    POWERBI_REPORT_URL: string
}

export const appenv: EnvType = {
    MSAL_AUTH_CLIENTID: process.env.REACT_APP_MSAL_AUTH_CLIENTID ? process.env.REACT_APP_MSAL_AUTH_CLIENTID: "",
    MSAL_AUTH_AUTHORITY: process.env.REACT_APP_MSAL_AUTH_AUTHORITY ? process.env.REACT_APP_MSAL_AUTH_AUTHORITY: "",
    MSAL_AUTH_REDIRECT_URI: process.env.REACT_APP_MSAL_AUTH_REDIRECT_URI ? process.env.REACT_APP_MSAL_AUTH_REDIRECT_URI: "",
    API_SCOPE: process.env.REACT_APP_API_SCOPE ? process.env.REACT_APP_API_SCOPE: "",
    WOOSMAP_KEY: process.env.REACT_APP_WOOSMAP_KEY ? process.env.REACT_APP_WOOSMAP_KEY: "",
    EXPERIAN_API_BASE_URL: process.env.REACT_APP_EXPERIAN_API_BASE_URL ? process.env.REACT_APP_EXPERIAN_API_BASE_URL: "",
    EXPERIAN_TOKEN: process.env.REACT_APP_EXPERIAN_TOKEN ? process.env.REACT_APP_EXPERIAN_TOKEN: "",
    TRAVELTIME_TILES_URL: process.env.REACT_APP_TRAVELTIME_TILES_URL ? process.env.REACT_APP_TRAVELTIME_TILES_URL: "",
    TRAVELTIME_APP_ID: process.env.REACT_APP_TRAVELTIME_APP_ID ? process.env.REACT_APP_TRAVELTIME_APP_ID: "",    
    API_EVENTMANAGEMENT_BASEURL: process.env.REACT_APP_API_EVENTMANAGEMENT_BASEURL ? process.env.REACT_APP_API_EVENTMANAGEMENT_BASEURL: "",
    API_STOREEVENTS_BASEURL: process.env.REACT_APP_API_STOREEVENTS_BASEURL ? process.env.REACT_APP_API_STOREEVENTS_BASEURL: "",
    API_PLEDGING_BASEURL: process.env.REACT_APP_API_PLEDGING_BASEURL ? process.env.REACT_APP_API_PLEDGING_BASEURL: "",
    API_EXEMPTION_BASEURL: process.env.REACT_APP_API_EXEMPTION_BASEURL ? process.env.REACT_APP_API_EXEMPTION_BASEURL: "",
    OURSAINSBURYS_URL : process.env.REACT_APP_OURSAINSBURYS_URL ? process.env.REACT_APP_OURSAINSBURYS_URL: "",
    WOOSMAP_URL : process.env.REACT_APP_WOOSMAP_URL ? process.env.REACT_APP_WOOSMAP_URL: "",
    POWERBI_REPORT_URL: process.env.REACT_APP_POWERBI_REPORT_URL ? process.env.REACT_APP_POWERBI_REPORT_URL: "",
}
