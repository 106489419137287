import * as React from 'react';
import { useState } from 'react';
import { Editor, EditorProvider } from '../../components/texteditor/editor';
import { useServices } from '../../providers/hooks/useServices';
import { Container } from '@jsluna/grid';
import { Fieldset, Label, Legend } from '@jsluna/form';
import { ButtonGroupPrimary, ButtonGroupWrapper, FilledButton } from '@jsluna/button';
import { Notification, NotificationTypes } from '../Common/Notification';
import { BtnBold, BtnBulletList, BtnItalic, BtnLink, BtnNumberedList, BtnStyles, BtnSuperscript, BtnUnderline, Toolbar } from '../../components/texteditor/toolbar';

const CONFIG_NAME_EVENTINFOTEXT: string = "EventInfoHtml";
export const HomePageTextSettingsPage = () => {
    const [html, setHtml] = useState("");
    const [isHtmlValid, setIsHtmlValid] = useState(true);
    const [isError, setIsError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const services = useServices();

    React.useEffect(()=>{
        const fetchConfig = async() => {
            const config = await services.configurationService.getConfiguration(CONFIG_NAME_EVENTINFOTEXT);
            if(config !== undefined) {
                setHtml(config.value);
            }
        }

        fetchConfig()
        .catch((err: any)=>{
            console.log(err);
        })
    }, [services.configurationService]);
    
    function onChange(e: any) {
        setHtml(e.target.value.trim());
        if(!e.target.value || e.target.value.trim() === "") {
            setIsHtmlValid(false);
        } else {
            setIsHtmlValid(true);
        }
    }

    const updateText = async() => {
        try {
            await services.configurationService.setConfiguration(CONFIG_NAME_EVENTINFOTEXT, html);
            setShowSuccess(true);
        } catch {
            setIsError(true);
            setShowSuccess(false);
        }
    }
    
    return <Container free size="lg">
        <Legend fontStyle="h2" className='topHeaderPadding'>Home page text settings</Legend>
        {
            isError &&
            <Notification type={NotificationTypes.Error} 
                display={true} 
                displayMessage= "Unable to process your request"
                onClose={()=>{
                    setIsError(false);
            }}/>
        }
        {
            showSuccess &&
            <Notification type={NotificationTypes.Success} 
                display={true} 
                displayMessage= "The changes are saved."
                onClose={()=>{
                    setShowSuccess(false);
            }}/>
        }
        <Fieldset>
            <Label htmlFor="" requiredLabel="*">Home Page Text</Label>
            <EditorProvider>
                <Editor value={html} onChange={onChange}>
                    <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnSuperscript />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <BtnLink />
                        <BtnStyles />
                    </Toolbar>
                </Editor>
            </EditorProvider>
            {
                !isHtmlValid &&
                <div id="firstNameValidation" className="ln-c-field-info ln-c-field-info--error" role="alert" aria-live="assertive">
                    Please complete
                </div>
            }
            
            <ButtonGroupWrapper>
                <ButtonGroupPrimary className='btnRightOnPopup'>
                    <FilledButton onClick={updateText} disabled={!isHtmlValid}>
                        Save changes
                    </FilledButton>
                </ButtonGroupPrimary>
            </ButtonGroupWrapper>
        </Fieldset>
    </Container>;
}