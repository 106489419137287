import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss'
import { Layout } from './components/layouts/Layout';
import UnauthorisedPage from './pages/auth/UnauthorisedPage';
import NewEventPage, { EditEventPage } from './pages/eventmanagement/EventForm';
import EventsPage from './pages/eventmanagement/Events';
import EventSubmittedPage, { EventRemovedPage, EventUpdatedPage } from './pages/eventmanagement/EventSubmitted';
import StoreSettingsPage from './pages/eventmanagement/StoreSettings';
import MyPledgesPage from './pages/pledgemanagement/MyPledges';
import MyStorePledgesPage from './pages/pledgemanagement/storepledges/MyStorePledges';
import PledgePage, { PledgeUpdatedPage } from './pages/pledgemanagement/Pledge';
import PledgeSearchPage from './pages/pledgemanagement/pledgesearch/PledgeSearch';
import PledgeSubmittedPage, { PledgeCancelledMessagePage, PledgeUpdatedMessagePage } from './pages/pledgemanagement/PledgeSubmitted';
import LeadersReportPage from './pages/reporting/leadersreport/LeadersReport';
import TeamPledgesPage from './pages/reporting/TeamPledges';
import { AuthorisationRoutes, EventRoutes, PledgingRoutes } from './Routes';
import ExemptionsPage from './pages/exemptions/Exemption';
import { HomePageTextSettingsPage } from './pages/settings/HomePageTextSettingsPage';
import { ChangeNamePage } from './pages/pledgemanagement/linemanager/ChangeNamePage';
import DivisionSummaryReportPage from './pages/reporting/divisionsummary/DivisionSummaryReport';

function App() {
  return <BrowserRouter>
    <Layout>
      <Routes>
          <Route path={PledgingRoutes.SearchPledge} element={<PledgeSearchPage />}/>
          <Route path={`${PledgingRoutes.EventPledge}/:eventId/:storeCode`} element={<PledgePage />}/>
          <Route path={`${PledgingRoutes.PledgeComplete}`} element={<PledgeSubmittedPage />}/>
          <Route path={`${PledgingRoutes.PledgeUpdated}`} element={<PledgeUpdatedMessagePage />}/>
          <Route path={`${PledgingRoutes.PledgeCancelled}`} element={<PledgeCancelledMessagePage />}/>
          <Route path={`${PledgingRoutes.EditPledge}/:eventId/:storeCode/:pledgeId`} element={<PledgeUpdatedPage />}/>
          <Route path={PledgingRoutes.MyPledges} element={<MyPledgesPage />}/>
          <Route path={PledgingRoutes.MyStorePledges} element={<MyStorePledgesPage />}/>
          <Route path={PledgingRoutes.TeamPledges} element={<TeamPledgesPage />}/>
          <Route path={PledgingRoutes.SwitchName} element={<ChangeNamePage />}/>
          <Route path={PledgingRoutes.LeadersReport} element={<LeadersReportPage />}/>
          <Route path={PledgingRoutes.DivisionSummaryReport} element={<DivisionSummaryReportPage />}/>
          <Route path={PledgingRoutes.Exemptions} element={<ExemptionsPage />}/>
          <Route path={EventRoutes.Events} element={<EventsPage />}/>
          <Route path={EventRoutes.CreateEvent} element={<NewEventPage />}/>
          <Route path={`${EventRoutes.EditEvent}/:id`} element={<EditEventPage />}/>
          <Route path={`${EventRoutes.HomePageText}`} element={<HomePageTextSettingsPage />}/>
          <Route path={`${EventRoutes.EventSubmitted}`} element={<EventSubmittedPage />}/>
          <Route path={`${EventRoutes.EventUpdated}`} element={<EventUpdatedPage />}/>
          <Route path={`${EventRoutes.EventRemoved}`} element={<EventRemovedPage />}/>
          <Route path={`${EventRoutes.StoreSettings}/:id`} element={<StoreSettingsPage />}/>
          <Route path={`${AuthorisationRoutes.UnauthorisedPage}`} element={<UnauthorisedPage />}/>
      </Routes>
    </Layout>
  </BrowserRouter>;
}

export default App;
