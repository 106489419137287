import * as React from 'react';
import { Tabs, TabLink, TabPanel } from '@jsluna/tabs';
import { Label } from '@jsluna/form';
import { Table } from '@jsluna/table';
import { IExemptedProfile, ILeaderReport } from '../../../models/reporting/ILeaderReport';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { PledgeSummaryReport } from './PledgeSummaryReport';
import { PledgeExemptions } from './PledgeExemptions';

interface ILineManagerReportProps {
    lineManagerReport: ILeaderReport;
    allDirectReports: ILeaderReport[];
    directReports: ILeaderReport[];
    exemptions: IExemptedProfile[];
    onLineManagerSelected(value: ILeaderReport): void;
    onExemptColleague(exempt: boolean, upn: string, reason?: string): void;
    onExemptTeam(upn: string, reason: string): void;
}

interface ILineManagerReportState {
    selectedTab: string;
}

const tabKeys = {
    Summary: "summary",
    ColleaguePledges: "pledges",
    NoPledgeColleagues: "nopledges",
    ExemptedColleagues: "exempt"
};



export class LineManagerReport extends React.Component<ILineManagerReportProps, ILineManagerReportState> {

    private noPledgeColumns = [
        {
            name: "Name",
            accessor: (rowData: any) => ({ value: rowData.displayName }),
            hideLabel: true,
        },
        {
            name: "Email",
            accessor: (rowData: any) => ({ value: rowData.mail }),
            hideLabel: true,
        },
        {
            name: "Exempted",
            accessor: (rowData: any) => ({ value: rowData.userPrincipalName }),
            hideLabel: true,
            render: this.renderExemption.bind(this)
        }
    ];

    private readonly tabs = [
        { name: 'Summary', key: tabKeys.Summary },        
        { name: 'Colleagues with no pledges', key: tabKeys.NoPledgeColleagues }
    ];

    constructor(props: ILineManagerReportProps) {
        super(props);

        this.state = {
            selectedTab: tabKeys.Summary
        };
    }

    public render(): React.ReactNode {
        return <>
                    <Tabs type="deep">
                        {this.tabs.map(tab => (
                            <TabLink
                                key={tab.key}
                                onClick={() => this.setState({ selectedTab: tab.key})}
                                active={this.state.selectedTab === tab.key}>
                                {tab.name}
                            </TabLink>
                        ))}
                    </Tabs>
                    <TabPanel>
                        <GridWrapper>
                            <GridItem size="1/1" element="div">
                                {
                                    this.renderTab()
                                }
                            </GridItem>
                        </GridWrapper>
                    </TabPanel>
                </>;
    }

    private renderTab() {
        switch(this.state.selectedTab) {
            case tabKeys.Summary:
                return <PledgeSummaryReport 
                    allDirectReports={this.props.allDirectReports}
                    reports={this.props.directReports}
                    exemptions={this.props.exemptions}
                    onSelected={this.onLineManagerSelected.bind(this)} 
                    onExemptTeam={(leaderUpn: string, exemptReason: string)=>{this.props.onExemptTeam(leaderUpn, exemptReason)}}
                    onExempt={this.onColleagueExempt.bind(this)} />;
            case tabKeys.NoPledgeColleagues:
                return this.props.lineManagerReport.nonPledgeUsers.length > 0 ?
                    <Table
                        rowKey="username"
                        data={this.props.lineManagerReport.nonPledgeUsers}
                        caption="User pledge informations"
                        visuallyHiddenCaption
                        columns={this.noPledgeColumns}>
                    </Table>
                    :
                    <Label htmlFor="norecords" className="emptyMessagebox">
                        There are no colleagues to display
                    </Label>
            case tabKeys.ExemptedColleagues:
                return <PledgeExemptions profiles={this.props.exemptions}
                    onExempt={this.onColleagueExempt.bind(this)} />
        }
    }

    private renderExemption(value: any) {
        const report = this.props.exemptions
            .find(x=>x.userPrincipalName.toLocaleLowerCase() === value.value.toLocaleLowerCase());
        return <Label htmlFor=''>
            {report?.exempted ? "Yes" : "No"}
        </Label>;
    }

    private onLineManagerSelected(value: ILeaderReport) {
        this.props.onLineManagerSelected(value);
    }

    private onColleagueExempt(exempt: boolean, upn: string, reason?: string) {
        this.props.onExemptColleague(exempt, upn, reason);
    }
}