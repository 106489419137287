import { MsalHelper } from "../../auth/MsalHelper";
import { appenv } from "../../env";
import { IDirectReport, IDirectReportGenerationRequest } from "../../models/exemptions/IDirectReport";
import { IUserProfile } from "../../models/reporting/ILineManagerReport";
import { IDirectReportService } from "./IDirectReportService";

export class DirectReportService implements IDirectReportService {
    constructor(private readonly msalHelper: MsalHelper) {
    }
    
    public async exists(username: string): Promise<boolean> {
        const errorMessage = "Error getting direct report exists";
        const requestUrl: string = `${appenv.API_EXEMPTION_BASEURL}/DirectReports/${username}/exists`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });
    
            if(response.ok) {
                const result = await response.json();
                return result.exists as boolean;
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }

    public async generate(username: string): Promise<IDirectReportGenerationRequest> {
        const errorMessage = "Error generating direct reports";
        const requestUrl: string = `${appenv.API_EXEMPTION_BASEURL}/DirectReports/${username}/generate`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'POST'
            });

            if(response.ok) {
                const result = await response.json();
                return result as IDirectReportGenerationRequest;
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }

    public async getGenerationStatus(requestId: string): Promise<IDirectReportGenerationRequest> {
        const errorMessage = "Error getting generation status";
        const requestUrl: string = `${appenv.API_EXEMPTION_BASEURL}/DirectReports/generate/status/${requestId}`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                return result as IDirectReportGenerationRequest;
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }

    public async query(username: string, queryText: string): Promise<IDirectReport[]> {
        const errorMessage = "Error querying direct reports";
        const requestUrl: string = `${appenv.API_EXEMPTION_BASEURL}/DirectReports/${username}/search/${queryText}`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                return result as IDirectReport[];
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }

    public async searchProfiles(queryText: string): Promise<IUserProfile[]> {
        const errorMessage = "Error searching profiles";
        const requestUrl: string = `${appenv.API_EXEMPTION_BASEURL}/DirectReports/search/${queryText}`;
        try {
            const accessToken = await this.msalHelper.getAccessToken([appenv.API_SCOPE]);
            const response = await fetch(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: 'GET'
            });

            if(response.ok) {
                const result = await response.json();
                return result as IUserProfile[];
            }

            const err = await response.text();
            console.log(err);
            throw new Error(errorMessage);
        } catch(err) {
            console.log(err);
            throw new Error(errorMessage);
        }
    }
}