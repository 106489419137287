import * as React from 'react';
import { IExemptedProfile, ILeaderReport } from '../../../models/reporting/ILeaderReport';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { Accordion, AccordionItem } from '@jsluna/accordion';
import { Tooltip, TooltipIcon, TooltipMessage } from '@jsluna/labs';
import { onKeyPress } from '@jsluna/utils';
import '@jsluna/labs/src/tooltip/scss/main.scss';
import { ButtonGroupPrimary, ButtonGroupWrapper, TextButton } from '@jsluna/button';

interface IPledgeSummaryReport {
    allDirectReports: ILeaderReport[];
    reports: ILeaderReport[];
    exemptions: IExemptedProfile[];
    onSelected(report: ILeaderReport): void;
    onExempt(exempt: boolean, upn: string, reason?: string): void;
    onExemptTeam(leaderUpn: string, exemptReason: string): void;
}

interface IPledgeSummaryReportState {
    selectedColleague?: string;
    openToolTip1: boolean;
    openToolTip2: boolean;
}

export class PledgeSummaryReport extends React.Component<IPledgeSummaryReport, IPledgeSummaryReportState> {
    constructor(props: IPledgeSummaryReport) {
        super(props);

        this.state = {
            openToolTip1: false,
            openToolTip2: false
        };
    }

    public render(): React.ReactNode {
        return <>
                <Accordion titleElement="h3" title=''>
                {
                    this.props.reports.map((data: ILeaderReport) => {
                        return this.renderManagerItem(data);
                    })
                }
                </Accordion>
            </>;
    }

    private renderManagerItem(data: ILeaderReport) {
        let percent = 0;
        const total = data.usersMetRequirement + data.usersNotMetRequirement;
        if(total > 0 && data.usersMetRequirement > 0) {
            percent = Math.round((data.usersMetRequirement / (data.usersMetRequirement + data.usersNotMetRequirement)) * 100);
        } else {
            percent = 0;
        }

        const hasTeam: boolean = (data.usersMetRequirement + data.usersNotMetRequirement) > 0;
        let isExempted: boolean = false;
        const profile = this.props.exemptions.find(x=>x.userPrincipalName.toLocaleLowerCase() === data.userPrincipalName.toLocaleLowerCase());
        if(profile !== undefined) {
            isExempted = profile.exempted;
        }

        let heading: string = "";
        if(hasTeam) {
            heading = `${data.userDisplayName} - ${percent}% of team has met the requirement`;
        } else {
            if(data.hasMetRequirement) {
                heading = `${data.userDisplayName} - Met the minimum pledge requirement`;
            } else {
                heading = `${data.userDisplayName} - Has not met the minimum pledge requirement`;
            }
        }
        
        return <AccordionItem title={heading}>
                <GridWrapper>
                    <GridItem size="1/1">
                        <b>Has {data.userDisplayName} met the minimum pledge requirement?</b>: { data.hasMetRequirement ? "Yes": "No" }
                    </GridItem>
                    <GridItem size="1/1">
                        <></>
                    </GridItem>
                    <GridItem size="1/1">
                        <b>Is {data.userDisplayName} exempted from the minimum pledge requirement?</b>: { isExempted ? "Yes": "No" }
                    </GridItem>
                    {
                        hasTeam && 
                        <>
                            <GridItem size="1/1">
                            <b>Team members met minimum pledge requirement</b>
                            <Tooltip open={this.state.openToolTip1} position="right"
                                priority={['bottom', 'top', 'left', 'right']}
                                popper={{
                                modifiers: {
                                    flip: {
                                    enabled: true,
                                    behaviour: 'clockwise',
                                    },
                                },
                            }}>
                                <TooltipIcon onClick={(e: any) => {
                                        this.setState({
                                            openToolTip1: true
                                        });
                                        e.preventDefault();
                                    }}
                                    onBlur={(e: any) => {
                                        this.setState({
                                            openToolTip1: false
                                        });
                                    }}
                                    onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
                                        this.setState({
                                            openToolTip1: false
                                        })
                                    )}
                                />
                                <TooltipMessage>
                                    Total number of colleagues in the direct report hierarchy who have met the minimum pledge days requirement.
                                    This include colleagues who are exempted from the event.
                                </TooltipMessage>
                            </Tooltip>: {data.usersMetRequirement}
                        </GridItem>
                        <GridItem size="1/1">
                            <b>Team members not met minimum pledge requirement</b>
                            <Tooltip open={this.state.openToolTip2} position="right"
                                priority={['bottom', 'top', 'left', 'right']}
                                popper={{
                                modifiers: {
                                    flip: {
                                    enabled: true,
                                    behaviour: 'clockwise',
                                    },
                                },
                                }}>
                                <TooltipIcon onClick={(e: any) => {
                                        this.setState({
                                            openToolTip2: true
                                        });
                                        e.preventDefault();
                                    }}
                                    onBlur={(e: any) => {
                                        this.setState({
                                            openToolTip2: false
                                        });
                                    }}
                                    onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
                                        this.setState({
                                            openToolTip2: false
                                        })
                                    )}
                                />
                                <TooltipMessage>
                                    Total number of colleagues in the direct report hierarchy who did not meet the minimum pledge days requirement
                                </TooltipMessage>
                            </Tooltip>: {data.usersNotMetRequirement}
                        </GridItem>
                        <GridItem size="1/1">
                            <ButtonGroupWrapper>
                                <ButtonGroupPrimary>
                                    <TextButton fullWidth
                                        onClick={()=>{
                                            this.props.onSelected(data);
                                        }}>
                                        View Team Details &#8594;
                                    </TextButton>
                                </ButtonGroupPrimary>
                            </ButtonGroupWrapper>
                        </GridItem>
                        </>
                    }
                </GridWrapper>
            </AccordionItem>;
    }
}