import * as React from 'react';

import { AsyncAutocompleteField } from '@jsluna/autocomplete';
import { Container, GridItem, GridWrapper } from '@jsluna/grid';
import { Fieldset, Legend, SelectField, SelectOptionList } from '@jsluna/form';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, TextButton } from '@jsluna/button';
import { Modal, ModalHeading } from '@jsluna/modal';
import { useServices } from '../../../providers/hooks/useServices';
import { IServiceCollection } from '../../../services/IServiceCollection';
import { Notification, NotificationTypes } from '../../Common/Notification';

interface IChangeNameProps {
    services: IServiceCollection;
}

interface IChangeNameState {
    showConfirmDialog: boolean;
    myDirectReportItems: SelectOptionList;
    existingName?: string;
    newName?: string;
    showError: boolean;
    showSuccess: boolean;
}

class ChangeName extends React.Component<IChangeNameProps, IChangeNameState> {
    constructor(props: any) {
        super(props);

        this.state = {
            showConfirmDialog: false,
            showError: false,
            showSuccess: false,
            myDirectReportItems: []
        };
    }

    public async componentDidMount(): Promise<void> {
        try {
            const allReportees = await this.props.services.changeNameService.getAllDirectReports();
            let items: any[] = [];
            allReportees.forEach((d)=>{
                items.push({
                    label: `${d.displayName} (${d.userPrincipalName})`,
                    value: d.userPrincipalName
                });
            });
    
            this.setState({
                myDirectReportItems: items
            });
        } catch(err) {
            console.log(err);
            this.setState({
                showError: true
            });
        }
    }

    public render(): React.ReactNode {

        const enableSwitchOption = this.state.existingName !== undefined && this.state.existingName !== "" 
            && this.state.newName !== undefined && this.state.newName !== "";

        return <Container free>
            {
                this.state.showSuccess &&
                <Notification type={NotificationTypes.Success} 
                    display={this.state.showSuccess} 
                    displayMessage= "The colleague's name change is successful."
                    onClose={()=>{
                        this.setState({
                            showSuccess: false
                        });
                }}/>
            }
            
            <Modal 
                restrictClose
                alert
                open={this.state.showConfirmDialog}>
                <ModalHeading element="h3">Switch Confirmation</ModalHeading>
                <p>
                    Are you sure want to switch the colleague name?
                </p>
                <ButtonGroupWrapper actionbar>
                    <ButtonGroupSecondary>
                        <TextButton onClick={() => this.setState({showConfirmDialog: false})}>Cancel</TextButton>
                    </ButtonGroupSecondary>
                    <ButtonGroupPrimary>
                        <FilledButton onClick={this.onSwitchName.bind(this)}>Switch</FilledButton>
                    </ButtonGroupPrimary>
                </ButtonGroupWrapper>
            </Modal>
            <Legend fontStyle="h2" className='topHeaderPadding'>Colleague name change</Legend>
            {
                this.state.myDirectReportItems &&
                <Fieldset>
                    <AsyncAutocompleteField
                        className="exemption-field"
                        name="apledgeusersearch"
                        placeholder="Type keyword to search for a colleague"
                        label="Select existing colleague name"
                        loadOptions={this.searchExistingColleague.bind(this)}
                        onSelect={(value:any)=> this.setState({
                            existingName: value.value
                        })}
                        role="search">
                    </AsyncAutocompleteField>
                    <SelectField 
                        name="select-field-1"
                        label="Select new colleague name"
                        options={this.state.myDirectReportItems}
                        onChange={(e:any)=>this.setState({newName: e.target.value})} />
                    <GridWrapper>
                        <GridItem key="1" size="1/1" element="div">
                            <ButtonGroupPrimary>
                                <FilledButton 
                                    disabled={!enableSwitchOption}
                                    type="submit" 
                                    onClick={()=> this.setState({showConfirmDialog: true})}>
                                    Switch
                                </FilledButton>
                            </ButtonGroupPrimary>
                        </GridItem>
                    </GridWrapper>
                </Fieldset>
            }
            
        </Container>;
    }

    private async searchExistingColleague(value: string) {
        const results = await this.props.services.changeNameService.searchMyPledgeDirectReport(value);
        const items: any[] = [];
        results.forEach(d => {
            items.push({
                label: `${d.displayName} (${d.userPrincipalName})`,
                value: d.userPrincipalName
            });
        });

        return items;
    }

    private async onSwitchName() {
        this.setState({
            showConfirmDialog: false
        }, async () => {
            if(this.state.existingName !== undefined && this.state.existingName !== "" 
                && this.state.newName !== undefined && this.state.newName !== "")
            {
                await this.props.services.changeNameService.switchDirectReportName(
                    this.state.existingName,
                    this.state.newName);
                
                this.setState({
                    showSuccess: true
                });
            }
        });
    }
}

export function ChangeNamePage () {
    const services = useServices();
    return <ChangeName services={services} />
}
