import { Link } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import {
    Header,   
    HeaderLogo,
    HeaderNav,
    HeaderItem
} from '@jsluna/header';
import { ListGroup, ListGroupItem, ListGroupDropdown } from '@jsluna/list';

import { TextButton } from '@jsluna/button';
import { EventRoutes, PledgingRoutes } from '../../Routes';
import { appenv } from '../../env';
import { useEffect, useState } from 'react';
import { useServices } from '../../providers/hooks/useServices';

export const SiteHeader = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [canPledge, setCanPledge] = useState(false);
    const [isSSCLeader, setIsSSCLeader] = useState(false);
    let links = [];

    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const services = useServices();
    useEffect(() => {
        const checkIfUserAdmin = async () => {
            if(services.eventManagementService !== undefined && services.eventPledgeService !== undefined) {
                const isAdmin = await services.eventManagementService.isAdmin();
                const canPledge = await services.eventPledgeService.canUserPledge();
                const isSSCLeader = await services.eventPledgeService.isSSCLeader();
                setIsAdmin(isAdmin);
                setCanPledge(canPledge);
                setIsSSCLeader(isSSCLeader);
            }
        }
        
        checkIfUserAdmin()
          .catch(console.error);
    })

    if(canPledge) {
        links.push({
            route: PledgingRoutes.SearchPledge,
            text: "Make a pledge"
        });

        links.push({
            route: PledgingRoutes.MyPledges,
            text: "My pledges"
        });
    }

    if(isAdmin || !canPledge) {
        links.push({
            route: PledgingRoutes.MyStorePledges,
            text: "My store pledges"
        });
    }

    let adminGroup = <></>;
    if(isAdmin) {
        adminGroup = <ListGroupDropdown id="admin">
            Admin
            <ListGroup>
                <ListGroupItem element="a" href={EventRoutes.Events}>
                    Events
                </ListGroupItem>
                <ListGroupItem element="a" href={EventRoutes.HomePageText}>
                    Home Page Text
                </ListGroupItem>
                <ListGroupItem element="a" href={PledgingRoutes.DivisionSummaryReport}>
                    Division Summary Report
                </ListGroupItem>
                <a href={appenv.POWERBI_REPORT_URL} target='_blank' rel="noreferrer" className='ln-c-list-group__item'>
                    Power BI Report
                </a>
            </ListGroup>
        </ListGroupDropdown>;
    }

    let reporting = <></>;
    if(isAdmin || isSSCLeader) {
        reporting = <ListGroupDropdown id="teamreporting">
            Reporting
            <ListGroup>
                <ListGroupItem element="a" href={PledgingRoutes.LeadersReport}>
                    Team reporting
                </ListGroupItem>
                <ListGroupItem element="a" href={PledgingRoutes.Exemptions}>
                    Exemptions
                </ListGroupItem>
            </ListGroup>
        </ListGroupDropdown>;
    }

    return <Header hasDrawer>       
            <HeaderLogo className='topHeaderLogo'>
                <div className="ln-c-header__item ln-c-header__item--logo">Store working</div>                
            </HeaderLogo>
            <HeaderNav drawer openDropdownOnHover label="Main">               
                {
                    links.map(l=>{
                        return <Link to={l.route} key={l.text}>
                                    {l.text}
                                </Link>
                    })
                }
                <ListGroupDropdown id="myteam">
                    My Team
                    <ListGroup>
                        <ListGroupItem element="a" href={PledgingRoutes.TeamPledges}>
                            My team pledges
                        </ListGroupItem>                        
                    </ListGroup>
                </ListGroupDropdown>
                
                { reporting }
                { adminGroup }
            </HeaderNav>
            <HeaderItem align='right' drawer bottomBar>
                <div className='topRightNavItems'>
                    {
                        isAuthenticated && 
                            <span className='topBanerAccountName'>
                                {accounts[0].name !== undefined ? accounts[0].name : accounts[0].username}
                            </span>
                    }  
                    <TextButton onClick={()=>window.location.href=appenv.OURSAINSBURYS_URL} className='topBanerTextLinks'>Our Sainsbury's</TextButton>
                    <TextButton className='topBanerTextLinks' onClick={()=>window.location.href='https://login.microsoftonline.com/common/oauth2/logout'}>Logout</TextButton>
                </div>
            </HeaderItem>
    </Header>;
}