import React, { PropsWithChildren } from "react";
import { getServices } from "../services/IServiceCollection";
import { ServiceContext } from "./ServiceContext";
import { useMsal } from "@azure/msal-react";

export type ServiceProviderProps = PropsWithChildren<{
}>;

export function ServiceProvider({children}: ServiceProviderProps): React.ReactElement {
    const { instance } = useMsal();
    const services = getServices(instance);
    return <ServiceContext.Provider value={services}>
        { children }
    </ServiceContext.Provider>
}